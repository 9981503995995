import React from "react";

import { Icon } from "@chakra-ui/react";
import { MdSupervisorAccount, MdPhoneAndroid } from "react-icons/md";

import {
  AiFillBuild,
  AiOutlineAppstore,
  AiOutlineSetting,
  AiOutlineDashboard,
  AiFillContainer,
  AiOutlineDatabase,
  AiOutlineCrown
} from "react-icons/ai";

// Admin Imports
import AdminDashboard from "../komponents/admin/dashboard/AdminDashboard";
import BusinessListing from "../komponents/admin/management/BusinessListing";
import TeamListing from "../komponents/admin/team/TeamListing";
import AdminProfile from "../komponents/admin/profile/AdminProfile";
import LingolinkListing from "../komponents/admin/lingolinks/LingoLinkListing";
import BillingManager from "../komponents/admin/billing/BillingManager";

// Demo Imports
import SinglePartyDemo from "../komponents/admin/demo/SinglePartyDemo";

// Workflow Jobs
import AdminJobs from "../komponents/admin/jobs/AdminJobs";
import PerformJob from "../komponents/admin/jobs/PerformJob";
import AdminJobProgress from "../komponents/admin/jobs/AdminJobProgress";
import AutomationReport from "../komponents/admin/jobs/AutomationReport";

// Devices
import DevicesListing from "../komponents/admin/devices/DevicesListing";
import DevicesDetails from "../komponents/admin/devices/DeviceDetails";
import WorkflowServices from "../komponents/admin/services/WorkflowServices";
import LSPManagement from "../komponents/admin/lsp/LSPManagement";
import Helper from "../komponents/common/helper/Helper";
import ApplicationSettings from "../komponents/common/ApplicationSettings";

// LSP Management

// import ProfileView from '../tscomponents/admin/profile/view/ProfileView';

const adminRoutes = [
  {
    name: "Dashboard",
    path: "/admin",
    icon: (
      <Icon
        as={AiOutlineDashboard}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    collapse: true,
    items: [
      {
        name: "View",
        layout: "/admin",
        path: "/dashboard",
        component: <AdminDashboard />,
        secondary: true,
        showToMenu: true,
        icon: (
          <Icon
            as={AiOutlineDashboard}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
      },
      {
        name: "Single Party",
        layout: "/admin",
        path: "/singleparty",
        component: <SinglePartyDemo />,
        secondary: true,
        showToMenu: true,
        icon: (
          <Icon
            as={AiOutlineDashboard}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
      },
    ],
  },
  {
    name: "Business",
    path: "/admin",
    icon: (
      <Icon
        as={MdSupervisorAccount}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    collapse: true,
    items: [
      {
        name: "Listing",
        layout: "/admin",
        path: "/listing",
        component: <BusinessListing />,
        secondary: true,
        showToMenu: true,
        icon: (
          <Icon
            as={MdSupervisorAccount}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
      },
      {
        name: "Lingolink Calls",
        layout: "/admin",
        path: "/lingolinklisting",
        component: <LingolinkListing />,
        secondary: true,
        showToMenu: true,
        icon: (
          <Icon
            as={MdSupervisorAccount}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
      },
      {
        name: "Billing Management",
        layout: "/admin",
        path: "/billingmanagement",
        component: <BillingManager />,
        secondary: true,
        showToMenu: true,
        icon: (
          <Icon
            as={MdSupervisorAccount}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
      },
    ],
  },
  {
    name: "Devices",
    path: "/admin",
    icon: (
      <Icon as={MdPhoneAndroid} width="20px" height="20px" color="inherit" />
    ),
    collapse: true,
    items: [
      {
        name: "Devices",
        layout: "/admin",
        path: "/lsplisting",
        component: <DevicesListing />,
        secondary: true,
        showToMenu: true,
        icon: (
          <Icon
            as={MdPhoneAndroid}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
      },
      {
        name: "Devices",
        layout: "/admin",
        path: "/devices/deviceinfo",
        component: <DevicesDetails />,
        secondary: true,
        showToMenu: false,
        icon: (
          <Icon
            as={MdPhoneAndroid}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
      },
      DevicesDetails,
    ],
  },
  {
    name: "LSP Management",
    path: "/admin",
    icon: (
      <Icon as={AiOutlineDatabase} width="20px" height="20px" color="inherit" />
    ),
    collapse: true,
    items: [
      {
        name: "LSP Listing",
        layout: "/admin",
        path: "/lspmanagement",
        component: <LSPManagement />,
        secondary: true,
        showToMenu: true,
        icon: (
          <Icon
            as={AiOutlineSetting}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
      },
      {
        name: "LSP Listing",
        layout: "/admin",
        path: "/lspmanagement/lspinfo",
        component: <DevicesDetails />,
        secondary: true,
        showToMenu: false,
        icon: (
          <Icon
            as={AiOutlineCrown}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
      },
    ],
  },
  {
    name: "Workflows",
    path: "/admin",
    icon: <Icon as={AiFillBuild} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Submit Jobs",
        layout: "/admin",
        path: "/workflowjobs",
        component: <AdminJobs />,
        secondary: true,
        showToMenu: true,
        icon: (
          <Icon as={AiFillBuild} width="20px" height="20px" color="inherit" />
        ),
      },
      {
        name: "Perform",
        layout: "/admin",
        path: "/workflowjobs/perform",
        component: <PerformJob />,
        secondary: true,
        showToMenu: false,
        icon: (
          <Icon as={AiFillBuild} width="20px" height="20px" color="inherit" />
        ),
      },
      {
        name: "Jobs Progress",
        layout: "/admin",
        path: "/jobsprogress",
        component: <AdminJobProgress />,
        secondary: true,
        showToMenu: true,
        icon: (
          <Icon as={AiFillBuild} width="20px" height="20px" color="inherit" />
        ),
      },
      {
        name: "Automation Report",
        layout: "/admin",
        path: "/automationreport",
        component: <AutomationReport />,
        secondary: true,
        showToMenu: true,
        icon: (
          <Icon as={AiFillBuild} width="20px" height="20px" color="inherit" />
        ),
      },
      {
        name: "Services",
        layout: "/admin",
        path: "/workflowservices",
        component: <WorkflowServices />,
        secondary: true,
        showToMenu: true,
        icon: (
          <Icon as={AiFillBuild} width="20px" height="20px" color="inherit" />
        ),
      },
    ],
  },
  {
    name: "Team",
    path: "/admin",
    icon: (
      <Icon
        as={MdSupervisorAccount}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    collapse: true,
    items: [
      {
        name: "Management",
        layout: "/admin",
        path: "/team",
        component: <TeamListing />,
        secondary: true,
        showToMenu: true,
        icon: (
          <Icon
            as={MdSupervisorAccount}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
      },
    ],
  },
  {
    name: "Settings",
    path: "/admin",
    icon: (
      <Icon as={AiOutlineSetting} width="20px" height="20px" color="inherit" />
    ),
    collapse: true,
    items: [
      {
        name: "Profile",
        layout: "/admin",
        path: "/profile",
        component: <AdminProfile />,
        secondary: true,
        showToMenu: true,
        icon: (
          <Icon
            as={AiOutlineSetting}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
      },
      {
        name: "Application settings",
        layout: "/admin",
        path: "/applicationsettings",
        component: <ApplicationSettings />,
        secondary: true,
        showToMenu: true,
        icon: (
          <Icon
            as={AiOutlineSetting}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
      },
    ],
  },
  {
    name: "Help",
    path: "/admin",
    icon: <Icon as={AiOutlineSetting} width="25px" height="25px" />,
    collapse: true,
    items: [
      {
        name: "Help Documentation",
        layout: "/admin",
        path: "/help",
        component: <Helper />,
        secondary: true,
        showToMenu: true,
        icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
      },
      
    ],
  },
];

export default adminRoutes;
