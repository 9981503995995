import {
    Box, Button, Flex, IconButton, Text, Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    useToast,
    Card,
    Spacer,
    Spinner,
    Alert,
    AlertIcon,
  } from '@chakra-ui/react'
  import React, { memo, useState } from 'react'
import { accLangPrefPaginated, applyAccLangPref, fetchLocationInfo } from '../../../../services/accountServices'
import { useGreenBg } from '../../../../theme/globalColorTheme'
import { toastFunctionToaster } from '../../../../utils/toastFunction'
import { BiDotsHorizontal } from 'react-icons/bi'
  
  const RenderLanguages = memo(({ langs, translationLanguages }) => {
    let langsArr = langs?.split(',')
    const filterData = translationLanguages?.filter(item => langsArr?.indexOf(item.code) > -1)
    return (
      filterData?.length ? (
        <Flex flexWrap="wrap" gap={2} >
          {filterData.map((item) => (<Text bg={'blue.200'} p="1" fontSize="sm" borderRadius="md">{item.name}</Text>))}
        </Flex>
      ) :
        (<Flex flexWrap="wrap" gap={2} >
          <Text bg={'blue.200'} p="1" fontSize="sm" borderRadius="md">All Languages</Text>
        </Flex>)
    )
  })
  
  const LinkPreferences = (props) => {
    const { linkItem,reListLink,availableLangs } = props;
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast()
    const [langPrefList, setLangPrefList] = useState(null)
    const greenBg = useGreenBg()
    const [loading, setLoading] = useState(false)
    const [isApplyLoading, setIsApplyLoading] = useState(false)
    const [accountId, setAccountUuid] = useState('')
    
    function getLanguagePreferencesList() {
      const paramObj = {
        pageId: 1,
        records_per_page: 100,
        status: 'active'
      }
      setLoading(true)
      accLangPrefPaginated(paramObj).then(resp => {
        if (resp.data[0]?.status) {
          setLangPrefList(resp.data[0]?.status)
        } else {
          setLangPrefList(null)
          toast(toastFunctionToaster("Failed to fetch Preference list", "error"))
        }
        setLoading(false)
      }).catch(err => {
        setLangPrefList(null)
        toast(toastFunctionToaster("Failed to fetch Preference list", "error"))
        setLoading(false)
      })
    }
  
    function handleApplyPrefrence(prefrenceid) {
      const paramObj = {
        "link_account_uuid": accountId,
        "link_location_uuid":"",
        "linkUuid": linkItem.uuid,
        "language_preference_uuid": prefrenceid
      }
      setIsApplyLoading(prefrenceid)
      applyAccLangPref(paramObj).then(resp => {
        if (resp.data[0]?.status) {
          reListLink()
          handleCloseDrawer()
          toast(toastFunctionToaster("Successfully added language preference", "success"))
        } else {
          toast(toastFunctionToaster("Failed to add language preference", "error"))
        }
        setIsApplyLoading(false)
      }).catch(err => {
        setIsApplyLoading(false)
        toast(toastFunctionToaster("Failed to add language preference", "error"))
      })
    }

    function fetchAccAndLocDetails() {
        const paramObj = { key: "location" };
        fetchLocationInfo(paramObj)
            .then(resp => {
                if (resp?.data[0]?.status) {
                    setAccountUuid(resp?.data[0]?.uuid);
                } else {
                    setAccountUuid("");
                }
            })
            .catch(err => {
                setAccountUuid("");
            });
    };
  
    function handleCloseDrawer (){
      onClose()
      setIsApplyLoading('')
    }

    function handleOpenDrawer(){
        onOpen()
        getLanguagePreferencesList()
          fetchAccAndLocDetails()
      }
 
  
    return (
      <Flex gap={2} mt={3}>
        <b>Language Preferences:</b>
        <RenderLanguages langs={linkItem?.source_langs} translationLanguages={availableLangs}/>
        <IconButton
          borderRadius={'5px'}
          size='sm'
          fontSize={'22px'}
          ml='2'
          bg={'blue.100'}
          icon={<BiDotsHorizontal />}
          onClick={handleOpenDrawer}
        />
  
  
        <Drawer
          size="md"
          isOpen={isOpen}
          placement='right'
          onClose={handleCloseDrawer}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Create your account</DrawerHeader>
  
            <DrawerBody>
              <Text>List of all enabled Languages preference</Text>
              <Flex flexDir="column" gap={2} p={1}>
                {loading ? <Spinner thickness='2px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='black'
                  size='xl'
                  position="fixed"
                  top="50%"
  
                /> : <>
                  {
                    langPrefList && langPrefList.length ? langPrefList.map(item => (
                      <Flex alignItems="center" justifyContent="center" gap="1">
                        <Card w="100%" borderRadius="md" mb={2} p={4}>
                          <Flex alignItems='center' >
                            <Flex flexDir="column" gap={2} flex="1">
                              <Flex>
                                <Box
                                  p={2}
                                  bg={greenBg}
                                  borderRadius="md">
                                  <Text
                                    fontWeight={"500"}
                                    fontSize={"18px"}
                                    _firstLetter={{ textTransform: "capitalize" }}>
                                    {item?.language_preference_name}
                                  </Text>
                                </Box>
                                <Spacer />
                              </Flex>
  
                              <Flex alignContent="center" gap={2}>Languages:<RenderLanguages langs={item?.supported_language_list}  translationLanguages={availableLangs}/></Flex>
                            </Flex>
                            <Button fontSize="sm" bg="blue.100" isLoading={isApplyLoading === item.language_preference_uuid} onClick={()=>handleApplyPrefrence(item.language_preference_uuid)}>Apply</Button>
                          </Flex>
                        </Card>
                      </Flex>
                    ))
                      : <Alert status="warning" mt="4">
                        <AlertIcon />
                        <Text>You dont have any Language Preferences</Text>
                        <Text
                          ml={5}
                          _hover={{ cursor: "pointer", textDecoration: "underline" }}
                        // onClick={() => handleOpenDrawer(SIDEBAR_TYPES.CREATE)}
                        >
                          <b>CREATE NEW Language Preference!!</b>
                        </Text>
                      </Alert>
                  }
                </>}
  
              </Flex>
            </DrawerBody>
  
            {/* <DrawerFooter>
              <Button variant='outline' mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='blue'>Save</Button>
            </DrawerFooter> */}
          </DrawerContent>
        </Drawer>
  
      </Flex>
    )
  }
  
  export default LinkPreferences