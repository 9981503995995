// Chakra Imports
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Joyride, { STATUS } from 'react-joyride';
import React, { useState, useRef, useEffect, useCallback } from "react";
import {
	Box,
	Flex,
	Button,
	Icon,
	Text,
	useToast,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Input,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter
} from "@chakra-ui/react";
import AWS from "aws-sdk";

// Component
import TagsInput from "react-tagsinput";
import AudioPlayer from "../AudioPlayer";
import "react-tagsinput/react-tagsinput.css";
import { toastFunctionToaster } from "../../../../utils/toastFunction";
import BasicAITranslateSelector from "./BasicAITranslateSelector";
import { RiSplitCellsHorizontal, RiSplitCellsVertical } from "react-icons/ri";
import {
	AiOutlineAudio,
	AiOutlineCaretRight,
	AiOutlineAudioMuted,
	AiOutlineSave,
	AiOutlineClear,
	AiFillClockCircle,
	AiOutlineHome,
	AiOutlineMail,
} from "react-icons/ai";

// API
import { basicAiCallSetupEx, callCompletedEx } from "../../../../services/dynamicPageSvc";
import { lingoEmailTranscript, saveEmailTranscript } from "../../../../services/accountServices";
import { setHomeRouteFlag } from "../../../../store/actions/topNotificationActions";
import silentAudio from "../../../../assets/audio/silence_no_sound.mp3"

const BasicAICommDemo = props => {
	const toast = useToast();
	const dispatch = useDispatch();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const {isOpen:isAlertOpen, onOpen:onAlertOpen, onClose:onAlertClose} = useDisclosure()
	const launchKey = props.launchKey ? props.launchKey : null; // this is key for dynamic_link
	const launchCode = props.launchCode ? props.launchCode : null; // code for dynamic link
	const sessionKey = props.sessionKey ? props.sessionKey : null;
	const callProviderCode = props.callProviderCode ? props.callProviderCode : null;
	const sourceLanguageCode = props?.selectedSettings?.sourceLangCode ? props?.selectedSettings?.sourceLangCode : null;
	const targetLanguageCode = props?.selectedSettings?.targetLangCode ? props?.selectedSettings?.targetLangCode : null;
	let socket = null;
	const basicAITutorialFlag = props?.basicAITutorialFlag
	const mediaFormatRef = useRef({
		mimeType: '',
		audioEncoding: '',
		sampleRate: 0
	});
	const [participants, setParticipants] = useState([]);
	const participantsRef = useRef(participants);
	const call_control = process.env.REACT_APP_CALL_CONTROL !== '0'
	const showConfidence = process.env.REACT_APP_SHOW_CONFIDENCE !== '0'

	useEffect(() => {
		participantsRef.current = participants;
	}, [participants]);

	// ----- Deepgram + Anthropic -------- //
	// const [combineTranscript, setCombineTranscript] = useState([]);
	// const [outputTranscript, setOutputTranscript] = useState([]);
	const [interimTranscript, setInterimTranscript] = useState("");
	const [interimConfidence, setInterimConfidence] = useState(0);
	const [recordingState, setRecordingState] = useState(null);
	const [micDisabled, setMicDisabled] = useState(true);
	const [translatedTranscript, setTranslatedTranscript] = useState([]);
	const location = useLocation();
	const [isTabActive, setIsTabActive] = useState(true);
	const [callCompleted, setCallCompleted] = useState(false);
	const [saveButtonDisable, setSaveButtonDisable] = useState(false);

	const sttSocketRef = useRef(null);
	const micRef = useRef(null);
	const targetLanguageRef = useRef("");
	const cancelRef = React.useRef()

	const [spitFlag, setSplitFlag] = useState("vertical");
	// THIS ONE VARIABLE CONTROLS WHO SPEAKS
	const [activeLanguage, setActiveLanguage] = useState("source"); // 'source' or 'target' SOURCE IS SPEAKER 1, AND TARGET IS SPEAKER 2
	const activeLanguageRef = useRef(activeLanguage);
	const [skillsKeyword, setSkillsKeyword] = useState([]);
	const [invalidEmail, setInvalidEmail] = useState([]);
	const [emailLoader, setEmailLoader] = useState(false);

	useEffect(() => {
		activeLanguageRef.current = activeLanguage;
	}, [activeLanguage]);

	const [sourceLanguageTranscript, setSourceLanguageTranscript] = useState([]);
	const [targetLanguageTranscript, setTargetLanguageTranscript] = useState([]);
	const [communicationFragments, setCommunicationFragments] = useState([]);
	const [autoSwitchLanguage, setAutoSwitchLanguage] = useState(false);
	const autoLanguageSpeakerRef = useRef(autoSwitchLanguage);

	const [run, setRun] = useState(true);
	const [stepIndex, setStepIndex] = useState(0);

	const steps = [
		{
			target: '.call',
			content: 'This is the first step when clicked your call will start.',
		},
		{
			target: '.stop',
			content: 'This is to stop the call.',
		},
		{
			target: '.pause',
			content: 'This button is to pause and play the call.',
		},

		{
			target: '.time',
			content: 'This shows the total time call was on. Click "Next" to continue.',
		},
		{
			target: '.audioType',
			content: 'This returns the generated language in audio type',
		},
		{
			target: '.save',
			content: 'This step is to save the generated transccript. Click "Next" to continue.',
		},
		{
			target: '.email',
			content: 'This step is to mail the generated transccript. Click "Next" to continue.',
		},
		{
			target: '.home',
			content: 'Return Back Home.',
		},
	];

	useEffect(() => {
		autoLanguageSpeakerRef.current = autoSwitchLanguage;
	}, [autoSwitchLanguage]);

	const [targetLanguage, setTargetLanguage] = useState("");
	const [targetLanguageDisplay, setTargetLanguageDisplay] = useState(props?.selectedSettings?.targetLangCode);
	const translateInput = useRef("");
	const [buttonConnectedLoad, setButtonConnectedLoad] = useState(false);
	const intervalRef = useRef();
	const [time, setTime] = useState(0);
	const [isRunning, setIsRunning] = useState(false);
	const [demoHistoryTag, setDemoHistoryTag] = useState(true);
	const [pauseFlag, setPauseFlag] = useState(true);
	const [basicAiCallUuid, setBasicAiCallUuid] = useState(null);
	const [phoneNumber, setPhoneNumber] = useState('+16478855924');
	const [avgConfidencePer, setAvgConfidencePer] = useState({
		avg: 0,
		count: 0
	})
	// NOTE: this is Interpretation confidence only! Translation confidence is not available yet.
	

	// ----- Deepgram + Anthropic -------- //


	useEffect(() => {
		if (props?.selectedSettings?.sourceLangCode === "Demo" || props?.selectedSettings?.targetLangCode === "Demo") {
			setDemoHistoryTag(false);
		}
	}, [props?.selectedSettings]);

	useEffect(() => {
		if (demoHistoryTag) {
			if (targetLanguageDisplay) {
				targetLanguageRef.current = targetLanguageDisplay;
				setTargetLanguage(targetLanguageDisplay);
				setTargetLanguageDisplay(targetLanguageDisplay);
			}
			// if (sourceLanguageCode != null && callProviderCode == 21) {
			//     callSetupProcess();
			// }
		}
	}, [targetLanguageDisplay]);

	function calculateAvgPre(avgPreObj, newPer) {
		const oldAvgPer = avgPreObj?.avg
		const oldCount = avgPreObj?.count

		const newAvgPer = ((oldAvgPer * oldCount) + newPer) / (oldCount + 1)

		return {
			avg: newAvgPer,
			count: oldCount + 1
		}
	}

	const callSetupProcess = () => {
		if (launchKey != null && launchCode != null && sessionKey != null && callProviderCode != null) {
			let paramObj = {
				feature: "bi",
				provider: callProviderCode,
				launchSession: sessionKey,
				launchKey: launchKey,
				launchCode: launchCode,
				source_language: sourceLanguageCode,
				target_language: targetLanguageCode,
			};
			basicAiCallSetupEx(paramObj)
				.then(res => {
					if (res?.data[0]?.status) {
						setBasicAiCallUuid(res?.data[0]?.uuid);
						setRecordingState(true);
						console.log(recordingState);
						// launch2PartySocket(res?.data[0]?.uuid);
					}
				})
				.catch(err => {
					// updateLogDetails("Unable to get call started working...");
				});
		}
	};

	const callCompletedProcess = () => {
		if (launchKey != null && sessionKey != null && basicAiCallUuid != null) {
			let paramObj = {
				feature: "hi",
				provider: callProviderCode,
				callUuid: basicAiCallUuid,
				launchKey: launchKey,
				launchSession: sessionKey,
				callLog: "none",
				agentInfo: !demoHistoryTag ? "Demo Call" : "{'agent': 'Lingolet Secure AI'}",
			};
			callCompletedEx(paramObj)
				.then(res => {
					if (res?.data[0]?.status) {
						setBasicAiCallUuid(null);
						setCallCompleted(true);
						//Toast -> "You have completed Secure AI voice call with total call time: " + formatTime(time) + "."
					}
				})
				.catch(err => {
					// updateLogDetails("Unable to get call stopped correctly...");
				});
		}
	};

	AWS.config.update({
		accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
		secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
		region: process.env.REACT_APP_AWS_REGION,
	});

	const polly = new AWS.Polly();
	const [audioFile, setAudioFile] = useState();
	const [playNowState, setPlayNowState] = useState(false);

	const convertTextToSpeech = (local_text, voiceName) => {
		if (polly != null && polly != undefined) {
			polly.synthesizeSpeech(
				{
					Text: local_text,
					OutputFormat: "mp3",
					VoiceId: voiceName ? voiceName : "Salli",
				},
				(error, data) => {
					if (error) {
						//console.log(error);
						toast(toastFunctionToaster("Something went wrong!", "error"));
					} else {
						setPlayNowState(true);
						setAudioFile(data);
						// Show toast notification on successful conversation
						// toast(toastFunctionToaster('Text converted to speech successfully!', "success"));
					}
				}
			);
		}
	};

	async function getMicrophone() {
        try {
            const supportedFormats = [
                'audio/webm;codecs=opus',
                'audio/webm',
                'audio/ogg;codecs=opus',
                'audio/ogg',
                'audio/mp4;codecs=mp4a',
                'audio/mp4',
                'audio/mpeg',
                'audio/wav',
                'audio/aac'
            ];

            console.log("Supported audio formats:");
            let selectedMimeType = '';
            for (const format of supportedFormats) {
                const isSupported = MediaRecorder.isTypeSupported(format);
                console.log(`${format}: ${isSupported}`);
                if (isSupported && !selectedMimeType) {
                    selectedMimeType = format;
                }
            }

            if (!selectedMimeType) {
                throw new Error('No supported audio format found');
            }

            console.log(`Selected MIME type: ${selectedMimeType}`);

            const audioEncoding = selectedMimeType.split(';')[0].split('/')[1];

			const stream = await navigator.mediaDevices.getUserMedia({
				audio: true,
			});

			// Create AudioContext to get sample rate
			const audioContext = new (window.AudioContext || window.webkitAudioContext)();
			const source = audioContext.createMediaStreamSource(stream);
			const sampleRate = audioContext.sampleRate;

            mediaFormatRef.current = {
                mimeType: selectedMimeType,
                audioEncoding: audioEncoding,
                sampleRate: sampleRate
            };

            console.log(`Audio encoding: ${audioEncoding}`);
            console.log(`Sample rate: ${sampleRate} Hz`);

            return new MediaRecorder(stream, { mimeType: selectedMimeType });
        } catch (error) {
            console.error("Error accessing microphone:", error);
            throw error;
        }
    }

	async function openMicrophone(microphone, socket) {
		return new Promise(resolve => {
			let isFirstPacket = false;  // Add this flag

			microphone.onstart = () => {
				console.log("client: microphone opened");
				document.body.classList.add("recording");
				resolve();
			};

			microphone.onstop = () => {
				console.log("client: microphone closed");
				if (socket.readyState === WebSocket.OPEN) {
					console.log("client: microphone stop sent");
				}
				document.body.classList.remove("recording");
			};

			microphone.ondataavailable = event => {
				if (isFirstPacket) {
					isFirstPacket = false;
					console.log("client: first microphone data packet ignored");
					return;
				}
				if (event.data.size > 0 && socket.readyState === WebSocket.OPEN) {
					socket.send(event.data);
					// console.log("client: microphone data sent");
				}
			};
			microphone.start(1000);
		});
	}

	async function reOpenMicrophone() {
		if (micRef.current != null && micRef.current != undefined && micRef.current.state != "closed") {
			micRef.current.stop();
			micRef.current = null;
		}
		micRef.current = await getMicrophone();
		await openMicrophone(micRef.current, sttSocketRef.current);
	}

	async function toggleRecording() {
		if (recordingState == null || !recordingState) {
			setRecordingState(true);
			startTimer();
			callSetupProcess();
		} else {
			setRecordingState(false);
			stopTimer();
			callCompletedProcess();
			closeSocket(); // we need to close socket when call is completed
			micRef.current.stop();
		}
	}

	const socketAddListener_open = async socket => {
		setButtonConnectedLoad(true);
		socket.addEventListener("open", async () => {
			setButtonConnectedLoad(false);
			console.log("client: connected to server");

			// socketRef.current.setTimeout // HOW TO SET Socket timeout if there is no Data for 1 minute
			try {
				micRef.current = await getMicrophone();
				await openMicrophone(micRef.current, sttSocketRef.current);
				socket.send(JSON.stringify({
					type: "start_transcribing",
					media_format: mediaFormatRef.current
				}));
				socket.send(
					JSON.stringify({
						type: "auto_switch_language",
						value: autoLanguageSpeakerRef.current,
					})
				);
				micRef.current.resume();
				setMicDisabled(false);
			} catch (error) {
				console.error("error opening microphone:", error);
			}
		});
	};

	useEffect(() => {
		// send the message to server if socket is open, send the current mode of autoSwitchSpeaker
		if (sttSocketRef?.current && sttSocketRef.current.readyState === WebSocket.OPEN) {
			sttSocketRef.current.send(
				JSON.stringify({
					type: "auto_switch_speaker",
					autoSwitchSpeaker: autoLanguageSpeakerRef.current,
				})
			);
		}
	}, [autoSwitchLanguage]);

	const add_communication_fragment = data => {
		if (data.text != undefined && data.text.trim() !== "") {
			setCommunicationFragments(t => [...t, data]);
		}
	};

	// NOTE: below unused for now, but will be used when we add chat messages
	const add_communication_polyglot = data => {
		// iterate over communication fragments and find the matching entry with communication_fragment_uuid
		// if found, update the text with the new text
		for (let i = communicationFragments.length - 1; i >= 0; i--) {
			if (communicationFragments[i].communication_fragment_uuid === data.communication_fragment_uuid) {
				if (!communicationFragments[i].polyglots) {
					communicationFragments[i].polyglots = [];
				}
				communicationFragments[i].polyglots.push(data);
			}
		}
	};

	const socketAddListener_message = async socket => {
		socket.addEventListener("message", async event => {
			const data = JSON.parse(event.data);
			console.log("Received data:", data);
			if (data.type === "UtteranceEnd") {
				console.log(data.type, data["language"]);
				setInterimTranscript("");
				setInterimConfidence(0);
			} else if (data.type === "Results" && data.transcript !== "") {
				console.log(data.type, data["confidence"], data["language"], data.transcript);
				if (data.is_final) {

					console.log("Final transcript:", data);
					add_communication_fragment(data);
					// Check if there's a phone call participant and if their language matches the current audio

					const phoneCallParticipant = participantsRef.current.find(p => p.is_phone_call_participant);

					const shouldPlayAudio = !phoneCallParticipant ||
						(phoneCallParticipant && phoneCallParticipant.language !== data.communication_polyglots[0].language);

					if (shouldPlayAudio) {
						const audioContent = data.communication_polyglots[0].audio_content;
						const audioUrl = `data:audio/mp3;base64,${audioContent}`;
						const audio = new Audio(audioUrl);
						audio.oncanplaythrough = function () {
							console.log('Can play through audio without stopping');
							setTimeout(() => {
								audio.play();
							}, 1);
						};
						audio.onerror = function (e) {
							console.error('Error in playing audio', e);
						};
					} else {
						console.log('Skipping audio playback due to phone call participant');
					}

					setInterimTranscript("");
					setInterimConfidence(0);
				} else {
					setInterimTranscript(data.text);
					setInterimConfidence(data.confidence);
					console.log("perer", data.confidence)
					const avgPer = calculateAvgPre(avgConfidencePer, data?.confidence)
					setAvgConfidencePer(avgPer)
				}
			} else if (data.type === "participants_info") {
				console.log("Participants info", data);
				setParticipants(data.participants);
			}
		});
	};

	const socketAddListener_close = async socket => {
		socket.addEventListener("close", () => {
			console.log("client: disconnected from server");
			setRecordingState(false);
			setMicDisabled(true);
			setRun(false)
			if (micRef != null && micRef != undefined) {
				if (micRef.current != null && micRef.current != undefined) {
					// send socket message to stop-transcribing
					socket.send(JSON.stringify({ type: "stop_transcribing" }));
					micRef.current.stop();
					micRef.current = null;
				}
			}
		});
	};

	const launch2PartySocket = callUuid => {
		var localCallUuid = null;
		if (basicAiCallUuid != null) {
			localCallUuid = basicAiCallUuid;
		} else {
			localCallUuid = callUuid;
		}
		if (launchKey != null && launchCode != null && localCallUuid != null && sessionKey != null && sourceLanguageCode != null && demoHistoryTag) {
			let socket_url = "";
			if (window.location.hostname.includes("localhost")) {
				// Suraj > "wss://lingolet.ai/api/stt";
				// socket_url = "wss://lingolet.ai/api/stt?key=" + launchKey + "&code=" + launchCode + "&session=" + sessionKey + "&langCode=" + sourceLanguageCode;
				socket_url =
					"ws://localhost:9110/api/stt_twoparty?key=" +
					launchKey +
					"&code=" +
					launchCode +
					"&session=" +
					sessionKey +
					"&sourceLanguageCode=" +
					sourceLanguageCode +
					"&targetLanguageCode=" +
					targetLanguageCode;
			} else {
				// stt > single Party
				socket_url =
					"wss://" +
					window.location.hostname +
					"/api/stt_twoparty?key=" +
					launchKey +
					"&code=" +
					launchCode +
					"&session=" +
					sessionKey +
					"&sourceLanguageCode=" +
					sourceLanguageCode +
					"&targetLanguageCode=" +
					targetLanguageCode;
			}
			socket = new WebSocket(socket_url);
			sttSocketRef.current = socket;
			if (recordingState !== null) {
				if (recordingState) {
					setActiveLanguage("source");
					socketAddListener_open(socket);
					socketAddListener_message(socket);
					socketAddListener_close(socket);
				} else {
					closeSocket();
				}
			} else {
				toast(toastFunctionToaster("Critical communication error, please contact support team.", "error"));
			}
		}
	};

	function playSilentAudio() {
		let audio = new Audio(silentAudio)
		audio.play()
	}

	useEffect(() => {
		launch2PartySocket();
	}, [basicAiCallUuid]);

	// useEffect(() => {
	//     console.log(recordingState, "2")
	//     if (launchKey != null && launchCode != null
	//             && basicAiCallUuid != null
	//             && sessionKey != null && sourceLanguageCode != null && demoHistoryTag) {
	//         let socket_url = ''
	//         if (window.location.hostname.includes('localhost')) {
	//             // Suraj > "wss://lingolet.ai/api/stt";
	//             // socket_url = "wss://lingolet.ai/api/stt?key=" + launchKey + "&code=" + launchCode + "&session=" + sessionKey + "&langCode=" + sourceLanguageCode;
	//             socket_url = "ws://localhost:9110/api/stt_twoparty?key=" + launchKey + "&code=" + launchCode + "&session=" + sessionKey + "&sourceLanguageCode=" + sourceLanguageCode+"&targetLanguageCode="+targetLanguageCode;

	//         } else {
	//             // stt > single Party
	//             socket_url = "wss://" + window.location.hostname + "/api/stt_twoparty?key=" + launchKey + "&code=" + launchCode + "&session=" + sessionKey + "&sourceLanguageCode=" + sourceLanguageCode+"&targetLanguageCode="+targetLanguageCode;
	//         }
	//         socket = new WebSocket(socket_url);
	//         sttSocketRef.current = socket;
	//         if (recordingState !== null) {
	//             if (recordingState) {
	//                 setActiveLanguage('source')
	//                 socketAddListener_open(socket);
	//                 socketAddListener_message(socket);
	//                 socketAddListener_close(socket)
	//             }
	//             else {
	//                 closeSocket()
	//             }
	//         }
	//     }
	//     else if(!demoHistoryTag) {

	//     }
	//     else {
	//         toast(toastFunctionToaster('Critical communication error, please contact support team.', "error"));
	//     }
	// }, [recordingState]);

	const handleSelectedAudio = data => {
		if (data) {
			const translatedLocalText = translatedTranscript[translatedTranscript?.length - 1];
			convertTextToSpeech(translatedLocalText, data);
		}
	};

	// Todo:When Browser tab is changed close socket
	useEffect(() => {
		const handleEvent = () => {
			const isTabActive = !document.hidden;
			setIsTabActive(isTabActive);
			if (!isTabActive) {
				// closeSocket();
			}
		};
		const handleBeforeUnload = event => {
			closeSocket();
			event.returnValue = "";
		};
		document.addEventListener("visibilitychange", handleEvent);
		window.addEventListener("beforeunload", handleBeforeUnload);
		return () => {
			document.removeEventListener("visibilitychange", handleEvent);
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, []);

	// Todo:When Url is changes socket is closed
	// useEffect(() => {
	//     closeSocket()
	// }, [location]);

	const closeSocket = () => {
		console.log("CLosed Socket");
		setRun(false)
		if (sttSocketRef?.current != null && sttSocketRef?.current !== undefined) {
			sttSocketRef.current.close();
		}
	};

	// Step 2: Create a function that toggles the active language
	const toggleActiveLanguage = () => {
		setActiveLanguage(prevLanguage => (prevLanguage === "source" ? "target" : "source"));
	};
	const getActiveSourceLanguageCode = () => {
		return activeLanguageRef.current === "source" ? sourceLanguageCode : targetLanguageCode;
	};
	const getActiveTargetLanguageCode = () => {
		return activeLanguageRef.current === "target" ? sourceLanguageCode : targetLanguageCode;
	};
	useEffect(() => {
		const updateLanguage = async () => {
			if (sttSocketRef?.current && sttSocketRef.current.readyState === WebSocket.OPEN) {
				await reOpenMicrophone();
				sttSocketRef.current.send(
					JSON.stringify({
						type: "change_language",
						language: getActiveSourceLanguageCode(),
						target_language: getActiveTargetLanguageCode(),
					})
				);
			}
		};

		updateLanguage();
	}, [activeLanguage]);


	const makeCall = (phoneNumber) => {
		if (sttSocketRef.current) {
			sttSocketRef.current.send(JSON.stringify({
				type: "add_call_participant_request",
				'phone_number': phoneNumber,
				'language': getActiveTargetLanguageCode()
			}));
			setPhoneNumber('');
		}
	};
	// ------ Code main Starts here

	// Time Start Stop Function
	const startTimer = () => {
		resetTimer();
		if (!isRunning) {
			setIsRunning(true);
			intervalRef.current = setInterval(() => {
				setTime(prevTime => prevTime + 1);
			}, 1000);
		}
	};

	const stopTimer = () => {
		clearInterval(intervalRef.current);
		setIsRunning(false);
		// Todo:Once call is hangup reset timer
	};

	const resetTimer = () => {
		clearInterval(intervalRef.current);
		setIsRunning(false);
		setTime(0);
	};

	const formatTime = timeInSeconds => {
		const minutes = Math.floor(timeInSeconds / 60)
			.toString()
			.padStart(2, "0");
		const seconds = (timeInSeconds % 60).toString().padStart(2, "0");
		return `${minutes}:${seconds}`;
	};
	// ------ Code main Ends here

	//   Todo:Set home button route to primary page by updating redux value to dynamic home
	const handleHomeRouteFlag = () => {
		dispatch(setHomeRouteFlag(true));
	};

	//   Todo:Play Pause function
	const pauseTranslationCall = () => {
		if (pauseFlag) {
			micRef.current.pause();
			setPauseFlag(!pauseFlag);
		} else {
			setMicDisabled(true);
			micRef.current.resume();
			setPauseFlag(!pauseFlag);
		}
	};

	const renderTranscript = () => {
		const transformedString = transformData(communicationFragments);
		return transformedString;
	};

	const transformData = (data) => {
		return data.map(item => {
			const sourceText = item?.text || "";
			const targetLanguage = item?.communication_polyglots[0]?.language;
			const targetText = (item?.communication_polyglots.find(polyglot => polyglot?.language === targetLanguage) || {}).text || "";
			return `${item?.language}: ${sourceText}<br>${targetLanguage}: ${targetText}<br>`;
		}).join('<br>');
	};

	const saveTranscript = () => {
		const letObj = {
			linkKey: launchKey,
			linkSession: sessionKey,
			languages: `${sourceLanguageCode},${targetLanguageCode}`,
			transcript_text: JSON.stringify(communicationFragments),
			call_uuid: basicAiCallUuid,
		};
		saveEmailTranscript(letObj)
			.then(res => {
				if (res?.data[0]?.status) {
					toast(toastFunctionToaster("The transcript is saved.", "success"));
					setSaveButtonDisable(true);
				} else {
					toast(toastFunctionToaster("Unable to save the transcripts...", "error"));
				}
			})
			.catch(err => {
				console.log(err);
			});
	};

	const emailTranscript = () => {
		setEmailLoader(true);
		const letObj = {
			linkKey: launchKey,
			linkSession: sessionKey,
			languages: `${sourceLanguageCode}${targetLanguageCode}`,
			transcript_text: renderTranscript(),
			call_uuid: basicAiCallUuid,
			emails: skillsKeyword.join(", "),
		};
		console.log(letObj)
		lingoEmailTranscript(letObj)
			.then(res => {
				if (res?.data[0]?.status) {
					setEmailLoader(false);
					toast(toastFunctionToaster("The transcript is emailed as suggested.", "success"));
					onClose();
				} else {
					toast(toastFunctionToaster("Unable to email the transcript, due to some error..", "error"));
				}
			})
			.catch(err => {
				setEmailLoader(false);
				toast(toastFunctionToaster("Unable to email the transcript, due to some error..", "error"));
			});
	};

	const onTagChange = tags => {
		setSkillsKeyword(tags);
	};

	const validateEmails = emails => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emails.filter(email => !emailRegex.test(email));
	};

	useEffect(() => {
		setInvalidEmail(validateEmails(skillsKeyword));
	}, [skillsKeyword]);

	useEffect(() => {
		const transformedData = communicationFragments.map(item => {
			const englishText = item.text;
			const sourceLang = item.communication_polyglots[0].language;
			const hindiText = item.communication_polyglots[0].text;
			const confidence = item.confidence;
			return { [item.language]: englishText, sourceLang, hindiText, confidence };
		});
		console.log(transformedData, "transformedData");
	}, [communicationFragments]);

	// Todo:Test with multipled scenarios
	const handleJoyrideCallback = useCallback((data) => {
		const { status, index, type } = data;
		const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
		console.log(data)
		if (finishedStatuses.includes(status)) {
			setRun(false);
		} else if (type === 'step:after') {
			if (index === 0) {
				setRun(false);  // Stop the tour after the last step
			} else if (index === 2) {
				setRun(false);  // Stop the tour after the last step
			}
			else {
				setStepIndex(index + 1);
			}
		}
	}, [steps.length]);

	const handleStartTour = () => {
		setRun(true);
		setStepIndex(0);
	};

	const handleStopTour = () => {
		setRun(false);
	};

	const handleNextStep = () => {
		setRun(true);
		setStepIndex(prevIndex => prevIndex + 1);
	};

	return (
		<>
			{basicAITutorialFlag ? (
				<Joyride steps={steps} run={run} stepIndex={stepIndex} callback={handleJoyrideCallback} continuous={true} showSkipButton={false} styles={{ options: { zIndex: 10000 }, }} />
			) : null}
			{/* {!run && (
			<button onClick={handleNextStep} className="continue-button">
			Continue
			</button>
		)} */}
			<Flex my="2">
				<Flex w="100%" justifyContent={"center"}>
					<Flex borderRadius={"10px"} color="#fff" alignItems={"center"} bg="#000" p="2">
						<Flex borderWidth={"0.5px"} borderRadius={"5px"} bg="#000">
							{spitFlag === "horizontal" ? (
								<Icon
									w="50px"
									cursor={"pointer"}
									fontSize="30px"
									color="#42aa42"
									as={RiSplitCellsVertical}
									onClick={() => {
										setSplitFlag("vertical");
									}}
								/>
							) : (
								<Icon
									w="50px"
									cursor={"pointer"}
									fontSize="30px"
									color="#42aa42"
									as={RiSplitCellsHorizontal}
									onClick={() => {
										setSplitFlag("horizontal");
									}}
								/>
							)}
						</Flex>
						{time > 0 ? (
							<>
								<Flex className="time" h="100%" ml={1} rounded={"md"} minW={"80px"} maxW={"80px"} bg={"#bee3f8"} color="#000" align={"center"}>
									<Icon as={AiFillClockCircle} ml={1} mr={1} h={5} w={5} />
									{formatTime(time)}
								</Flex>
							</>
						) : null}
						{/* <Flex>
							<Box p={1} className="audioType">
								<BasicAITranslateSelector targetLanguage={targetLanguage} returnSelectedAudioType={handleSelectedAudio} />
							</Box>
						</Flex> */}
						{recordingState && (
							<Button
								size={{
									base: "xs",
									md: "sm",
									lg: "sm",
									xl: "sm",
								}}
								p="10px"
								ml={2}
								borderRadius={"5px"}
								mr="3"
								onClick={toggleActiveLanguage}
								color="#000"
							>
								Speak: {getActiveSourceLanguageCode()} - Listen: {getActiveTargetLanguageCode()}
							</Button>
						)}
					</Flex>
				</Flex>
			</Flex>
			<Box overflow={"hidden"} bgColor="#252628">
				<Box bg="#fff" h="calc(100vh - 240px)">
					<AudioPlayer playNow={playNowState} audioFile={audioFile} />
					<Flex justifyContent={"space-between"} flexWrap="wrap" h="100%" border="1px solid #efefef">
						<Box
							w={{
								base: spitFlag === "vertical" ? "49%" : "100%",
								md: spitFlag === "vertical" ? "49%" : "100%",
								lg: spitFlag === "vertical" ? "49%" : "100%",
								xl: spitFlag === "vertical" ? "49%" : "100%",
							}}
							borderRightWidth={"1px"}
							p="10px"
							borderColor="#efefef"
							borderBottomWidth={spitFlag === "horizontal" ? "1px" : "0px"}
						>
							<Box
								h="80%"
								overflow="auto"
								css={{
									"&::-webkit-scrollbar": {
										width: "4px",
									},
									"&::-webkit-scrollbar-track": {
										width: "6px",
									},
									"&::-webkit-scrollbar-thumb": {
										borderRadius: "24px",
									},
								}}
								mt="3"
							>
								<Text color="#000" fontSize={"14px"}>
									{communicationFragments?.map((item, index) => (
										<Flex key={index} direction={spitFlag === "horizontal" ? "column" : "row"}>
											{(item.language === sourceLanguageCode || spitFlag === "horizontal") && (
												<Text>
													<Icon mr="1" as={AiOutlineCaretRight} />
													{item.language}: {item.text}
													{showConfidence && item.confidence && typeof item.confidence === 'number' ?
														` (${(item.confidence * 100).toFixed(2)}%)` :
														''
													}
												</Text>
											)}
											{item.communication_polyglots?.map(
												(polyglot, index) =>
													(polyglot.language === sourceLanguageCode || spitFlag === "horizontal") && (
														<Text key={index} direction="row">
															{spitFlag !== "vertical" && (
																<span>
																	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
																	<Icon mr="1" as={AiOutlineCaretRight} />
																</span>
															)}
															{polyglot.language}: {polyglot.text}
														</Text>
													)
											)}
										</Flex>
									))}
								</Text>
								{(activeLanguage === "source" || spitFlag === "horizontal") && interimTranscript ? (
									<Text color="#c6d704" fontSize={"14px"}>
										{interimTranscript} {showConfidence ? `${(interimConfidence * 100).toFixed(2)}%` : ''} 
									</Text>
								) : null}
							</Box>
						</Box>
						<Box
							w={{
								base: spitFlag === "vertical" ? "49%" : "100%",
								md: spitFlag === "vertical" ? "49%" : "100%",
								lg: spitFlag === "vertical" ? "49%" : "100%",
								xl: spitFlag === "vertical" ? "49%" : "100%",
							}}
							p="10px"
						>
							<Box
								// h="calc(100vh - 175px)"
								h="80%"
								overflow="auto"
								css={{
									"&::-webkit-scrollbar": {
										width: "4px",
									},
									"&::-webkit-scrollbar-track": {
										width: "6px",
									},
									"&::-webkit-scrollbar-thumb": {
										borderRadius: "24px",
									},
								}}
								mt="3"
							>
								{spitFlag == "vertical" && (
									<Text color="#000" fontSize={"14px"}>
										{communicationFragments?.map((item, index) => (
											<Flex key={index}>
												<Icon mr="1" as={AiOutlineCaretRight} />
												{item.language === targetLanguageCode && (
													<Text>
														{item.language}: {item.text}
														{showConfidence && item.confidence && typeof item.confidence === 'number' ?
															` (${(item.confidence * 100).toFixed(2)}%)` :
															''
														}
													</Text>
												)}
												{item.communication_polyglots?.map(
													(polyglot, index) =>
														polyglot.language === targetLanguageCode && (
															<Text key={index}>
																{polyglot.language}: {polyglot.text}
															</Text>
														)
												)}
											</Flex>
										))}
									</Text>
								)}
								{activeLanguage === "target" && spitFlag !== "horizontal" && interimTranscript ? (
									<Text color="#c6d704" fontSize={"14px"}>
										{interimTranscript} - {showConfidence ? `${(interimConfidence * 100).toFixed(2)}%` : ''}
									</Text>
								) : null}
							</Box>
						</Box>
					</Flex>
				</Box>
				{callCompleted ? (
					<Flex alignItems={"center"} justifyContent={"center"} bg={"red.50"} p={1} className="pause-step">
						<Button
							isDisabled={saveButtonDisable ? true : false}
							size={{ base: "xs", md: "sm", lg: "sm", xl: "sm" }}
							p="10px"
							borderRadius={"5px"}
							mr="2"
							onClick={e => {
								saveTranscript();
							}}
							colorScheme="green"
							leftIcon={<Icon as={AiOutlineSave}
							/>}
						>
							<Text
								as="span"
								display={{
									base: "none",
									md: "block",
									lg: "block",
									xl: "block",
								}}
								className="save"
							>
								Save
							</Text>
						</Button>

						<Button
							size={{ base: "xs", md: "sm", lg: "sm", xl: "sm" }}
							p="10px"
							borderRadius={"5px"}
							mr="2"
							onClick={e => {
								onOpen();
							}}
							colorScheme="cyan"
							leftIcon={<Icon as={AiOutlineMail} />}
						>
							<Text
								as="span"
								display={{
									base: "none",
									md: "block",
									lg: "block",
									xl: "block",
								}}
								className="email"
							>
								Email
							</Text>
						</Button>
						<Button
							size={{ base: "xs", md: "sm", lg: "sm", xl: "sm" }}
							p="10px"
							borderRadius={"5px"}
							mr="2"
							onClick={e => {
								setCommunicationFragments([]);
								setInterimTranscript("");
								setInterimConfidence(0);
							}}
							colorScheme="teal"
							leftIcon={<Icon as={AiOutlineClear} />}
						>
							<Text
								as="span"
								display={{
									base: "none",
									md: "block",
									lg: "block",
									xl: "block",
								}}
							>
								Clear
							</Text>
						</Button>
						<Button
							onClick={() => {
								handleHomeRouteFlag();
							}}
							borderRadius={"5px"}
							size={{ base: "xs", md: "sm", lg: "sm", xl: "sm" }}
							p="10px"
							colorScheme="pink"
							className="home"
						>
							<Icon as={AiOutlineHome} />
						</Button>
						<Button
							onClick={() => {
								onAlertOpen();
							}}
							borderRadius={"5px"}
							size={{ base: "xs", md: "sm", lg: "sm", xl: "sm" }}
							p="10px"
							colorScheme="pink"
							className="home"
							ml={2}
						>
							AI Report
						</Button>
					</Flex>
				) : (
					<Flex alignItems={"center"} justifyContent={"center"} bg={"red.50"} p={1}>
						{/* {basicAiCallUuid != null */}
						<Button
							size={{ base: "xs", md: "sm", lg: "sm", xl: "sm" }}
							p="10px"
							mr="3"
							borderRadius={"5px"}
							onClick={() => {
								setRecordingState(true);
								playSilentAudio()
								toggleRecording();
								setRun(true);
								setStepIndex(prevIndex => prevIndex + 1);
							}}
							colorScheme={recordingState ? "red" : "green"}
							// isDisabled={micDisabled || !targetLanguageRef.current}
							isDisabled={buttonConnectedLoad}
							leftIcon={<Icon as={recordingState ? AiOutlineAudioMuted : AiOutlineAudio} />}
						>
							<Text
								className={recordingState ? 'stop' : 'call'}
								as="span"
								display={{
									base: "none",
									md: "block",
									lg: "block",
									xl: "block",
								}}
							>
								{recordingState ? "Stop " : "Start "} Call
							</Text>
						</Button>
						{/* :<Text p={2}>Call Completed</Text>} */}
						{recordingState && (
							<>
								<Button
									size={{
										base: "xs",
										md: "sm",
										lg: "sm",
										xl: "sm",
									}}
									p="10px"
									borderRadius={"5px"}
									mr="3"
									colorScheme={pauseFlag ? "red" : "green"}
									leftIcon={<Icon as={pauseFlag ? AiOutlineAudioMuted : AiOutlineAudio} />}
									onClick={() => {
										pauseTranslationCall();
									}}
									className="pause"
								>
									{pauseFlag ? "Pause" : "Resume"}
								</Button>
								{call_control && participants.length <= 1 && (
									<Flex direction="row" ml={3} alignItems="center">
										<Input
											placeholder="Enter phone number"
											value={phoneNumber}
											onChange={(e) => setPhoneNumber(e.target.value)}
											size="sm"
											width="200px"
										/>
										<Button
											colorScheme="blue"
											size="sm"
											ml={2}
											onClick={() => {
												if (phoneNumber) {
													makeCall(phoneNumber);
												}
											}}
										>
											Add Participant
										</Button>
									</Flex>
								)}
								{participants.some(participant => participant.is_phone_call_participant) && (
									<Flex direction="row" ml={3} alignItems="center">
										{participants
											.filter(participant => participant.is_phone_call_participant)
											.map((participant, index) => (
												<Text key={index}>{participant.phone_number} - {participant.call_status}</Text>
											))
										}
									</Flex>
								)}
							</>
						)}
					</Flex>
				)}
				{/* Popup Modal */}
				<Modal isOpen={isOpen} onClose={onClose}>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>Send Email</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<Text>Please use enter to multiple emails in the textbox.</Text>
							<TagsInput value={skillsKeyword} onChange={onTagChange} inputProps={{ placeholder: "Enter Email" }} />
							<Flex flexWrap="wrap" alignItems={"center"} mt="2">
								{invalidEmail.map((email, index) => (
									<Text p="1" mr="1" bg="red.100" color="#000" size="sm" key={index}>
										{email}
									</Text>
								))}
								{invalidEmail?.length ? <Text>Not Valid Email </Text> : null}
							</Flex>
						</ModalBody>
						<ModalFooter>
							<Button
								colorScheme="green"
								mr={3}
								onClick={() => {
									emailTranscript();
								}}
								isLoading={emailLoader ? true : false}
								isDisabled={invalidEmail?.length > 0 || !skillsKeyword?.length ? true : false}
							>
								Send Mail
							</Button>
							<Button colorScheme="blue" onClick={onClose}>
								Close
							</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>

				{/**Ai report  */}
				<AlertDialog
					isOpen={isAlertOpen}
					leastDestructiveRef={cancelRef}
					onClose={onAlertClose}
				>
					<AlertDialogOverlay>
						<AlertDialogContent>
							<AlertDialogHeader fontSize='lg' fontWeight='bold'>
								AI Report
							</AlertDialogHeader>

							<AlertDialogBody>
								<Text as="h4" fontSize="md">Average Confidence score - {(avgConfidencePer.avg * 100).toFixed(2)}%</Text>
							</AlertDialogBody>

							<AlertDialogFooter>
								<Button ref={cancelRef} onClick={onAlertClose}>
									Close
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialogOverlay>
				</AlertDialog>
			</Box>
		</>
	);
};

export default BasicAICommDemo;
