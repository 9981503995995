// React Chakra Imports
import React from "react";
import {
	Box,
	Flex,
	Text,
	Icon,
	Stack,
	InputGroup,
	InputLeftElement,
	Input,
} from "@chakra-ui/react";
import { SiClickhouse } from "react-icons/si";
import { PiLineSegment } from "react-icons/pi";
import { TbBrandElastic } from "react-icons/tb";
import { AiOutlineSearch } from "react-icons/ai";
import { FaAws, FaCloudflare } from "react-icons/fa";

function TrustSubprocessor() {
	const cloudServices = [
		{
			icon: FaAws,
			name: "Amazon Web Services",
			feature: "Cloud provider",
		},
		{
			icon: FaCloudflare,
			name: "Cloudflare",
			feature: "Cloud monitoring",
		},
		{
			icon: TbBrandElastic,
			name: "Elastic",
			feature: "Engineering",
		},
		{
			icon: PiLineSegment,
			name: "Segment",
			feature: "Data analytics",
		},
		{
			icon: SiClickhouse,
			name: "Clickhouse",
			feature: "Data Processing",
		},
	];
	return (
		<Box>
			<Flex alignItems={"center"} justifyContent={"space-between"} my="6">
				<Text mr="3" fontSize={"18px"} fontWeight="500">
					Subprocessors
				</Text>
				<Stack spacing={4} w="60%" alignItems={"center"}>
					<InputGroup>
						<InputLeftElement pointerEvents="none">
							<Icon
								mt="-1"
								as={AiOutlineSearch}
								color="gray.300"
							/>
						</InputLeftElement>
						<Input
							size="sm"
							type="text"
							placeholder="Search Subprocessors"
						/>
					</InputGroup>
				</Stack>
			</Flex>
			{cloudServices.map((service, index) => (
				<Box
					borderWidth={"1px"}
					borderTop={index === 0 ? "1px" : "0px"}
					borderTopColor={"#d7d7d7"}
					borderTopLeftRadius={index === 0 ? "5px" : "0px"}
					borderTopRightRadius={index === 0 ? "5px" : "0px"}
					borderBottomLeftRadius={
						index === cloudServices.length - 1 ? "5px" : "0px"
					}
					borderBottomRightRadius={
						index === cloudServices.length - 1 ? "5px" : "0px"
					}
					p="20px"
				>
					<Flex
						key={index}
						alignItems="center"
						mt={index !== 0 ? "3" : undefined}
					>
						<Icon as={service.icon} mr="3" fontSize="20px" />
						<Text>{service.name}</Text>
						<Text ml="3" fontSize="md">
							&#8226; {service.feature}
						</Text>
					</Flex>
				</Box>
			))}
		</Box>
	);
}

export default TrustSubprocessor;
