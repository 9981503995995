import { Box, Button, Flex, FormControl, FormLabel, Icon, Spinner, Switch, Text, useDisclosure, Input, useToast, Alert, AlertIcon, useColorModeValue } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { AiFillLeftCircle, AiFillRightCircle, AiOutlineStop } from 'react-icons/ai'

import UpdateSetting from './UpdateSetting'
import { useBlueButton, useBrandBg, useCardHoverBg, useCardTextHover, useTextColor } from '../../theme/globalColorTheme'
import { createNewSetting, fetchSettingsData, manageSetting, updateSetting } from '../../services/settingServices'
import { toastFunctionToaster } from '../../utils/toastFunction'
import Pagination from '../../components/pagination/Pagination'
import { SearchBar } from '../../components/navbar/searchBar/SearchBar'


const ApplicationSettings = () => {

	const [loading, setLoading] = useState(false)
	const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(false);
	const accountCardHoverBg = useCardHoverBg()
	const cardHoverTextColor = useCardTextHover()
	const brandBg = useBrandBg()
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [currentPage, setCurrPage] = useState(1)
	const [totalPages, setTotalPages] = useState(0)
	const [recordsPerPage, setRecordsPerPage] = useState(5)
	const [formData, setFormData] = useState({
		setting_name: "",
		setting_details: "",
		value_type: "",
		setting_value: "",
		setting_type: "",
		setting_group: "",
		setting_component: "",
		setting_function: "",
	});
	const [settingsData, setSettingsData] = useState([])
	const [selectedSettingDetails, setSelectedSettingdetails] = useState(null)
	const [searchValue, setSearchValue] = useState('')
	const [buttonLoader, setButtonLoader] = useState(false)

	const toast = useToast();
	const blueColor = useBlueButton()
	const searchBg = useColorModeValue("whiteAlpha.800", "whiteAlpha.100")
	const blackWhiteText = useTextColor()


	useEffect(() => {
		handleFetchSettingsData()
	}, [currentPage, recordsPerPage])

	const handleClose = () => {
		setSelectedSettingdetails(null)
		onClose();
	}

	const handleUpdateSetting = (data) => {
		setButtonLoader(true)
		updateSetting(data).then(resp => {
			if (resp?.result) {
				handleFetchSettingsData()
				handleClose()
				toast(toastFunctionToaster(resp?.message, "success"));
			} else {
				toast(toastFunctionToaster(resp?.message, "error"));
			}
			setButtonLoader(false)
		}).catch(err => {
			setButtonLoader(false)
			toast(toastFunctionToaster("Failed to Update setting", "success"));
		})
	}

	const SettingDetails = () => {
		return (
			settingsData.length > 0 ?
				settingsData.filter(item => item?.setting_name.indexOf(searchValue) > -1).map(item => (
					<Flex
						w={"100%"}
						rounded={"md"}
						align={"center"}
						p={1}
						mt={1}
						key={item?.setting_uuid}
						borderWidth={"0.05px"}
						borderColor={"green.300"}>
						<Flex
							role="group"
							_hover={{ cursor: "pointer", bg: accountCardHoverBg, color: cardHoverTextColor }}
							rounded={"md"}
							px={1}
							w={"100%"}
							align={"center"}>
							<Flex w={"100%"} p={1} align={"center"}>
								<Flex minW={"150px"} maxW={"150px"}>
									<Text rounded={"md"} bg={!item?.setting_enabled ? "green.200" : "red.200"} p={2} color="black">
										{!item?.setting_enabled ? (
											<Icon as={AiOutlineStop} color={"red.600"} ml={1} mr={1} mt={1} />
										) : null}
										{item?.setting_name}
									</Text>
								</Flex>
								<Flex w={"100%"} direction={"column"}>
									<Flex w={"100%"}>
										<Text>
											Setting value:{" "}
											<Text as="spam" fontWeight={600}>
												{item?.setting_value}
											</Text>
										</Text>
										
									</Flex>
									<Flex w={"100%"}>
										<Text>
											Setting type:{" "}
											<Text as="spam" fontWeight={600}>
												{item?.setting_type}
											</Text>
										</Text>
										<Text ml={2}>
											Setting group:{" "}
											<Text as="spam" fontWeight={600}>
												{item?.setting_group}
											</Text>
										</Text>
									</Flex>
									<Flex w={"100%"} align={"center"}>
										<Text>
											Setting component:{" "}
											<Text as="spam" fontWeight={600}>
												{item?.setting_component}
											</Text>
										</Text>
										<Text ml={2}>
											Setting function:{" "}
											<Text as="spam" fontWeight={600}>
												{item?.setting_function}
											</Text>
										</Text>
									</Flex>
								</Flex>
							</Flex>
						</Flex>
						<Flex
							px={2}
							alignItems={"center"}
							borderLeftWidth={"1px"}
							borderLeftColor={"gray.400"}
							minW={"100px"}
							align={"end"}>
							<Switch
								onChange={() => {
									handleManageSetting(item?.setting_uuid)
								}}
								isChecked={item?.setting_enabled}
							/>
							<Button p={4} onClick={() => {
								setSelectedSettingdetails(item)
								onOpen()
							}} ml={2}
							bg={blueColor}>
								Update
							</Button>
						</Flex>
					</Flex>
				))
				: <Alert status="warning" mt="4">
					<AlertIcon />
					<Text>You dont have any Application settings </Text>
					<Text ml={5} _hover={{ cursor: 'pointer', textDecoration: 'underline' }}  onClick={()=>setConfiguationCollapseFlag(true)}>
						<b>CREATE NEW Setting!!</b>
					</Text>
				</Alert>
		)
	}

	const handleFetchSettingsData = () => {
		setLoading(true)
		const reqBody = {
			"records_per_page": recordsPerPage,
			"page_id": currentPage
		}
		fetchSettingsData(reqBody).then(resp => {
			if (resp.result) {
				setSettingsData(resp?.data[0]?.records)
				setTotalPages(resp?.data[0]?.total_pages)
			} else {
				toast(toastFunctionToaster(resp?.message, "error"));
				setSettingsData([])
				setTotalPages(0)
			}
			setLoading(false)
		}).catch(err => {
			setLoading(false)
			setSettingsData([])
			setTotalPages(0)
			toast(toastFunctionToaster(err?.message, "error"));
		})
	}

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleCreateSetting = (e) => {
		e.preventDefault();
		setButtonLoader(true)
		createNewSetting(formData).then(resp => {
			if (resp.result) {
				toast(toastFunctionToaster(resp.message, "success"))
				handleFetchSettingsData()
				setConfiguationCollapseFlag(false)
			} else {
				toast(toastFunctionToaster(resp.message, "error"))
			}
			setButtonLoader(false)
			setFormData({
				setting_name: "",
				setting_details: "",
				value_type: "",
				setting_value: "",
				setting_type: "",
				setting_group: "",
				setting_component: "",
				setting_function: "",
			})
		}).catch(err => {
			setButtonLoader(false)
			toast(toastFunctionToaster('Failed to add setting', "error"))
		})
	};

	const handleManageSetting = (uuid) => {
		const reqBody = {
			'setting_uuid': uuid
		}
		manageSetting(reqBody).then(resp => {
			if (resp.result) {
				toast(toastFunctionToaster(resp.message, "success"))
				handleFetchSettingsData()
			} else {
				toast(toastFunctionToaster(resp.message, "error"))
			}
		}).catch(err => {
			toast(toastFunctionToaster("Failed to manage setting", "error"))
		})
	}

	return (
		<>
			<Box direction="column" pt={{ sm: "125px", lg: "75px" }} mt="4">
				<Flex h={'calc(100vh - 200px)'} overflowX="auto">
					<Box
						w={!configurationCollapseFlag ? "95%" : "85%"}
						bg={brandBg}
						p={1}
						borderRadius={"10px"}
						overflow="hidden">
						<Flex w={"100%"} direction={"column"}>
							{loading ? (
								<Flex w={"100%"} p={10} justifyContent={'center'} direction={"column"} align={"center"} h="50vh">
									<Spinner size={"xl"} />
								</Flex>
							) : (
								<Flex w={"100%"} direction={"column"}>
									<Flex
										direction={"column"}
										w={"100%"}
										h="calc(100vh - 200px)"
										overflow="auto"
										borderTopWidth="0px"
										css={{
											"&::-webkit-scrollbar": {
												width: "4px",
											},
											"&::-webkit-scrollbar-track": {
												width: "6px",
											},
											"&::-webkit-scrollbar-thumb": {
												borderRadius: "24px",
											},
										}}>
										<Flex p={4} justifyContent="flex-end">
											<SearchBar placeholder="Search setting" background={searchBg} w='30%' value={searchValue} setValue={setSearchValue}/>
										</Flex>
										<SettingDetails />
									</Flex>
								</Flex>
							)}
						</Flex>
					</Box>
					{configurationCollapseFlag ? (
						<Flex w="35%">
							<Box
								w="100%"
								position="relative"
								p="3"
								borderRadius="15px"
								bg={brandBg}
								ml="2"
								overflowY="scroll"
							>
								<Box position="absolute" top="0" right="0" mr="2" mt="3" zIndex="999">
									<AiFillRightCircle
										cursor="pointer"
										size="20px"
										onClick={() => {
											setConfiguationCollapseFlag(!configurationCollapseFlag);
										}}
									/>

								</Box>
								<Box w="100%" h="95%" mb={4}>
									<Text fontWeight={'600'} >Add Application Setting</Text>
									<Box mt={4}>
										<form onSubmit={handleCreateSetting}>
											<Box>
												<FormControl isRequired mb={4}>
													<FormLabel>Setting name</FormLabel>
													<Input
														color={blackWhiteText}
														placeholder="Enter setting name"
														name="setting_name"
														type="text"
														value={formData.setting_name}
														onChange={handleChange}
													/>
												</FormControl>

												<FormControl mb={4}>
													<FormLabel>Setting details</FormLabel>
													<Input
														color={blackWhiteText}
														type="text"
														placeholder="Enter setting details"
														name="setting_details"
														value={formData.setting_details}
														onChange={handleChange}
													/>
												</FormControl>
												<FormControl mb={4}>
													<FormLabel>Value type</FormLabel>
													<Input
														color={blackWhiteText}
														placeholder="Enter value type"
														name="value_type"
														value={formData.value_type}
														onChange={handleChange}
													/>
												</FormControl>
												<FormControl isRequired mb={4}>
													<FormLabel>Setting value</FormLabel>
													<Input
														color={blackWhiteText}
														type="text"
														placeholder="Enter setting value"
														name="setting_value"
														value={formData.setting_value}
														onChange={handleChange}
													/>
												</FormControl>
												<FormControl isRequired mb={4}>
													<FormLabel>Setting type</FormLabel>
													<Input
														color={blackWhiteText}
														type="text"
														placeholder="Enter setting type"
														name="setting_type"
														value={formData.setting_type}
														onChange={handleChange}
													/>
												</FormControl>
												<FormControl isRequired mb={4}>
													<FormLabel>Setting group</FormLabel>
													<Input
														color={blackWhiteText}
														type="text"
														placeholder="Enter setting group"
														name="setting_group"
														value={formData.setting_group}
														onChange={handleChange}
													/>
												</FormControl>
												<FormControl isRequired mb={4}>
													<FormLabel>Setting component</FormLabel>
													<Input
														color={blackWhiteText}
														type="text"
														placeholder="Enter setting component"
														name="setting_component"
														value={formData.setting_component}
														onChange={handleChange}
													/>
												</FormControl>
												<FormControl isRequired mb={4}>
													<FormLabel>Setting function</FormLabel>
													<Input
														color={blackWhiteText}
														type="text"
														placeholder="Enter setting function"
														name="setting_function"
														value={formData.setting_function}
														onChange={handleChange}
													/>
												</FormControl>
											</Box>
											<Button colorScheme="blue" type="submit" m="1rem 0" isLoading={buttonLoader}>
												Create Setting
											</Button>
										</form>
									</Box>
								</Box>
							</Box>
						</Flex>
					) : (
						<Box w="5%" p="3" borderRadius="10px" bg={brandBg} h="100%" ml="2" pl="5">
							<Box w={"100%"} h="100%">
								<AiFillLeftCircle
									cursor="pointer"
									onClick={() => {
										setConfiguationCollapseFlag(!configurationCollapseFlag);
									}}
									size="20px"
								/>
								<Text
									fontSize="16px"
									mt="2"
									fontWeight='bold'
									css={{
										writingMode: "vertical-lr",
										textOrientation: "mixed",
									}}>
									Add Application Setting
								</Text>
							</Box>
						</Box>
					)}
				</Flex>
			</Box>
			<Pagination currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrPage} setRecordsPerPage={setRecordsPerPage} recordsPerPage={recordsPerPage}/>
			<UpdateSetting isOpen={isOpen} close={handleClose} handleOnsubmit={(data) => handleUpdateSetting(data)} data={selectedSettingDetails} isUpdateInProgress={buttonLoader}/>
		</>
	)
}

export default ApplicationSettings