import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Chakra Import
import {
  Box,
  Flex,
  Select,
  Text,
  Button,
  Icon,
  Spacer,
  Alert,
  Tabs, TabList, TabPanels, Tab, TabPanel,
  Drawer,
  Spinner,
  AlertIcon,
  FormLabel,
  SimpleGrid,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  useColorModeValue,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import InputField from "../../../components/fields/InputField";
import moment from "moment";
import Card from "../../../components/card/Card";

// Api Call Imports
import {
    getDeviceDetails, addBulkDevices, getDevTeamList, 
    getDevBusinessList, updateSelectedDevice, adminDeviceManage,
    assignSingleDevice
} from "../../../services/adminServices";
import { BiCheckboxMinus, BiSolidPlusSquare, BiCheckCircle } from "react-icons/bi";

// Component Imports
import { toastFunctionToaster } from "../../../utils/toastFunction";

function DevicesDetails() {
    const toast = useToast();
    const { state } = useLocation();
    const navigate = useNavigate();

    const [deviceUuid, setDeviceUuid] = useState(state.deviceUuid ? state.deviceUuid : null);
    const [deviceSerial, setDeviceSerial] = useState(state.deviceSerial ? state.deviceSerial : null);
    const textColor = useColorModeValue('secondaryGray.900', 'white');

    const btnRef = React.useRef();
    const [loading, isLoading] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [deviceList, setDeviceList] = useState([]);
    const [assignmentList, setAssignmentList] = useState([]);
    const [lastAssignment, setLastAssignment] = useState([]);
    const [error, setError] = useState({});

    const [deviceDetails, setDeviceDetails] = useState({
        deviceType: null,
        deviceClass: null,
        deviceSerial: null,
        deviceConfig: null
    });
    const [currentAction, setCurrentAction] = useState(null);
    const [bizList, setBizList] = useState([]);
    const [teamList, setTeamList] = useState([]);
    

    //   Todo:Set Form Values
    const onUpdateField = e => {
        const nextFormState = {
          ...deviceDetails,
          [e.target.name]: e.target.value,
        };
        setDeviceDetails(nextFormState);
    };
    
    const assignDeviceDetails = (deviceSerial, deviceClass, deviceType, deviceConfig) => {
        const nextFormState = {
            ...deviceDetails,
            ['deviceSerial']: deviceSerial,
            ['deviceClass']: deviceClass,
            ['deviceType']: deviceType,
            ['deviceConfig']: deviceConfig,
          };
          setDeviceDetails(nextFormState);
    };

    //   Todo:Fetch all devices
    useEffect(() => {
        fetchDeviceInfo();
      }, []);
    
    const fetchDeviceInfo = () => {
        isLoading(true);
        let paramObj = {
            device_uuid: deviceUuid
        };
        getDeviceDetails(paramObj)
          .then((res) => {
            isLoading(false);
            if (res?.data?.length > 0) {
                setDeviceList(res?.data[0]?.device);
                let devInfo = res?.data[0]?.device[0];
                assignDeviceDetails(devInfo?.serial, devInfo?.class, devInfo?.type, devInfo?.config);
                if (devInfo?.assignment?.length > 0){
                    setAssignmentList(devInfo?.assignment);
                }
                if (devInfo?.last?.length > 0){
                    setLastAssignment(devInfo?.last);
                }                
            }
          })
          .catch((err) => {
            isLoading(false);
          });
    };

    const fetchBusinessList = () => {
        let paramObj = {
            records_per_page: 10,
            page_id: 0
        };
        getDevBusinessList(paramObj)
          .then((res) => {
            if (res?.data?.length > 0) {
                setBizList(res?.data[0]);
            }
          })
          .catch((err) => {
            // isLoading(false);
          });
    };

    const fetchTeamList = () => {
        let paramObj = {
            team_code: 0
        };
        getDevTeamList(paramObj)
          .then((res) => {
            if (res?.data?.length > 0) {
                setTeamList(res?.data[0]);
            }
          })
          .catch((err) => {
            // isLoading(false);
          });
    };

    const manageSelectedDevice = (key, keyStatus) => {
        let paramObj = {
            device_uuid: deviceUuid,
            key: key,
            status: keyStatus
        };
        adminDeviceManage(paramObj)
          .then((res) => {
            if (res?.data?.length > 0) {
                fetchDeviceInfo();
                toast(toastFunctionToaster("Device status is updated successfully", "success"));
            }
          })
          .catch((err) => {
            toast(toastFunctionToaster("Failed to manage the selected device..", "error"));
          });
    };

    const assignSelectedDevice = (type, target) => {
        let paramObj = null;
        if (type === 'int'){
            paramObj = {
                device_uuid: deviceUuid,
                business_uuid: null,
                user_email: target
            };
        } else if (type === 'ext'){
            paramObj = {
                device_uuid: deviceUuid,
                business_uuid: target,
                user_email: null
            };
        }
        if (paramObj != null){
            assignSingleDevice(paramObj)
            .then((res) => {
                if (res?.data?.length > 0) {
                    setTeamList(res?.data[0]);
                    fetchDeviceInfo();
                    toast(toastFunctionToaster("Selected device is assigned successfully", "success"));
                }
            })
            .catch((err) => {
                toast(toastFunctionToaster("Unable to assign the selected device..", "error"));
            });
        }
    };

    const validateDeviceValues = () => {
        let fields = deviceDetails;
        let errors = {};
        let formIsValid = true;
        if (!fields['deviceType']) {
          formIsValid = false;
          errors['deviceType'] = '*Device Type is required.';
        }
        if (!fields['deviceClass']) {
            formIsValid = false;
            errors['deviceClass'] = '*Device Class is required.';
        }
        if (!fields['deviceSerial']) {
            formIsValid = false;
            errors['deviceSerial'] = '*Device Serial is required.';
        }
        if (!fields['deviceConfig']) {
            formIsValid = false;
            errors['deviceConfig'] = '*Device Info is required.';
        }
        setError(errors);
        return formIsValid;
    }

    // Todo:Edit Device Api call
    const editDeviceDetails = () => {
        if (validateDeviceValues){
            let objBody = {
                device_uuid: deviceUuid,
                device_type: deviceDetails.deviceType,
                device_class: deviceDetails.deviceClass,
                device_serial: deviceDetails.deviceSerial,
                device_config: deviceDetails.deviceConfig,
            };
            updateSelectedDevice(objBody)
            .then((res) => {
                if (res?.result) {
                    onClose();
                    resetDeviceDetails();
                    fetchDeviceInfo();
                    toast(toastFunctionToaster(res.message, "success"));
                } else {
                    onClose();
                    resetDeviceDetails();
                    toast(toastFunctionToaster(res.message, "error"));
                }
            })
            .catch((err) => {
                onClose();
                resetDeviceDetails();
                toast(toastFunctionToaster(err.message, "error"));
            });
        }
    };

    // Todo:Reset Device Details
    const resetDeviceDetails = () => {
        const nextFormState = {
            ...deviceDetails,
            ['deviceSerial']: null,
            ['deviceClass']: null,
            ['deviceType']: null,
            ['deviceConfig']: null,
          };
          setDeviceDetails(nextFormState);
    };

    // Todo:Reset details on drawer closed
    const closeDrawer = () => {
        onClose();
        setDeviceUuid(null);
        resetDeviceDetails();
    };

    const editDeviceTab = deviceUuid !== null && deviceList?.length > 0
    ?deviceList.map((item, index) => (
        <Flex w={'100%'} direction={'column'}>
        <Flex w={'100%'} direction={'column'} mb={5}>
            <Select
                value={deviceDetails.deviceClass}
                name="deviceClass"
                id={'deviceClass'}
                placeholder="Select Device Class"
                size="md"
                color={textColor}
                borderRadius="16px"
                onChange={e => {
                    onUpdateField(e);
                }}
            >
                <option value="Electronics">Electronics</option>
                <option value="Enclosure">Enclosure</option>
            </Select>
            {error?.deviceClass ? (
                <Text fontSize="sm" color="red" mb="10px">
                {error?.deviceClass}
                </Text>
            ) : null}
        </Flex>
        <Flex w={'100%'} direction={'column'} mb={5}>
            <Select
                value={deviceDetails.deviceType}
                name="deviceType"
                id={'deviceType'}
                placeholder="Select Device Type"
                size="md"
                color={textColor}
                borderRadius="16px"
                onChange={e => {
                    onUpdateField(e);
                }}
            >
                <option value="Tablet">Tablet</option>
                <option value="Lingolet BT">Lingolet BT</option>
                <option value="TableTop">TableTop</option>
                <option value="KickStand">KickStand</option>
            </Select>
        </Flex>
        {error?.deviceType ? (
            <Text fontSize="sm" color="red" mb="10px">
            {error?.deviceType}
            </Text>
        ) : null}
        <InputField
            mb={5}
            me="30px"
            name="deviceSerial"
            id="deviceSerial"
            value={deviceDetails.deviceSerial}
            onChange={onUpdateField}
            label="Device Serial Number"
            required="true"
            borderRadius="5px"
            placeholder="Enter Device Serial Number"
            w="100%"
            maxlength={100}
        />
        {error?.deviceSerial ? (
            <Text fontSize="sm" color="red" mb="10px">
            {error?.deviceSerial}
            </Text>
        ) : null}
        <FormLabel mt="3" ml="2" required>
        <Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
            Device Info
        </Text>
        <Textarea
            mb={5}
            me="30px"
            rows="10"
            id="deviceConfig"
            name="deviceConfig"
            value={deviceDetails.deviceConfig}
            onChange={onUpdateField}
            placeholder="Enter Device Info"
            w="100%"
            maxlength={500}
        />
        <Text
            textAlign={"right"}
            fontWeight={"500"}
            fontSize={"sm"}
            mt="2"
        >
            {deviceDetails?.deviceConfig?.length}/500
        </Text>
        {error?.deviceConfig ? (
            <Text fontSize="sm" color="red" mb="10px">
            {error?.deviceConfig}
            </Text>
        ) : null}
        </FormLabel>
        <Flex w={'100%'}>
            <Spacer />
            <Button variant="outline" mr={3}>
                Cancel
            </Button>
            <Button
                colorScheme="blue"
                onClick={() => {
                    setError({});
                    editDeviceDetails();
                }}
                >
                Update Device
            </Button>
        </Flex>
        </Flex>
    ))
    :null;

    const renderDevicesDetails = deviceList !== null && deviceList?.length > 0
    ? deviceList?.map((item, index) => (
        <Flex key={index} w={'100%'} mt={2}  direction={'column'} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
            <Card w={'100%'} mt={1} bg={item?.assigned ? 'green.100' : 'yellow.100'}>
                <Box minW={'150px'} bg={'gray.200'}  fontSize={'lg'} ml={1} pl={2}>
                    Device Details
                </Box>
                <Box minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
                    Assignment Status: {item?.assigned ? 'Assigned' : 'Not Assigned'}
                </Box>
                <Box minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
                    Class: {item?.class}
                </Box>
                <Box minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
                    Type: {item?.type}
                </Box>
                <Box minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
                    Serial: {item?.serial}
                </Box>
                <Box minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
                    Lost Status: {item?.lost? 'Lost' : 'Not Lost'}
                </Box>
                <Box minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
                    Archival Status{item?.archived? 'Archived' : 'Not Archived'}
                </Box>
                <Box minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
                    Added Date: {moment(item?.added_date).format("MMM DD,YYYY,HH:mm a")}
                </Box>
                <Box w={'100%'} fontSize={'sm'} ml={1} pl={2}>
                    Configuration: {item?.config}
                </Box>
            </Card>
        </Flex>
        ))
    : null;

    const renderAssignmentHistory = deviceList !== null && deviceList?.length > 0 && assignmentList?.length > 0
    ? assignmentList?.map((item, index) => (
        <Flex key={index} w={'100%'} mt={1}  direction={'column'} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
            <Card w={'100%'} mt={1}>
                <Flex w={'100%'}>
                    <Box w={'200px'}>
                        <Text fontSize={'xs'}>Asigned Date:</Text>
                        <Text fontSize={'sm'} color={'blue.400'}>{moment(item?.assigned_date).format("MMM DD,YYYY,HH:mm a")}</Text>
                    </Box>
                    {item?.int
                    ?<Box w={'350px'}>
                        <Text fontSize={'xs'}>Assigned to (Internal Team):</Text>
                        <Text fontSize={'sm'} color={'blue.400'}>{item?.email} </Text>
                    </Box>
                    :<Box w={'350px'}>
                        <Text fontSize={'xs'}>Assigned To (Business):</Text>
                        <Text fontSize={'sm'} color={'blue.400'}>{item?.biz_info}</Text>
                    </Box>}
                    <Box w={'150px'}>
                        <Text fontSize={'xs'}>Device Lock Info:</Text>
                        {item?.is_locked
                        ?<Text fontSize={'sm'} color={'blue.400'}>
                            <Text fontSize={'xs'} color={'red.400'}>Locked</Text>
                        </Text>
                        :<Text fontSize={'xs'} color={'green.400'}>Not Locked</Text>}
                    </Box>
                    <Box w={'150px'}>
                        <Text fontSize={'xs'}>Return:</Text>
                        {item?.is_returned
                        ?<Text fontSize={'sm'} color={'blue.400'}>
                            <Text fontSize={'xs'} color={'green.400'}>Returned</Text>
                        </Text>
                        :<Text fontSize={'xs'} color={'red.400'}>Not Returned</Text>}
                    </Box>
                </Flex>
                <Flex w={'100%'} align={'center'} mt={2}>
                        <Text fontSize={'xs'} mr={3}>Activation Info:</Text>
                        {item?.is_activated
                        ?<Text fontSize={'sm'} color={'blue.400'}>
                            Activated on {moment(item?.activation_date).format("MMM DD,YYYY,HH:mm a")}
                        </Text>
                        :<Text fontSize={'sm'} color={'red.400'}>Not Activated</Text>}
                        <Text fontSize={'xs'} ml={2}>(Activation Code:</Text>
                        <Text fontSize={'sm'} color={'gray.700'}>{item?.code})</Text>
                </Flex>
            </Card>
        </Flex>
        ))
    : <Text>
        This device does not have any assignment history
    </Text>;

    const assignExtDeviceDiv = deviceUuid !== null && bizList?.length > 0 && deviceList[0]?.assigned === false
    ? bizList?.map((item, index) => (
        <Flex key={index} w={'100%'} mt={2} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
            <Flex minW={'200px'}>
                {item?.name}
            </Flex>
            <Flex minW={'200px'}>
                {item?.city}
            </Flex>
            <Flex minW={'200px'}>
                <Button  
                    onClick={() => assignSelectedDevice('ext', item?.uuid)}
                    size={'sm'}>Assign Device</Button>
            </Flex>

        </Flex>
        ))
    : null;

    const assignIntDeviceDiv = deviceUuid !== null && teamList?.length > 0 && deviceList[0]?.assigned === false
    ? teamList?.map((item, index) => (
        <Flex key={index} w={'100%'} mt={2} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
            <Flex minW={'200px'}>
                {item?.fname}{' '}{item?.lname}
            </Flex>
            <Flex minW={'200px'}>
                {item?.email}
            </Flex>
            <Flex minW={'200px'}>
                <Button 
                    onClick={() => assignSelectedDevice('int', item?.email)}
                    size={'sm'}>Assign Device To {item?.email}</Button>
            </Flex>
        </Flex>
        ))
    : null;

    const externalDeviceManagement = deviceUuid !== null && lastAssignment?.length > 0
    ?<Flex w={'100%'} mt={2} direction={'column'} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
        <Card w={'100%'} mt={1}>
            <Flex w={'100%'}>
                <Box w={'120px'} align={'center'}>
                    {lastAssignment[0]?.is_locked
                    ?<Text bg={'red.100'} rounded={'md'} p={1}>Currently Locked</Text>
                    :<Text bg={'green.100'} rounded={'md'} p={1}>Not Locked</Text>}
                </Box>
                <Box w={'100%'} pl={3}>
                    <Text>Setting device as <b>locked or Unlocked</b></Text>
                    <Text fontSize={'xs'} color={'red.500'}>Be careful: This device will not be able to work if locked...</Text>
                </Box>
                <Box w={'300px'}  align='end'>
                    {lastAssignment[0]?.is_locked === false
                    ?<Button 
                        onClick={() => manageSelectedDevice('lock', true)}
                        bg={'red.100'}>Lock Device</Button>
                    :<Button 
                        onClick={() => manageSelectedDevice('lock', false)}
                        bg={'green.100'}>Unlock Device</Button>}
                </Box>
            </Flex>
        </Card>
        <Card w={'100%'} mt={1}>
            <Flex w={'100%'}>
                <Box w={'120px'} align={'center'}>
                    <Text bg={'green.100'} rounded={'md'} p={1}>{lastAssignment[0]?.code}</Text>
                </Box>
                <Box w={'100%'} pl={3}>
                    <Text>Manage <b>Activation Code</b></Text>
                    <Text fontSize={'xs'} color={'red.500'}>Be careful: Resetting activation code will force customer to reactivate the device...</Text>
                </Box>
                <Box w={'300px'}  align='end'>
                    <Button 
                        onClick={() => manageSelectedDevice('code', false)}
                        bg={'blue.100'}>Reset Activation Code</Button>
                    <Button m={1}
                        onClick={() => manageSelectedDevice('act', false)}
                        bg={'blue.100'}>Hard Activation</Button>

                </Box>
            </Flex>
        </Card>
    </Flex>
    : <Text>Device must be assigned to manage it</Text>;


    const internalDeviceManagement = deviceUuid !== null && deviceList?.length > 0
    ?<Flex w={'100%'} mt={2} direction={'column'} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
        <Card w={'100%'} mt={1}>
            <Flex w={'100%'}>
                <Box w={'120px'} align={'center'}>
                    {deviceList[0]?.assigned
                    ?<Text bg={'red.100'} rounded={'md'} p={1}>Currently Assigned</Text>
                    :<Text bg={'green.100'} rounded={'md'} p={1}>Not Assigned</Text>}
                </Box>
                <Box w={'100%'} pl={3}>
                    <Text>Set device status as <b>Returned and Unassigned</b></Text>
                    <Text fontSize={'xs'}>This device will be ready to re-assigned.</Text>
                </Box>
                <Box w={'300px'}  align='end'>
                    {deviceList[0]?.assigned
                    ?<Button 
                        onClick={() => manageSelectedDevice('back', false)}
                        bg={'blue.100'}>Set Returned and Unassigned</Button>
                    :null}
                </Box>
            </Flex>
        </Card>
        <Card w={'100%'} mt={1}>
            <Flex w={'100%'}>
                <Box w={'120px'} align={'center'}>
                    {deviceList[0]?.lost
                    ?<Text bg={'red.100'} rounded={'md'} p={1}>Currently Lost</Text>
                    :<Text bg={'green.100'} rounded={'md'} p={1}>Not Lost</Text>}
                </Box>
                <Box w={'100%'} pl={3}>
                    <Text>Set Device Status as Lost</Text>
                    <Text fontSize={'xs'}>This device will not be listed as a device to be assigned.</Text>
                </Box>
                <Box w={'200px'} align='end'>
                    {deviceList[0]?.lost
                    ?<Button onClick={() => manageSelectedDevice('lost', false)} bg={'red.100'}>Set as Not Lost</Button>
                    :<Button onClick={() => manageSelectedDevice('lost', true)} bg={'red.100'}>Set as Lost</Button>}
                </Box>
            </Flex>
        </Card>
        <Card w={'100%'} mt={1}>
            <Flex w={'100%'}>
                <Box w={'120px'} align={'center'}>
                    {deviceList[0]?.archived
                    ?<Text bg={'red.100'} rounded={'md'} p={1}>Currently Archived</Text>
                    :<Text bg={'green.100'} rounded={'md'} p={1}>Not Archived</Text>}
                </Box>
                <Box w={'100%'} p={3}>
                    <Text>Set Device Status as archived</Text>
                    <Text fontSize={'xs'}>This device will not be listed as a device to be assigned.</Text>
                </Box>
                <Box w={'200px'}  align='end'>
                    {deviceList[0]?.archived
                    ?<Button onClick={() => manageSelectedDevice('arch', false)} bg={'red.100'}>Set as Not Archived</Button>
                    :<Button onClick={() => manageSelectedDevice('arch', true)} bg={'red.100'}>Set as Archived</Button>}
                </Box>
            </Flex>
        </Card>

    </Flex>
    : null;
    

    return (
        <>
        {deviceSerial === null || deviceUuid === null
        ?<Box direction="column" pt={{ sm: "125px", lg: "75px" }}>
            <Flex w={'100%'}>
                <Text color={'red.300'}>There is some issues with your access. Please go back to Devices List page.</Text>
                <Spacer />
                <Button colorScheme="blue" size="sm" 
                    onClick={() => navigate("/admin/devices")}
                    >
                    Back to Devices
                </Button>                
            </Flex>
        </Box>
        :<Box direction="column" pt={{ sm: "125px", lg: "75px" }}>
            <Flex
            p="3"
            alignItems="center"
            borderBottomWidth="1px"
            justifyContent={"space-between"}
            >
                <Text fontWeight={"500"} fontSize={"18px"}>
                    Lingolet Device Serial: <b>{deviceSerial}</b>
                </Text>
                <Spacer />
                <Button colorScheme="blue" size="sm" 
                    onClick={() => navigate("/admin/devices")}
                    >
                    Back to Devices
                </Button>
            </Flex>
            {loading ? (
            <Flex alignItems="center" justifyContent="center" h="50vh">
                <Spinner size="xl" />
            </Flex>
            ) : deviceList?.length ? (
                <Flex w={'100%'}>
                    <Flex minW={'300px'} maxW={'500px'} h={'100%'}>
                        {renderDevicesDetails}
                    </Flex>
                    <Flex direction={'column'} w={'100%'} h={'100%'} p={0}>
                        <Card w={'100%'} mt={1}>
                            <Tabs>
                            <TabList>
                                <Tab>Assignment History</Tab>
                                <Tab>Edit Device</Tab>
                                <Tab>Assign Device</Tab>
                                <Tab>External Management</Tab>
                                <Tab>Internal Management</Tab>
                                <Tab>Remote Execution</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    {renderAssignmentHistory}
                                </TabPanel>
                                <TabPanel>
                                    {editDeviceTab}
                                </TabPanel>
                                <TabPanel>
                                    <Flex w={'100%'} direction={'column'}>
                                        {deviceList[0]?.assigned === false
                                        ?<Flex w={'100%'}>
                                            <Spacer />
                                            <Button 
                                                _hover={{bg: 'blue.100'}}
                                                bg={currentAction ==='intassign' ? 'blue.200': 'gray.200'}
                                                onClick={() => {
                                                    fetchTeamList();
                                                    setCurrentAction('intassign');
                                                }}>Internal Assignment</Button>
                                            <Button 
                                                ml={1}
                                                _hover={{bg: 'blue.100'}}
                                                bg={currentAction ==='extassign' ? 'blue.200': 'gray.200'}
                                                onClick={() => {
                                                    fetchBusinessList();
                                                    setCurrentAction('extassign');
                                                }}>External Assignment</Button>
                                        </Flex>
                                        :<Flex w={'100%'} p={2}>
                                            <Text>This device is already assigned!!</Text>
                                        </Flex>}
                                        {currentAction === 'extassign'
                                        ?<Flex w={'100%'} direction={'column'}>
                                            {assignExtDeviceDiv}
                                        </Flex>:null}
                                        {currentAction === 'intassign'
                                        ?<Flex w={'100%'} direction={'column'}>
                                            {assignIntDeviceDiv}
                                        </Flex>:null}
                                    </Flex>
                                </TabPanel>
                                <TabPanel>
                                    {externalDeviceManagement}
                                </TabPanel>
                                <TabPanel>
                                    {internalDeviceManagement}
                                </TabPanel>
                            </TabPanels>
                            </Tabs>
                        </Card>
                    </Flex>
                </Flex>
            ) : (
            <Alert status="warning" mt="4">
                <AlertIcon />
                No Device Found
            </Alert>
            )}
        </Box>}
        </>
    )
}

export default DevicesDetails;
