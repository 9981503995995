import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// Chakra imports
import {
  Box,
  Spacer,
  Tab,
  Flex,
  Badge,
  Tabs,
  Icon,
  Text,
  Alert,
  Button,
  Spinner,
  TabList,
  AlertIcon,
  TabPanel,
  TabPanels,
  AlertTitle,
  SimpleGrid,
  AlertDescription,
} from "@chakra-ui/react";
import {
  ViewIcon,
  SmallAddIcon,
  AttachmentIcon,
  ChevronLeftIcon,
} from "@chakra-ui/icons";
import {
  BiCheckboxMinus,
  BiSolidPlusSquare,
  BiCheckCircle,
  BiStopCircle,
} from "react-icons/bi";
import moment from "moment";

// Components
import Card from "../../../../components/card/Card";
import UploadDocs from "./components/UploadDocs";
import AddTextContent from "./components/AddTextContent";
import EditableContentList from "./components/EditableContentList";
import { ContentListingHeader } from "./components/ContentListingHeader";
import { translationLanguages } from "../../../../utils/TranslationLanguages";
import { transcriptionLanguages } from "../../../../utils/TranscriptionLanguage";

// API
import { getEmailId } from "../../../../utils/localStorageIndex";
import { listingContentWorkflowUser } from "../../../../services/moduleServices";

function ContentWorkflowMgmt() {
  const { state } = useLocation();
  const emaiId = getEmailId();
  const navigate = useNavigate();
  console.log("state", state);
  const projectUuid = state?.projectUuid ? state?.projectUuid : null;
  const contentUuid = state?.contentUuid ? state?.contentUuid : null;
  const contentTitle = state?.contentTitle ? state?.contentTitle : null;

  const [loading, isLoading] = useState(false);
  const [workflowList, setWorkflowList] = useState([]);

  useEffect(() => {
    if (projectUuid != null && contentUuid != null) {
      fetchContentWorkflows();
    }
  }, []);

  const RefreshPage = () => {
    if (projectUuid != null && contentUuid != null) {
      fetchContentWorkflows();
    }
  };
  const fetchContentWorkflows = () => {
    isLoading(true);
    let objData = {
      project_uuid: projectUuid,
      content_uuid: contentUuid,
    };
    listingContentWorkflowUser(objData)
      .then((res) => {
        if (res?.result && res?.data?.length > 0) {
          isLoading(false);
          setWorkflowList(res?.data[0]?.workflows);
        }
      })
      .catch((err) => {
        isLoading(false);
      });
  };

  const getTranslationLanguagesFromCode = (langArr) => {
    let languageList = [];
    let langCodes = langArr.split(",");
    if (langCodes.length > 0) {
      langCodes.map((item, index) => {
        let obj = translationLanguages.find((o) => o.code === item);
        if (obj != null || obj != undefined) {
          languageList.push(obj?.name);
        }
      });
    }
    return languageList;
  };

  const getTranscriptionLanguagesFromCode = (langArr) => {
    let languageList = [];
    let langCodes = langArr.split(",");
    if (langCodes.length > 0) {
      langCodes.map((item, index) => {
        let obj = transcriptionLanguages.find((o) => o.code === item);
        if (obj != null || obj != undefined) {
          languageList.push(obj?.name);
        }
      });
    }
    return languageList;
  };

  const renderLanguageListDetails = (langData) => {
    if (langData.length > 0) {
      return langData?.map((item, index) => {
        return (
          <Text m={1} p={1} fontSize={"xs"} bg={"blue.100"} rounded={"md"}>
            {item}
          </Text>
        );
      });
    }
  };

  const getJobExType = (jobName) => {
    if (jobName != null) {
      let doc = ["docx", "doc", "pdf", "txt"].map((item, index) => {
        if (jobName.indexOf(item) >= 0) return true;
        else return false;
      });
      if (jobName.indexOf("mp3") >= 0) {
        return "(Audio File)";
      } else if (
        jobName.indexOf("docx") >= 0 ||
        jobName.indexOf("pdf") >= 0 ||
        jobName.indexOf("txt") >= 0 ||
        jobName.indexOf("doc") >= 0
      ) {
        return "(Document)";
      } else if ("realtime".indexOf(jobName) >= 0) {
        return "(Realtime Text)";
      }
    }
  };

  const renderAllWorkflows = () => {
    if (workflowList?.length > 0) {
      return workflowList?.map((item, index) => {
        return (
          <Flex
            w={"100%"}
            mt={2}
            borderBottomWidth={"0.05px"}
            borderBottomColor={"gray.200"}
          >
            <Box minW={"150px"} fontSize={"sm"} align={"center"}>
              <Text>{moment(item?.created).format("MMM DD,YYYY,HH:mm a")}</Text>
              <Button size="xs" bg={"green.100"} mt={1}>
                Workflow Info
              </Button>
            </Box>
            <Box
              w={"100%"}
              borderLeftWidth={"0.05px"}
              borderLeftColor={"gray.200"}
              ml={1}
              pl={2}
            >
              <Flex
                w={"100%"}
                align={"center"}
                borderBottomWidth={"0.05px"}
                borderBottomColor={"gray.200"}
              >
                <Text>
                  <b>{item?.code}</b>
                </Text>
                <Text fontSize={"xs"} ml={2}>
                  {getJobExType(item?.job_name)}
                </Text>
              </Flex>
              {item?.code === "Translation" ? (
                <Flex
                  w={"100%"}
                  align={"center"}
                  borderBottomWidth={"0.05px"}
                  borderBottomColor={"gray.200"}
                >
                  <Text mr={1}>Source Language</Text>
                  {renderLanguageListDetails(
                    getTranslationLanguagesFromCode(item?.source)
                  )}
                  <Text ml={5} mr={1}>
                    Target Language(s)
                  </Text>
                  {renderLanguageListDetails(
                    getTranslationLanguagesFromCode(item?.languages)
                  )}
                </Flex>
              ) : null}
              {item?.code === "Transcription" ? (
                <Flex
                  w={"100%"}
                  align={"center"}
                  borderBottomWidth={"0.05px"}
                  borderBottomColor={"gray.200"}
                >
                  <Text mr={1}>Source Language</Text>
                  {renderLanguageListDetails(
                    getTranscriptionLanguagesFromCode(item?.source)
                  )}
                  <Text ml={5} mr={1}>
                    Target Language(s)
                  </Text>
                  {renderLanguageListDetails(
                    getTranscriptionLanguagesFromCode(item?.languages)
                  )}
                </Flex>
              ) : null}
              <Flex
                w={"100%"}
                mt={1}
                borderBottomWidth={"0.05px"}
                borderBottomColor={"gray.200"}
              >
                <Text mt={2} color={"blue.400"} fontSize={"xs"}>
                  Submitted By: {item?.email}
                </Text>
              </Flex>
            </Box>
            <Box
              minW={"100px"}
              borderLeftWidth={"0.05px"}
              borderLeftColor={"gray.200"}
              ml={1}
              pl={2}
            >
              {item?.completed ? (
                <Text color={"blue.200"}>Job Completed</Text>
              ) : item?.started && item?.completed === false ? (
                <Text color={"green.300"}>Job Started</Text>
              ) : (
                <Text color={"red.300"}>Not Started</Text>
              )}
              {item?.status === "failed" ? (
                <Text color={"red.300"}>Failed</Text>
              ) : null}
            </Box>
            <Box
              minW={"250px"}
              borderLeftWidth={"0.05px"}
              borderLeftColor={"gray.200"}
              ml={1}
              pl={2}
            >
              {item?.priority ? (
                <Flex w={"200px"}>
                  <Icon mt={1} as={BiCheckCircle} color={"green.300"} mr={1} />{" "}
                  Top Priority
                </Flex>
              ) : null}
              {item?.proofreading ? (
                <Flex w={"200px"}>
                  <Icon mt={1} as={BiCheckCircle} color={"green.300"} mr={1} />{" "}
                  Proofreading Added
                </Flex>
              ) : (
                <Flex w={"200px"}>
                  <Icon mt={1} as={BiStopCircle} color={"red.200"} mr={1} />{" "}
                  Proofreading Not Added
                </Flex>
              )}
              {item?.email_response ? (
                <Flex w={"200px"}>
                  <Icon mt={1} as={BiCheckCircle} color={"green.300"} mr={1} />{" "}
                  Result in Email
                </Flex>
              ) : (
                <Flex w={"200px"}>
                  <Icon mt={1} as={BiStopCircle} color={"red.200"} mr={1} />{" "}
                  Result not in email
                </Flex>
              )}
            </Box>
            <Box
              minW={"150px"}
              borderLeftWidth={"0.05px"}
              borderLeftColor={"gray.200"}
              ml={1}
              pl={2}
            >
              {item?.billed ? (
                <Flex w={"200px"}>
                  <Icon mt={1} as={BiCheckCircle} color={"green.300"} mr={1} />{" "}
                  Billed
                </Flex>
              ) : (
                <Flex w={"200px"}>
                  <Icon mt={1} as={BiCheckCircle} color={"green.300"} mr={1} />{" "}
                  Not Billed
                </Flex>
              )}
              {item?.billed === false ? null : item?.paid ? (
                <Flex w={"200px"}>
                  <Icon mt={1} as={BiCheckCircle} color={"green.300"} mr={1} />{" "}
                  Paid
                </Flex>
              ) : (
                <Flex w={"200px"}>
                  <Icon mt={1} as={BiCheckCircle} color={"green.300"} mr={1} />{" "}
                  Paid
                </Flex>
              )}
            </Box>
            <Box
              minW={"150px"}
              borderLeftWidth={"0.05px"}
              borderLeftColor={"gray.200"}
              align={"center"}
              ml={1}
              pl={2}
            >
              {item?.completed ? (
                <Button
                  onClick={() => {
                    navigate("/business/project/content/workflow_result", {
                      state: {
                        projectUuid: projectUuid,
                        workflowUuid: item?.wuuid,
                        contentUuid: item?.cuuid,
                        contentTitle: contentTitle,
                      },
                    });
                  }}
                  size={"sm"}
                  bg={"blue.100"}
                >
                  View Results
                </Button>
              ) : (
                <Button size={"sm"} bg={"red.100"}>
                  Results No Ready
                </Button>
              )}
              {item?.status === "failed" ? (
                <Text
                  w={"60px"}
                  mt={1}
                  bg={"red.400"}
                  color={"gray.100"}
                  rounded={"md"}
                  p={1}
                >
                  Failed
                </Text>
              ) : (
                <Text mt={1} bg={"green.100"} rounded={"md"} p={1}>
                  {item?.status}
                </Text>
              )}
            </Box>
          </Flex>
        );
      });
    }
  };

  return (
    <>
      <Box direction="column" pt={{ sm: "125px", lg: "75px" }}>
        <Flex
          p="3"
          alignItems="center"
          borderBottomWidth="1px"
          justifyContent={"space-between"}
        >
          <Text fontWeight={"500"} fontSize={"18px"}>
            <b>{contentTitle}</b> Translation and Transcription Workflows
          </Text>
          <Spacer />
          <Button
            onClick={() => {
              navigate("/business/projects", {
                state: {
                  projectUuid: projectUuid,
                  contentUuid: contentUuid,
                },
              });
            }}
            colorScheme="blue"
            size="sm"
          >
            Back to Projects
          </Button>
          {projectUuid != null && contentUuid != null ? (
            <Button
              onClick={() => {
                navigate("/business/project/content", {
                  state: {
                    projectUuid: projectUuid,
                    contentUuid: contentUuid,
                  },
                });
              }}
              colorScheme="blue"
              size="sm"
              ml={1}
            >
              Back to Contents
            </Button>
          ) : null}
          <Button
            onClick={() => RefreshPage()}
            colorScheme="blue"
            size="sm"
            ml={1}
          >
            Refresh
          </Button>
        </Flex>
        {loading ? (
          <Flex alignItems="center" justifyContent="center" h="50vh">
            <Spinner size="xl" />
          </Flex>
        ) : workflowList?.length ? (
          <Flex w={"100%"}>
            <Card w={"100%"} mt={1}>
              {renderAllWorkflows()}
            </Card>
          </Flex>
        ) : (
          <Alert status="warning" mt="4">
            <AlertIcon />
            No Project Found
          </Alert>
        )}
      </Box>
    </>
  );
}

export default ContentWorkflowMgmt;
