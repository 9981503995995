// Chakra imports
// Chakra imports
import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  Icon,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";
// Custom components
import Card from "../../components/card/Card.js";
// Custom icons
import { AiOutlineEllipsis } from "react-icons/ai";

import React from "react";
import { useWhiteLightdarkBg } from "../../theme/globalColorTheme.js";

export default function Default(props) {
  const { startContent, endContent, name, growth, value, loading } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";
  const whiteLightdarkBg = useWhiteLightdarkBg()

  return (
    <Card py='15px' bg={whiteLightdarkBg}>
      <Flex
        my='auto'
        h='100%'
        align={{ base: "center", xl: "start" }}
        justify={{ base: "center", xl: "center" }}>
        {startContent}

        <Stat my='auto' ms={startContent ? "18px" : "0px"}>
          <StatLabel
            lineHeight='100%'
            color={textColorSecondary}
            fontSize={{
              base: "sm",
            }}>
            {name}
          </StatLabel>
          {loading ? (
                <Icon h='3rem' w='3rem' as={AiOutlineEllipsis}/>
            ):(
              <StatNumber
                color={textColor}
                fontSize={{
                  base: "2xl",
                }}>
                {value}
            </StatNumber>
            )}
          {growth ? (
            <Flex align='center'>
              <Text color='green.500' fontSize='xs' fontWeight='700' me='5px'>
                {growth}
              </Text>
              <Text color='secondaryGray.600' fontSize='xs' fontWeight='400'>
                since last month
              </Text>
            </Flex>
          ) : null}
        </Stat>
        <Flex ms='auto' w='max-content'>
          {endContent}
        </Flex>
      </Flex>
    </Card>
  );
}
