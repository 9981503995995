import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Box,
    Avatar,
    Image,
    Heading,
    Flex,
    Text,
    useColorModeValue
} from "@chakra-ui/react";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";

// Component Imports
import HumanAIBg from "../../../assets/communicator/HumanIcon.png";
import BasicAIBg from "../../../assets/communicator/SecureAI.png";
import PremiumAIBg from "../../../assets/communicator/Premium_AIIcon.png";
import MultichannelBg from "../../../assets/communicator/multichannelai.png";
import DocumentAiLightBg from "../../../assets/communicator/DocumentAILight.png";
import Card from "../../../components/card/Card";
import { setParentLogo } from "../../../store/actions/topNotificationActions";

// Api Call Imports
import { fetchAccLocInfo, fetchSubPersonalInfo } from "../../../services/accountServices";
import SubDashboardTab from "./SubDashboardTab";
import { useTextColor } from "../../../theme/globalColorTheme";

const SubscriberDashboard = (props) => {
    const dispatch = useDispatch()
    const [loading, isLoading] = useState(false);
    const brandBg = useColorModeValue("blue.50", "navy.900");
    const [accountUuid, setAccountUuid] = useState(null);
    const [locationUuid, setLocationUuid] = useState(null);
    const [subscriberUuid, setSubscriberUuid] = useState(null);
    const [accountInfo, setAccountInfo] = useState([]);
    const [locationInfo, setLocationInfo] = useState([]);
    const [subscriberInfo, setSubscriberInfo] = useState([]);
    const [subAppDetails,setSubAppDetails] = useState([]);
    const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(true);
    const blackWhiteText = useTextColor()

    useEffect(()=>{
        collectSubscriberInfo();
    },[])

    //Fetch Parent Account and Location details
    useEffect(() => {
        collectAccountLocationInfo();
    }, [accountUuid]);

    const collectAccountLocationInfo = () => {
        isLoading(true);
        let paramObj = {
            key: 'subscriber',
        };
        fetchAccLocInfo(paramObj)
            .then(res => {
                isLoading(false);
                if (res?.data[0]?.status) {
                    isLoading(false);
                    setAccountUuid(res?.data[0]?.accUuid);
                    setLocationUuid(res?.data[0]?.locUuid);
                    setSubscriberUuid(res?.data[0]?.subUuid);
                    setAccountInfo(res?.data[0]?.accInfo);
                    setLocationInfo(res?.data[0]?.locInfo);
                    setSubscriberInfo(res?.data[0]?.subInfo);
                    dispatch(setParentLogo(res?.data[0]?.accInfo[0]?.logo));
                } else {
                    isLoading(false);
                    setAccountInfo([]);
                    setLocationInfo([]);
                    dispatch(setParentLogo(''));
                }
            })
            .catch(err => {
                isLoading(false);
                setAccountInfo([]);
                setLocationInfo([]);
                dispatch(setParentLogo(''));
            });
    };
    
    const collectSubscriberInfo = () => {
		isLoading(true);
		let paramObj = {
			key: "subscriber",
		};
		fetchSubPersonalInfo(paramObj)
			.then(res => {
				isLoading(false);
				if (res?.data[0]?.status) {
					isLoading(false);
					setSubAppDetails(res?.data[0]?.subInfo);
				} else {
					isLoading(false);
					setSubAppDetails([]);
				}
			})
			.catch(err => {
				isLoading(false);
				setSubAppDetails([]);
			});
	};

    const renderAccountInfo = accountInfo !== null && accountInfo?.length > 0 ? (
        accountInfo?.map((accountData, index) => (
            <Flex key={index} w={'100%'} rounded={'md'} h={'100%'} align={'center'} p={2}>
                <Flex w={'150px'} direction={'column'}>
                    <Box minW={"100px"}>
                        <Avatar h={'80px'} w={'80px'} src={accountData?.avatar} />
                    </Box>
                </Flex>
                <Flex w={'150px'} direction={'column'}>
                    <Text w={'150px'} bg='blue.100' p={1} rounded={'md'} as="span" ml="3" color="black">{accountData?.type}</Text>
                </Flex>
                <Flex w={'100%'} direction={'column'} align={'top'}>
                    <Text ml={3} fontSize={"xs"}>
                        Account Details
                    </Text>
                    <Text as="span" ml="3">{accountData?.name}</Text>
                    <Text as="span" ml="3">{accountData?.title}</Text>
                    <Text fontSize={'sm'} as="span" ml="3">{accountData?.details}</Text>
                </Flex>
                <Flex w={'150px'} direction={'column'}>
                    <Box minW={"100px"}>
                        {accountData?.logo ? <Image src={accountData?.logo} h={100} /> : null}
                    </Box>
                </Flex>
            </Flex>
        ))
    ) : (
        <Flex w={'100%'} bg={brandBg} p={1} h={'100%'}>
            <Flex w={"100%"} p={5} bg="red.100" borderLeftWidth={"5px"} borderLeftColor={"red.400"}>
                <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"}>
                    Unable to collect Account and Location Info
                </Heading>
            </Flex>
        </Flex>
    );


    const renderLocationInfo = locationInfo !== null && locationInfo?.length > 0 ? (
        locationInfo?.map((item, index) => (
            <Box mt="1" textAlign={'center'} color="black">
                <Box minW={"100px"} maxW={'100px'} mx="auto" align='center'>
                    <Avatar w={'80px'} h={'80px'} src={item?.link_location_avatar?.length ? item?.link_location_avatar : null} />
                </Box>
            <Flex
                w={"100%"}
                key={index}
                rounded={"md"}
                align={"center"}
                p={1}
                mt={1}
            >
                <Flex
                    rounded={"md"}
                    px={1}
                    w={"100%"}
                >
                    <Flex w={'100%'} direction={'column'}>
                        <Flex minW={"100px"} p={1} direction={"column"} flexWrap={'wrap'}>
                            <Text fontWeight={'bold'}>
                                {item?.link_location_title}
                            </Text>
                            <Text w="100%">{item?.link_location_name}</Text>
                            <Text w="100%" fontSize={'sm'}>{item?.link_location_title}</Text>
                        </Flex>
                        <Flex minW={"100px"} p={1} ml="2" pl="2" direction={"column"} flexWrap={'wrap'}>
                            <Text>
                                {item?.link_location_address1}
                            </Text>
                            <Text w="100%">{item?.link_location_address2}</Text>
                            <Box w="full">
                            {item?.link_location_country && <Text fontSize={'sm'} as="span">{item?.link_location_country},</Text>}
                            {item?.link_location_state && <Text as="span"> {item?.link_location_state},</Text>}
                            {item?.link_location_city &&<Text as="span"> {item?.link_location_city}</Text>}
                            </Box>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            </Box>
        ))
    ) : (
        <Flex w={"100%"} p={5} bg="red.100" borderLeftWidth={"5px"} borderLeftColor={"red.400"} color="black">
            <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"}>
                No location info collected.
            </Heading>
        </Flex>
    );

    const renderSubscriberInfo = subscriberInfo !== null && subscriberInfo?.length > 0 ? (
        subscriberInfo?.map((item, index) => (
            <Flex
                w={"100%"}
                key={index}
                rounded={"md"}
                align={"center"}
                p={1}
                mt={1}>
                <Flex
                    align={'center'}
                    direction={'column'}
                    rounded={"md"}
                    px={1}
                    w={"100%"}
                >
                    <Avatar mx="auto" src={item?.avatar} h="87px" w="87px" border="1px solid" />
                    <Text color={'gray.700'} fontWeight="bold" fontSize="xl">
                        {item?.name}
                    </Text>
                    <Text color={'gray.600'} fontSize="md">
                        {item?.email}
                    </Text>
                    <Text color={'gray.600'} fontSize="sm">
                        {item?.phone}
                    </Text>
                </Flex>
            </Flex>
        ))
    ) : (
        <Flex w={"100%"} p={5} bg="red.100" borderLeftWidth={"5px"} borderLeftColor={"red.400"}>
            <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"}>
                No subscriber info collected.
            </Heading>
        </Flex>
    );

    // Todo:Render Feature Image By Code
	const renderFeatureImage = name => {
		if (name === "hi") {
			return HumanAIBg;
		} else if (name === "bi") {
			return BasicAIBg;
		} else if (name === "pi") {
			return PremiumAIBg;
		} else if (name === "di") {
			return DocumentAiLightBg;
		} else if (name === "mi") {
			return MultichannelBg;
		}  
        else return;
	};
    

    const renderAppData = (data) => {
        const appData = data.split(',')
        return appData?.map((featureName, index) => (
            <Box h="100px" w="49%" mb="3">
                <Image cursor={'pointer'}  key={index} mr="2" borderRadius="10px" h="100%" w="100%" src={renderFeatureImage(featureName)} />
            </Box>
        ))
    }

    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }} pl={3}>
           <Card w={"100%"} bg={brandBg} rounded={"lg"} mt={1} p={1} h={'80px'}>
                {renderAccountInfo}
            </Card>
            <Card w={'100%'} mt={1} p={1} rounded={"lg"}>
                <Flex h="80vh">
                    <Box
                        w={!configurationCollapseFlag ? "95%" : "80%"}
                        bg={brandBg}
                        p={2}
                        borderRadius={"10px"}
                        overflowY="scroll"
                    >
                        <SubDashboardTab />
                    </Box>
                    {configurationCollapseFlag ? (
                        <Box
                            w="20%"
                            position="relative"
                            p="1"
                            borderRadius="15px"
                            bg={brandBg}
                            ml="2"
                        >
                            <Box
                                position="absolute"
                                top="0"
                                right="0"
                                mr="2"
                                mt="3"
                                zIndex="999"
                            >
                                <AiFillRightCircle
                                    cursor="pointer"
                                    size="20px"
                                    color="#fff"
                                    onClick={() => {
                                        setConfiguationCollapseFlag(
                                            !configurationCollapseFlag
                                        );
                                    }}
                                />
                            </Box>
                            <Box w="100%" h="95%" overflow={'auto'}>
                                <Flex w={'100%'} direction={'column'} p={1} flexWrap={"wrap"}>
                                    <Card w={"100%"} bg={brandBg} rounded={"lg"} mt={1} p={1} borderWidth="1px"  mb='2'>
                                        {subAppDetails[0]?.apps != null
                                        ? <Box>
                                            <Text mb="2" fontWeight={'bold'}>Featured Apps</Text>
                                            <Flex justifyContent={'space-between'} flexWrap={'wrap'}> 
                                                {renderAppData(subAppDetails[0]?.apps)} 
                                            </Flex>
                                        </Box>
                                        : <Text>No apps</Text>}
                                    </Card>
                                    <Flex
                                        borderWidth={"0.05px"} borderColor={"green.300"}
                                        w={'100%'} direction={'column'} h={'200px'} rounded={'md'} bg={'gray.200'}>
                                        {/* <Text>{locationUuid ? locationUuid : 'xxx'}</Text> */}
                                        {renderLocationInfo}
                                    </Flex>
                                    <Flex
                                        borderWidth={"0.05px"} borderColor={"green.300"}
                                        w={'100%'} direction={'column'} h={'200px'} rounded={'md'} bg={'gray.200'}>
                                        {/* <Text>{subscriberUuid ? subscriberUuid : 'xxx'}</Text> */}
                                        {renderSubscriberInfo}
                                    </Flex>
                                </Flex>
                            </Box>
                        </Box>
                    ) : (
                        <Box
                            w="5%"
                            p="3"
                            borderRadius="10px"
                            bg={brandBg}
                            h="100%"
                            ml="2"
                            pl="5"
                        >
                            <Box w={"100%"} h="100%">
                                <AiFillLeftCircle
                                    cursor="pointer"
                                    onClick={() => {
                                        setConfiguationCollapseFlag(
                                            !configurationCollapseFlag
                                        );
                                    }}
                                    size="20px"
                                />
                                <Text
                                    fontSize="16px"
                                    mt="2"
                                    css={{
                                        writingMode: "vertical-lr",
                                        textOrientation: "mixed",
                                    }}
                                >
                                    Subscriber Details
                                </Text>
                            </Box>
                        </Box>
                    )}
                </Flex>
            </Card>
        </Flex>
    );
}

export default SubscriberDashboard;
