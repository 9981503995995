// Chakra React Imports
import React, { useState } from "react";
import { Text, Box, Flex, useToast, Spinner } from "@chakra-ui/react";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
// import Markdown from 'react-markdown';

// Component Imports
import TabOneSubHelper from "./TabOneSubHelper";
import { useBrandBg } from "../../../../theme/globalColorTheme";
import { getHelpFileContent } from "../../../../services/sharedServices";
import { toastFunctionToaster } from "../../../../utils/toastFunction";
import Markdown from "react-markdown";
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';



function TabOneHelper() {
	const brandBg = useBrandBg();
	const [configurationCollapseFlag, setConfiguationCollapseFlag] =
		useState(false);
	const [content, setContent] = useState(null)
	const [contentLoading, setContentLoading] = useState(false)
	const toast = useToast()

	const newTheme = {
		p: props => {
		  const { children } = props;
		  return (
			<Text mb={2} fontSize={'12px'}>
			  {children}
			</Text>
		  );
		},
	  };

	function handleGetFileContent(file_name) {
		setContentLoading(true)
		getHelpFileContent({ file_name: file_name }).then(resp => {
			if (resp.data[0]?.content) {
				const markdown = resp.data[0]?.content?.replace(/\\n/g, '\n')
				setContent(markdown)
			} else {
				toast(toastFunctionToaster("Something wen wrong", "error"))
				setContent(null)
			}
		}).catch(err => {
			setContent(null)
			toast(toastFunctionToaster("Something wen wrong", "error"))

		}).finally(() => {
			setContentLoading(false)
		})
	}

	return (
		<Flex h="70vh" overflow="hidden">
			<Box
				w={!configurationCollapseFlag ? "95%" : "70%"}
				bg={brandBg}
				p="3"
				borderRadius={"10px"}
				h="full"
				className="custom-scrollbar"
				overflowY="auto"
			>
				{
					contentLoading ? <Flex w="full" justifyContent="center">
						<Spinner
							thickness='2px'
							speed='0.65s'
							emptyColor='gray.200'
							color='gray.500'
							size='xl'
						/>
					</Flex> : <>
					{content ? <Markdown components={ChakraUIRenderer(newTheme)} skipHtml>{content}</Markdown> : 'Help Content'}
				</>
				}
			</Box>
			{configurationCollapseFlag ? (
				<Box
					w="30%"
					position="relative"
					p="3"
					borderRadius="15px"
					bg={brandBg}
					ml="2"
				>
					<Box
						position="absolute"
						top="0"
						right="0"
						mr="2"
						mt="3"
						zIndex="999"
					>
						<AiFillRightCircle
							cursor="pointer"
							size="20px"
							onClick={() => {
								setConfiguationCollapseFlag(
									!configurationCollapseFlag
								);
							}}
						/>
					</Box>
					<Box w="100%" h="95%" bg={brandBg}>
						<TabOneSubHelper handleGetFileContent={handleGetFileContent} />
					</Box>
				</Box>
			) : (
				<Box
					w="5%"
					p="3"
					borderRadius="10px"
					bg={brandBg}
					h="100%"
					ml="2"
					pl="5"
				>
					<Box w={"100%"} h="100%">
						<AiFillLeftCircle
							cursor="pointer"
							onClick={() => {
								setConfiguationCollapseFlag(
									!configurationCollapseFlag
								);
							}}
							size="20px"
						/>
						<Text
							fontSize="16px"
							mt="2"
							css={{
								writingMode: "vertical-lr",
								textOrientation: "mixed",
							}}
						>
							Expand Help Settings
						</Text>
					</Box>
				</Box>
			)}
		</Flex>
	);
}

export default TabOneHelper;
