// React Chakra Import
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import InputField from "../../../../components/fields/InputField";
import {
	Box,
	Flex,
	Text,
	Heading,
	Button,
	Select,
	Icon,
	Spacer,
	Drawer,
	Spinner,
	Tooltip,
	Slider,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	SliderMark,
	FormLabel,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";

import { transcriptionLanguages } from "../../../../utils/TranscriptionLanguage.js";
// import { translationLanguages } from "../../../../utils/TranslationLanguages";

import moment from "moment";
import { AiOutlineCheckCircle, AiOutlineMail, AiOutlineStop, AiOutlineSync } from "react-icons/ai";
// Api Call Imports
import {
	getlingoCardListing,
	addUpdateLingoCardDetails,
} from "../../../../services/businessServices";
// Component Imports
import { toastFunctionToaster } from "../../../../utils/toastFunction";
import FeedbackComponent from "../../../common/Feedback/FeedbackComponent.js";
import Multiselect from "multiselect-react-dropdown";

import Card from "../../../../components/card/Card";
import { useGrayTextColor, useGreenBg, useNoResultFoundText, useRedButton, useTextColor, useYellowButton } from "../../../../theme/globalColorTheme.js";

function CardListing() {
	const toast = useToast();
	const navigate = useNavigate();

	const btnRef = React.useRef();
	const [loading, isLoading] = useState(true);
	const { isOpen, onOpen, onClose } = useDisclosure();

	const [cardUuid, setCardUuid] = useState(null);

	const [cardData, setCardData] = useState([]);
	const [activeDays, setActiveDays] = useState(50);
	const [callTime, setCallTime] = useState(50);
	const [voiceCallValue, setVoiceCallValue] = useState(5);
	const [videoCallValue, setVideoCallValue] = useState(5);
	const [lingoCardTitle, setLingoCardTitle] = useState("");

	const [sourceLanguagesData, setSourceLanguagesData] = useState([]);
	const [targetLanguagesData, setTargetLanguagesData] = useState([]);

	const [saveCardLoader, setSaveCardLoader] = useState(false);
	const [titleErrorFlag, setTitlErrorFlag] = useState(false);

	const [showTooltip, setShowTooltip] = React.useState(false);

	const notFoundText = useNoResultFoundText()
	const grayText = useGrayTextColor()
	const textColor = useTextColor()
	const greenBg = useGreenBg()
	const redBg = useRedButton()
	const yellowBg = useYellowButton()

	// Todo:Style Slider Values
	const labelStyles = {
		mt: "12",
		ml: "-2.5",
		fontSize: "sm",
	};
	const labelStylesVoice = {
		mt: "1",
		ml: "-1",
		mr: "2.0",
		fontSize: "sm",
	};

	useEffect(() => {
		fetchCardListing();
	}, []);

	// Todo: Fetch Card Details
	const fetchCardListing = () => {
		isLoading(true);
		let obj = {
			lingo_card_uuid: "string",
			records_per_page: 0,
			page_id: 0,
		};
		getlingoCardListing(obj)
			.then(res => {
				isLoading(false);
				console.log(res);
				if (res?.data[0]?.total_records > 0) {
					setCardData(res?.data[0]?.records);
				}
			})
			.catch(err => {
				isLoading(false);

				console.log(err);
			});
	};

	const renderLanguageListDetails = langs => {
		let langData = langs.split(",");
		const filteredData = transcriptionLanguages.filter(item =>
			langData.includes(item.code)
		);
		if (filteredData.length > 0) {
			return filteredData?.map((item, index) => {
				return (
					<Text
						m={1}
						p={1}
						fontSize={"xs"}
						bg={"blue.100"}
						rounded={"md"}
					>
						{item?.name}
					</Text>
				);
			});
		}
	};

	const renderCardData =
		cardData !== null && cardData?.length > 0
			? cardData?.map((item, index) => (
				<Card
					key={index}
					p={2}
					w={"100%"}
					mt={2}
					rounded={"md"}
					borderWidth={"0.05px"}
					borderColor={item?.card_active ? "green.200" : "red.200"}>
					<Flex w={"100%"} direction={"column"}>
						<Flex w={"100%"} p={1} align={"center"}>
							<Flex minW="13%" mr={2}>
							<Tooltip label={item?.lingo_card_title}>
								<Text rounded={"md"} bg={item?.card_active ? "green.200" : "red.200"} p={2} w="100%" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
									{item?.dead ? (
										<Icon as={AiOutlineStop} color={"red.600"} ml={1} mr={1} mt={1} />
									) : null}
									{item?.lingo_card_title}
								</Text>
							</Tooltip>
							</Flex>
							<Flex w={"100%"} direction={"column"}>
								<Flex w={"100%"}>
									<Text>
										Start Time (UTC):{" "}
										<Text as="spam" fontWeight={"600"}>
											{moment(item?.card_start_time).format("MMM DD,YYYY,HH:mm a")}
										</Text>
									</Text>
									<Text ml={2}>
										End Time (UTC): {moment(item?.card_end_time).format("MMM DD,YYYY,HH:mm a")}
									</Text>
								</Flex>
								<Flex w={"100%"}>
									<Text>
										Call Time: <b>{item?.card_minutes} Minutes</b>

									</Text>
								</Flex>
								<Flex w={"100%"} align={"center"}>
									<Flex alignItems="flex-start" w="22%">
									<Text>Source Language: </Text>
									</Flex>
									<Flex w="80%" flexWrap="wrap" gap={1}>
									{renderLanguageListDetails(
										item?.card_source_langs
									)}
									</Flex>
								</Flex>
								<Flex w={"100%"} align={"center"} mt={2} borderTop="1px" borderColor="gray.200">
									<Flex alignItems="flex-start" w="22%">
									<Text>Target Language: </Text>
									</Flex>
									<Flex w="80%" flexWrap="wrap" gap={1}>
									{renderLanguageListDetails(
										item?.card_target_langs
									)}
									</Flex>
								</Flex>
							</Flex>
							<Flex maxW={"300px"} ml={1}>
								<Flex w={"100%"}>
									<Button
										ml={1}
										bg={"blue.100"}
										onClick={() => {
											navigate(
												"/business/cardlisting/cardmanager",
												{
													state: {
														cardUuid:
															item?.lingo_card_uuid,
													},
												}
											);
										}}
										size={"sm"}
									>
										Manage Card
									</Button>
									<Button
										ml={1}
										bg={"blue.100"}
										onClick={() => {
											setCardUuid(
												item?.lingo_card_uuid
											);
											updateRowDetails(item);
										}}
										size={"sm"}
									>
										Edit
									</Button>
								</Flex>
							</Flex>
						</Flex>
					</Flex>
				</Card>
			))
			: null;

	const getLanguageList = selectedArr => {
		console.log(selectedArr);
		let langs = [];
		if (selectedArr.length > 0) {
			selectedArr.map((item, key) => {
				langs.push(item["code"]);
			});
		}
		if (langs.length > 0) {
			return langs.join();
		}
		return "";
	};

	// Todo:Add Card Details
	const createNewCardDetails = () => {
		if (
			lingoCardTitle?.length > 0 &&
			sourceLanguagesData?.length > 0 &&
			targetLanguagesData?.length > 0
		) {
			setTitlErrorFlag(false);
			setSaveCardLoader(true);
			// const date = new Date();
			// const milliseconds = date.getMilliseconds();
			let obj = {
				lingo_card_uuid: cardUuid ? cardUuid : null,
				lingo_card_title: lingoCardTitle,
				start_time: moment().format("YYYY-MM-DD HH:mm:ss"),
				active_days: activeDays,
				videoCount: videoCallValue,
				voiceCount: voiceCallValue,
				source_language: getLanguageList(sourceLanguagesData), // JSON.stringify(sourceLanguagesData),
				target_language: getLanguageList(targetLanguagesData), //JSON.stringify(targetLanguagesData),
				callTime: callTime,
			};
			addUpdateLingoCardDetails(obj)
				.then(res => {
					setSaveCardLoader(false);
					if (res?.result) {
						resetDetails();
						fetchCardListing();
						toast(toastFunctionToaster(res.message, "success"));
					}
				})
				.catch(err => {
					setSaveCardLoader(false);
				});
		} else {
			setTitlErrorFlag(true);
		}
	};

	// Todo:Update Card Details
	const updateRowDetails = data => {
		onOpen();
		setActiveDays(data?.active_days);
		setCallTime(data?.card_minutes);
		// setLingoCardId(data?.lingo_card_uuid);
		setVoiceCallValue(data?.card_voice_calls);
		setVideoCallValue(data?.card_video_calls);
		setLingoCardTitle(data?.lingo_card_title);
		setSourceLanguagesData(
			returnSelectedData(
				data?.card_source_langs?.length ? data?.card_source_langs : []
			)
		);
		setTargetLanguagesData(
			returnSelectedData(
				data?.card_target_langs?.length ? data?.card_target_langs : []
			)
		);
	};

	// Todo:Return Selected Data
	const returnSelectedData = data => {
		const dataArr = data?.split(",");
		return transcriptionLanguages.filter(item =>
			dataArr.includes(item.code)
		);
	};

	// Todo:Reset details on drawer closed
	const closeDrawer = () => {
		onClose();
		resetDetails(); //Todo:Reset all details
	};

	const resetDetails = () => {
		onClose();
		setCardUuid(null);
		setActiveDays(30);
		setCallTime(30);
		setVoiceCallValue(5);
		setVideoCallValue(5);
		setLingoCardTitle(null);
		setSourceLanguagesData([]);
		setTargetLanguagesData([]);
		setSaveCardLoader(false);
	};

	// Todo:Fetch Feedback from component
	const handleFeedBack = feedbackValue => {
		console.log("SelectedFeedback", feedbackValue);
	};

	const handleSourceSelect = selectedList => {
		if (selectedList?.length) {
			selectedList.length > 10 && selectedList.length-- 
			let languagesArr = [];
			selectedList?.map(data => {
				languagesArr?.push(data?.code);
			});
			setSourceLanguagesData(selectedList);
		}
	};

	const handleTargetSelect = selectedList => {
		if (selectedList.length) {
			selectedList.length > 10 && selectedList.length-- 
			let languagesArr = [];
			selectedList?.map(data => {
				languagesArr?.push(data?.code);
			});
			setTargetLanguagesData(selectedList);
		}
	};

	return (
		<Box direction="column" pt={{ sm: "125px", lg: "80px" }}>
			<Flex p="3" alignItems="center" borderBottomWidth="1px" justifyContent={"space-between"}>
				<Text fontWeight={"500"} fontSize={"18px"}>
					LingoCard {cardData?.length > 0 ? cardData?.length : 0}
				</Text>
				<Spacer />
				<Flex w={"220px"} align={"center"}>
					<Box
						p={1}
						align={"center"}
						w={"100px"}
						_hover={{ cursor: "pointer" }}
						roundedLeft={"lg"}
						bg={greenBg}
						size="sm"
						onClick={() => { }}>
						{"active" ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
						Active
					</Box>
					<Box
						p={1}
						bg={redBg}
						align={"center"}
						w={"100px"}
						size="sm"
						_hover={{ cursor: "pointer" }}
						roundedRight={"lg"}
						onClick={() => {
							fetchCardListing();
						}}>
						{"closed" ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
						Closed
					</Box>
				</Flex>
				<Button
					bg={yellowBg}
					size="sm"
					rounded={"lg"}
					onClick={() => {
						fetchCardListing("active");
					}}
					color={textColor}
					_hover={{ color: 'black' }}
				>
					<Icon as={AiOutlineSync} />
				</Button>
				<Button
					ml={1}
					colorScheme="blue"
					rounded={"lg"}
					size="sm"
					onClick={() => {
						onOpen();
					}}>
					{cardUuid ? "Update" : "Create New"} Card
				</Button>
			</Flex>
			{loading ? (
				<Flex alignItems="center" justifyContent="center" h="50vh">
					<Spinner size="xl" />
				</Flex>
			) : cardData?.length ? (
				<Flex w={"100%"} direction={"column"}>
					{renderCardData}
				</Flex>
			) : (
				<Flex w={"100%"} p={5} bg="red.100" borderLeftWidth={"5px"} borderLeftColor={"red.400"}>
					<Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color={notFoundText}>
						There are no LingoCards!!
					</Heading>
				</Flex>
			)}
			{/* Todo:Test FeedBack Component */}
			<Box mt="5">
				<FeedbackComponent
					onEmojiSelect={handleFeedBack}
					title={'LingoCard Listing'}
					type="lingo_products"
					subtype="lingo_card_listing" />
			</Box>
			{/* Drawer */}
			<Drawer
				size="md"
				isOpen={isOpen}
				// isOpen={() => {
				//     isOpen();
				//     saveCardLoader(false);
				// }}
				placement="right"
				onClose={() => {
					closeDrawer();
				}}
				finalFocusRef={btnRef}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader borderBottomWidth={"1px"}>{cardUuid ? "Udpate" : "Create New"} Card</DrawerHeader>
					<DrawerBody overflow="hidden" overflowY={"scroll"} mt="3">
						<Flex w={"100%"} direction={"column"}>
							<InputField
								mb="0"
								me="30px"
								id="lingoCardTitle"
								label="LingoCard Title"
								value={lingoCardTitle}
								name="lingoCardTitle"
								required="true"
								borderRadius="5px"
								onChange={e => {
									setLingoCardTitle(e.target.value);
								}}
								placeholder="Enter LingoCard Title"
								w="100%"
								maxlength={100}
							/>
							<Flex alignItems={"center"} justifyContent={"space-between"}>
								{titleErrorFlag && lingoCardTitle?.length <= 0 ? (
									<Text color="red.500" fontSize={"sm"}>
										Card Title is required
									</Text>
								) : null}
								<Text textAlign={"right"} fontWeight={"500"} fontSize={"sm"} mt="2">
									{lingoCardTitle?.length}/100
								</Text>
							</Flex>
							{/* Todo:Active Daye */}
							<FormLabel mt="3" ml="2" required>
								<Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
									Active Days
								</Text>
								<Flex
									mt={"10px"}
									h={"100px"}
									w={"100%"}
									rounded={"md"}
									borderWidth={"0.5px"}
									borderColor={"gray.200"}>
									<Slider
										aria-label="slider-ex-1"
										colorScheme="teal"
										min={0}
										max={365}
										step={1}
										defaultValue={activeDays}
										onChange={v => setActiveDays(v)}
										onMouseEnter={() => setShowTooltip(true)}
										onMouseLeave={() => setShowTooltip(false)}>
										<SliderMark value={50} {...labelStyles}>
											50
										</SliderMark>
										<SliderMark value={100} {...labelStyles}>
											100
										</SliderMark>
										<SliderMark value={150} {...labelStyles}>
											150
										</SliderMark>
										<SliderMark value={200} {...labelStyles}>
											200
										</SliderMark>
										<SliderMark value={250} {...labelStyles}>
											250
										</SliderMark>
										<SliderMark value={300} {...labelStyles}>
											300
										</SliderMark>
										<SliderMark value={365} {...labelStyles}>
											365
										</SliderMark>

										<SliderTrack>
											<SliderFilledTrack />
										</SliderTrack>
										<Tooltip
											hasArrow
											bg="teal.500"
											color="white"
											placement="top"
											isOpen={showTooltip}
											label={`${activeDays} Days`}>
											<SliderThumb />
										</Tooltip>
									</Slider>
								</Flex>
							</FormLabel>
							{/* Todo:Video & Voice Call */}
							<Flex w={"100%"} direction={"column"} mt="3">
								<Flex w={"100%"} align={"center"}>
									<Flex
										w={"50%"}
										h={"100px"}
										direction={"column"}
										py={1}
										px={5}
										rounded={"md"}
										borderWidth={"0.5px"}
										borderColor={"gray.200"}>
										<Text>
											Voice Call Count: <b>{voiceCallValue}</b>
										</Text>
										<Slider
											mt={8}
											aria-label="slider-ex-1"
											colorScheme="teal"
											min={1}
											max={50}
											step={1}
											defaultValue={voiceCallValue}
											onChange={v => setVoiceCallValue(v)}
											onMouseEnter={() => setShowTooltip(true)}
											onMouseLeave={() => setShowTooltip(false)}>
											<SliderMark value={1} {...labelStylesVoice}>
												1
											</SliderMark>
											<SliderMark value={25} {...labelStylesVoice}>
												25
											</SliderMark>
											<SliderMark value={50} {...labelStylesVoice}>
												50
											</SliderMark>
											<SliderTrack>
												<SliderFilledTrack />
											</SliderTrack>
											<Tooltip
												hasArrow
												bg="teal.500"
												color="white"
												placement="top"
												isOpen={showTooltip}
												label={`${voiceCallValue}`}>
												<SliderThumb />
											</Tooltip>
										</Slider>
									</Flex>
									<Flex
										ml={1}
										w={"50%"}
										h={"100px"}
										direction={"column"}
										py={1}
										px={5}
										rounded={"md"}
										borderWidth={"0.5px"}
										borderColor={"gray.200"}>
										<Text>
											Video Call Count: <b>{videoCallValue}</b>
										</Text>
										<Slider
											mt={8}
											aria-label="slider-ex-1"
											colorScheme="teal"
											min={1}
											max={50}
											step={1}
											defaultValue={videoCallValue}
											onChange={v => setVideoCallValue(v)}
											onMouseEnter={() => setShowTooltip(true)}
											onMouseLeave={() => setShowTooltip(false)}>
											<SliderMark value={1} {...labelStylesVoice}>
												1
											</SliderMark>
											<SliderMark value={25} {...labelStylesVoice}>
												25
											</SliderMark>
											<SliderMark value={50} {...labelStylesVoice}>
												50
											</SliderMark>
											<SliderTrack>
												<SliderFilledTrack />
											</SliderTrack>
											<Tooltip
												hasArrow
												bg="teal.500"
												color="white"
												placement="top"
												isOpen={showTooltip}
												label={`${videoCallValue}`}>
												<SliderThumb />
											</Tooltip>
										</Slider>
									</Flex>
								</Flex>
							</Flex>
							{/* Todo:Source Language */}
							<Box mt="3" ml="2">
								<Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
									Select Source Language(s) (max 10/None means all)
								</Text>
								<Multiselect
									w={"100%"}
									options={transcriptionLanguages}
									selectedValues={sourceLanguagesData}
									onSelect={selectedList =>{ 
												handleSourceSelect(selectedList)}}
									onRemove={selectedList => handleSourceSelect(selectedList)}
									displayValue="name"
									placeholder="Select supported source language(s)"
								/>
								{/* <Select
									value={sourceLanguage}
									name="source Language"
									size="sm"
									borderRadius="3px"
									borderColor="gray.200"
									fontSize={"14px"}
									mr="10px"
									onChange={(e) => {
										console.log(e.target.value);
										setSourceLanguage(e.target.value);
									}}
									>
									{renderLanguageData}
								</Select> */}
							</Box>
							{/* Todo:Taget Language */}
							<Box mt="3" ml="2">
								<Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
									Select Target Language(s) (max 10/None means all)
								</Text>
								<Multiselect
									w={"100%"}
									options={transcriptionLanguages}
									selectedValues={targetLanguagesData}
									onSelect={selectedList =>{ 
												handleTargetSelect(selectedList)}}
									onRemove={selectedList => handleTargetSelect(selectedList)}
									displayValue="name"
									placeholder="Select supported target language(s)"
								/>
							</Box>
							{/* Todo:Call Time */}
							<FormLabel mt="3" ml="2" required>
								<Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
									Call Time
								</Text>
								<Flex
									mt={"10px"}
									h={"100px"}
									w={"100%"}
									rounded={"md"}
									borderWidth={"0.5px"}
									borderColor={"gray.200"}>
									<Slider
										aria-label="slider-ex-1"
										colorScheme="teal"
										min={0}
										max={600}
										step={1}
										defaultValue={callTime}
										onChange={v => {
											setCallTime(v);
										}}
										onMouseEnter={() => setShowTooltip(true)}
										onMouseLeave={() => setShowTooltip(false)}>
										<SliderMark value={100} {...labelStyles}>
											100
										</SliderMark>
										<SliderMark value={200} {...labelStyles}>
											200
										</SliderMark>
										<SliderMark value={300} {...labelStyles}>
											300
										</SliderMark>
										<SliderMark value={400} {...labelStyles}>
											400
										</SliderMark>
										<SliderMark value={500} {...labelStyles}>
											500
										</SliderMark>
										<SliderMark value={600} {...labelStyles}>
											600
										</SliderMark>

										<SliderTrack>
											<SliderFilledTrack />
										</SliderTrack>
										<Tooltip
											hasArrow
											bg="teal.500"
											color="white"
											placement="top"
											isOpen={showTooltip}
											label={`${callTime} Minutes`}>
											<SliderThumb />
										</Tooltip>
									</Slider>
								</Flex>
							</FormLabel>
						</Flex>
					</DrawerBody>
					<DrawerFooter>
						<Flex w={"100%"} direction={"row"}>
							<Button
								variant="outline"
								mr={3}
								onClick={() => {
									closeDrawer();
									// resetProjectDetails();
								}}>
								Cancel
							</Button>
							{lingoCardTitle?.length > 0 && sourceLanguagesData?.length > 0 && targetLanguagesData?.length > 0
								? <Button
									colorScheme="blue"
									onClick={() => {
										createNewCardDetails();
									}}
									isDisabled={saveCardLoader ? true : false}
									isLoading={saveCardLoader ? true : false}>
									{cardUuid ? "Update" : "Create New"} Card
								</Button>
								: <Button
									colorScheme="blue"
									isDisabled={true}
									isLoading={saveCardLoader ? true : false}>
									{cardUuid ? "Update" : "Create New"} Card
								</Button>}
						</Flex>
					</DrawerFooter>
				</DrawerContent>
			</Drawer>
		</Box>
	);
}

export default CardListing;
