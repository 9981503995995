import React, { useEffect, useRef, useState } from 'react'
import {
    Box,
    Image,
    Button,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Avatar, Text, Flex, Heading, useToast,
    Alert, AlertIcon, AlertTitle, AlertDescription,
    Switch,
    useDisclosure,
} from "@chakra-ui/react";
import InputField from "../../../../components/fields/InputField";


// Component Import
import Card from "../../../../components/card/Card";
import AccountUpload from './AccountUpload';
import { useBrandBg, useBlackWhiteBg, useNoResultFoundText } from '../../../../theme/globalColorTheme';

// Component
import HumanAIBg from "../../../../assets/communicator/HumanIcon.png";
import BasicAIBg from "../../../../assets/communicator/SecureAI.png";
import PremiumAIBg from "../../../../assets/communicator/Premium_AIIcon.png";
import DocumentAiLightBg from "../../../../assets/communicator/DocumentAILight.png";
import MultichannelBg from "../../../../assets/communicator/multichannelai.png";

import { toastFunctionToaster } from '../../../../utils/toastFunction';

// Api
import { saveAccountAppInfo, fetchAccountQuota, setNewAccountQuota, manageAccountDocumnetAiSupport, manageAccountDeviceSupport, updateAccountType } from '../../../../services/businessServices';
import { ACCOUNT_DETAILS_TABS, ACCOUNT_ACCESS_CLASS } from './constants';

function AccountDetails(props) {
    const toast = useToast()
    const fetchAccoutInfo = props?.refetchInfo;
    const accountUuid = props.accountDetails && props.accountDetails.length > 0 ? props.accountDetails[0]?.link_account_uuid : null;
    const [loading, isLoading] = useState(true);
    const [error, setError] = useState({});

    const brandBg = useBrandBg();
    const blackWhiteBg = useBlackWhiteBg();
    const sourceFeatures = ['hi', 'bi', 'pi', 'di', 'mi'];

    const [updateLoader, setUploadLoader] = useState(false)
    const noLingiLinksFound = useNoResultFoundText()
    const [selectedFeature, setSelectedFeature] = useState([]);
    const accountInfo = props.accountDetails ? props.accountDetails : null;
    const { isOpen:isAlertOpen, onOpen:onAlertOpen, onClose:onAlertClose } = useDisclosure()
    const AlertCancelRef = useRef()

    const [activeTab, setActiveTab] = useState(0);
    const [accountQuota, setAccountQuota] = useState([]);

    const [hiValue, setHiValue] = useState(0);
    const [biValue, setBiValue] = useState(0);
    const [piValue, setPiValue] = useState(0);
    const [btnDisabled, setBtnDisabled] = useState({})

    useEffect(() => {
        setSelectedFeature(props?.accountDetails[0]?.link_apps ? props?.accountDetails[0]?.link_apps.split(',') : [])
    }, [props?.accountDetails])

    useEffect(() => {
        collectAccountQuota();
    }, [accountUuid]);

    const collectAccountQuota = () => {
        if (accountUuid != null) {
            isLoading(true);
            let paramObj = {
                account_uuid: accountUuid,
            };
            fetchAccountQuota(paramObj)
                .then(res => {
                    isLoading(false);
                    if (res?.data[0]?.status) {
                        isLoading(false);
                        setAccountQuota(res?.data[0]?.quota);
                    } else {
                        isLoading(false);
                        setAccountQuota([]);
                    }
                })
                .catch(err => {
                    isLoading(false);
                    setAccountQuota([]);
                });
        }
    };

    const AccountQuotaCreation = (quota_id) => {
        if (accountUuid != null) {
            isLoading(true);
            let paramObj = {
                account_uuid: accountUuid,
                quota_id: quota_id,
                quota_value: 3000,
                quota_unit: 'minutes',
                quota_duration: 'month'
            };
            if (quota_id == 'hi') {
                paramObj.quota_value = hiValue;
            } else if (quota_id == 'bi') {
                paramObj.quota_value = biValue;
            } else if (quota_id == 'pi') {
                paramObj.quota_value = piValue;
            }
            setNewAccountQuota(paramObj)
                .then(res => {
                    isLoading(false);
                    if (res?.data[0]?.status) {
                        collectAccountQuota();
                    }
                })
                .catch(err => {
                    //isLoading(false);
                });
        }
    };

    const renderSelectedAccountDetails =
        accountInfo !== null && accountInfo?.length > 0 ? (
            accountInfo?.map((accountData, index) => (
                <Box key={index + accountData?.link_account_uuid} bgImage={accountData?.link_account_background ? accountData?.link_account_background : null} bgSize="cover" bgRepeat="no-repeat" w={"100%"} rounded={"md"} bgPosition="center" borderWidth={"0.05px"} minH={'150px'} position="relative">
                    <Flex alignItems="center" w="full" minH={'150px'} ml="3">
                        <Box minW={"100px"} position="absolute" top="58%">
                            <Avatar h={100} w={100} src={accountData?.link_account_logo} showBorder={true} dropShadow="md" />
                        </Box>
                    </Flex>
                </Box>
            ))
        ) : (
            <Flex w={"100%"} p={5} bg="red.100" borderLeftWidth={"5px"} borderLeftColor={"red.400"}>
                <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"}>
                    Unable to get selected account info...
                </Heading>
            </Flex>
        );

    const removeFeature = (value) => {
        setSelectedFeature((prevItems) => {
            const updatedItems = [...prevItems];
            const index = updatedItems.indexOf(value);
            if (index > -1) {
                updatedItems.splice(index, 1);
            } else {
                updatedItems.push(value);
            }
            return updatedItems;
        });
    };

    const addFeature = (value) => {
        setSelectedFeature((prevItems) => {
            const updatedItems = [...prevItems];
            const index = updatedItems.indexOf(value);
            if (index > -1) {
                updatedItems.splice(index, 1);
            } else {
                updatedItems.push(value);
            }
            return updatedItems;
        });
    };

    const renderLinkFeature = () => {
        return selectedFeature?.map((featureName, index) => (
            <Image onClick={() => { removeFeature(featureName) }} cursor={'pointer'} key={index} mr="2" borderRadius="10px" h="130px" w="130px" src={renderFeatureImage(featureName)} />
        ))
    }

    // Todo:Render Feature Image By Code
    const renderFeatureImage = name => {
        if (name === "hi") {
            return HumanAIBg;
        } else if (name === "bi") {
            return BasicAIBg;
        } else if (name === "pi") {
            return PremiumAIBg;
        } else if (name === "di") {
            return DocumentAiLightBg;
        } else if (name === "mi") {
            return MultichannelBg;
        }
        else return;
    };

    const renderAppsSelection = (accountObj) => {
        return (
            <Flex w={'100%'} direction={'column'} mt="4" h="100%" overflowY="auto" className='custom-scrollbar'>
                <Card w={'100%'} direction={'column'} rounded={'lg'} p={2}>
                    <Flex w="full" justifyContent="space-between" mb="2">
                        <Text fontWeight='600'>Available LingoLink Apps</Text>
                        <Box>
                            <Button size={'sm'} bg={'blue.100'} color="gray.600" onClick={() => { updateAccountApp() }} isDisabled={updateLoader ? true : false} isLoading={updateLoader ? true : false}>
                                Update Account Apps
                            </Button>
                        </Box>
                    </Flex>
                    {selectedFeature?.length > 0
                        ? <Flex w={'100%'} justifyContent={'space-between'}>
                            <Flex flexWrap="wrap" gap="2" justifyContent="flex-start">{renderLinkFeature()}</Flex>
                        </Flex>
                        : <Flex w={'100%'} mt={2}>
                            <Text p={2} bg={'red.100'} color={noLingiLinksFound}>You do not have any LingoLink applications set for this account.</Text>
                        </Flex>}
                </Card>
                <Card w={'100%'} direction={'column'} mt={1} rounded={'lg'} p={2}>
                    <Text>Available LingoLink Apps</Text>
                    <Text fontSize={'xs'}>Please select an application to assign with this accounts.</Text>
                    {sourceFeatures?.length > 0
                        ? <Flex w={'100%'} mt={2}>
                            <Flex w={'100%'} gap={2}>
                                <Box w="20%" display={selectedFeature.includes('hi') ? 'none' : 'block'} cursor="pointer" onClick={() => addFeature('hi')}>
                                    <Image h="150px" w="100%" src={HumanAIBg} />
                                </Box>
                                <Box w="20%" display={selectedFeature.includes('bi') ? 'none' : 'block'} cursor="pointer" onClick={() => addFeature('bi')}>
                                    <Image h="150px" w="100%" src={BasicAIBg} />
                                </Box>
                                <Box w="20%" display={selectedFeature.includes('pi') ? 'none' : 'block'} cursor="pointer" onClick={() => addFeature('pi')}>
                                    <Image h="150px" w="100%" src={PremiumAIBg} />
                                </Box>
                                <Box w="20%" display={selectedFeature.includes('di') ? 'none' : 'block'} cursor="pointer" onClick={() => addFeature('di')}>
                                    <Image h="150px" w="100%" src={DocumentAiLightBg} />
                                </Box>
                                <Box w="20%" display={selectedFeature.includes('mi') ? 'none' : 'block'} cursor="pointer" onClick={() => addFeature('mi')}>
                                    <Image h="150px" w="100%" src={MultichannelBg} />
                                </Box>
                            </Flex>
                        </Flex> : null}
                </Card>
            </Flex>
        )
    }

    const displaceQuotaValue = (quotaType) => {
        if (accountQuota.length > 0) {
            for (let i = 0; i < accountQuota.length; i++) {
                if (accountQuota[i]?.quota_id == quotaType) {
                    return accountQuota[i]?.quota_value;
                }
            }
        }
        return 0;
    }

    // Todo:Update Selected App For Account
    const updateAccountApp = () => {
        setUploadLoader(true)
        let obj = {
            "link_account_uuid": accountInfo[0]?.link_account_uuid,
            "link_apps": selectedFeature?.length ? selectedFeature.join(',') : []
        }
        saveAccountAppInfo(obj).then((res) => {
            setUploadLoader(false)
            if (res?.result) {
                toast(toastFunctionToaster(res.message, "success"));
                props?.renderFeatureApp('render')
            } else {
                toast(toastFunctionToaster(res.message, "error"));
            }
        }).catch((err) => {
            if (err) {
                setUploadLoader(false)
                toast(toastFunctionToaster('Something went wrong!!', "error"));
            }
        })
    }

    // Todo:Tab Change
    const handleTabChange = index => {
        setActiveTab(index);
    };

    function mamageDocumentAiSupport() {
        const paramObj = {
            account_uuid: accountInfo[0]?.link_account_uuid
        }
        setBtnDisabled(item => ({ ...item, documentAi: true }))
        manageAccountDocumnetAiSupport(paramObj).then(resp => {
            if (resp?.data[0]?.status) {
                fetchAccoutInfo()
                toast(toastFunctionToaster("Successfully updated documnet ai support for account", "success"))
            } else {
                toast(toastFunctionToaster("Failed to update documnet ai support for account", "error"))
            }
        }).catch(err => {
            toast(toastFunctionToaster("Failed to update documnet ai support for account", "error"))

        }).finally(() => {
            setBtnDisabled(item => ({ ...item, documentAi: false }))
        })
    }

    function manageDeviceSupport() {
        const paramObj = {
            account_uuid: accountInfo[0]?.link_account_uuid
        }
        setBtnDisabled(item => ({ ...item, deviceSupport: true }))
        manageAccountDeviceSupport(paramObj).then(resp => {
            if (resp?.data[0]?.status) {
                fetchAccoutInfo()
                toast(toastFunctionToaster("Successfully updated device support for account", "success"))
            } else {
                toast(toastFunctionToaster("Failed to update device support for account", "error"))
            }
        }).catch(err => {
            toast(toastFunctionToaster("Failed to update device support for account", "error"))
        }).finally(() => {
            setBtnDisabled(item => ({ ...item, deviceSupport: false }))
        })
    }

    function updateAccType() {
        const paramObj = {
            account_uuid: accountInfo[0]?.link_account_uuid
        }
        setBtnDisabled(item => ({ ...item, accountType: true }))
        updateAccountType(paramObj).then(resp => {
            if (resp?.data[0]?.status) {
                fetchAccoutInfo()
                toast(toastFunctionToaster("Successfully updated device support for account", "success"))
            } else {
                toast(toastFunctionToaster("Failed to update device support for account", "error"))
            }
        }).catch(err => {
            toast(toastFunctionToaster("Failed to update device support for account", "error"))
        }).finally(() => {
            setBtnDisabled(item => ({ ...item, accountType: false }))
            onAlertClose()
        })
    }

    return (
        <Card w={"100%"} rounded={"md"} p={1}>
            <Flex h="75vh">
                <Box
                    w={"98%"}
                    bg={brandBg}
                    p="1"
                    h="100%"
                    borderRadius={"10px"}>
                    {accountInfo != null && accountInfo?.length > 0
                        ? <Flex w={'100%'} direction={'column'} h="100%">
                            <Card w={'100%'} rounded={'lg'} p={2} mt="2" minH="99%">
                                <Tabs variant='unstyled' bg={brandBg} orientation="vertical" h="100%" w="100%" borderBottom={"0px"} mx="auto" onChange={handleTabChange} index={activeTab}>
                                    <TabList p='10px' pb='0px' h={"100%"} >
                                        <Flex h="100%" overflow='hidden'>
                                            <Flex h="100%" flexDir="column" overflow="hidden auto" pb={3} className='custom-scrollbar'>
                                                {
                                                    ACCOUNT_DETAILS_TABS.map(tabDetails => (
                                                        <Tab
                                                            key={tabDetails.name}
                                                            textAlign="left"
                                                            justifyContent="flex-start"
                                                            mt={1} rounded={'lg'} w='200px' bg={blackWhiteBg} _selected={{ color: '#fff', bg: 'blue.500', borderRadius: '5px' }} mr="1">
                                                            {tabDetails.icon}
                                                            {tabDetails.name}
                                                        </Tab>
                                                    ))
                                                }
                                            </Flex>
                                        </Flex>

                                    </TabList>
                                    <TabPanels pt="10px" minH="100%" overflowY="hidden" h="100%">
                                        <TabPanel p={0} h="100%">
                                            <Card w={'99%'} rounded={'lg'} p={1} mt={1} h="98%" overflowY="hidden">
                                                <Flex w={'100%'} mt="4" h="100%" overflowY="auto" direction="column">
                                                    <Flex justifyContent="space-between" alignItems="center">
                                                        <Box minW={"100px"}>
                                                            <Avatar h={100} w={100} src={accountInfo[0]?.link_account_avatar} />
                                                        </Box>
                                                    </Flex>
                                                    <Flex w={'100%'} direction={"column"}>
                                                        <Text mt={5} fontWeight={600} fontSize={"lg"}>
                                                            Account Details
                                                        </Text>
                                                        <Flex w="100%">
                                                            <Text w="25%">Account Name: </Text>
                                                            <Flex justifyContent="flex-start" fontWeight={'600'} ml="3" w="70%">{accountInfo[0]?.link_account_name}</Flex>
                                                        </Flex>
                                                        <Flex>
                                                            <Text w="25%">Account Title:</Text>
                                                            <Flex justifyContent="flex-start" fontWeight={'600'} ml="3">{accountInfo[0]?.link_account_title}</Flex>
                                                        </Flex>
                                                        <Flex>
                                                            <Text w="25%">
                                                                Account Type:
                                                            </Text>
                                                            <Flex justifyContent="flex-start" fontWeight={'600'} ml="3" p={1} bg={'green.200'}>
                                                                {accountInfo[0]?.link_account_type}
                                                            </Flex>
                                                        </Flex>
                                                        <Flex>
                                                            <Text w="25%">
                                                                Account Details:
                                                            </Text>
                                                            <Flex justifyContent="flex-start" fontWeight={'600'} ml="3">{accountInfo[0]?.link_account_details}</Flex>
                                                        </Flex>
                                                        <Flex rounded={'lg'} direction={'column'} w={'100%'} p={2} borderWidth={'0.5px'} borderColor={'gray.200'}>
                                                            <Flex>
                                                                <Text w="25%">
                                                                    Account Access Level:
                                                                </Text>
                                                                <Flex justifyContent="flex-start" fontWeight={'600'} ml="3" textTransform="capitalize">{accountInfo[0]?.account_access_class}</Flex>
                                                            </Flex>
                                                            <Flex mt="3">
                                                                <Flex justifyContent="flex-start" fontWeight={'600'} ml="3" textTransform="capitalize">
                                                                    <Button size="sm" w="360px" colorScheme='blue' p='2' justifySelf="flex-end" onClick={onAlertOpen} isLoading={btnDisabled.accountType}>
                                                                        Change  Account Access Level to {accountInfo[0]?.account_access_class === ACCOUNT_ACCESS_CLASS.ADVANCE ? ACCOUNT_ACCESS_CLASS.BASIC : ACCOUNT_ACCESS_CLASS.ADVANCE}
                                                                    </Button>
                                                                </Flex>
                                                            </Flex>
                                                        </Flex>
                                                    </Flex>

                                                </Flex>
                                            </Card>
                                        </TabPanel>
                                        <TabPanel p={0} h="100%">
                                            <Card w={'99%'} rounded={'lg'} p={1} mt={1} h="98%" overflowY="hidden">
                                                {renderAppsSelection(accountInfo)}
                                            </Card>
                                        </TabPanel>
                                        <TabPanel p={0} h="100%">
                                            <Flex w={'100%'} h="98%" overflowY="hidden" >
                                                <Flex flexWrap="wrap" w="100%" h="100%" overflowY="auto" className='custom-scrollbar'>
                                                    <Card w={'48%'} rounded={'lg'} m={1}>
                                                        <Text>Human Interpretation</Text>
                                                        <Text>{displaceQuotaValue('hi') + ' Minute Monthly'}</Text>
                                                        <Flex w="100%" p={2} rounded={'lg'} borderWidth={'0.5px'} borderColor={'gray.300'} flexDir="column">
                                                            <InputField
                                                                mb="0"
                                                                w={'150px'}
                                                                me="3px"
                                                                id="quota_value"
                                                                label="Quota Value"
                                                                name="quota_value"
                                                                value={hiValue}
                                                                onChange={e => {
                                                                    setHiValue(e.target.value);
                                                                }}
                                                                placeholder="Enter Quota Value"
                                                                required="true"
                                                                borderRadius={'10px'}
                                                            />
                                                            {error?.quota_value ? <Text fontSize="sm" color={"red"} mb="5" mt="1">
                                                                {error?.quota_value}
                                                            </Text> : null}
                                                            <Button px={2}
                                                                mt={2}
                                                                rounded={'lg'}
                                                                bg={'blue.100'}
                                                                w="130px"
                                                                whiteSpace="normal"
                                                                wordBreak="break-word"
                                                                onClick={() => AccountQuotaCreation('hi')}>
                                                                Update Quota
                                                            </Button>
                                                        </Flex>
                                                    </Card>
                                                    <Card w={'48%'} rounded={'lg'} m={1}>
                                                        <Text>Secure AI</Text>
                                                        <Text>{displaceQuotaValue('bi') + ' Minute Monthly'}</Text>
                                                        <Flex w="100%" p={2} rounded={'lg'} borderWidth={'0.5px'} borderColor={'gray.300'} flexDir="column">
                                                            <InputField
                                                                mb="0"
                                                                w={'150px'}
                                                                me="3px"
                                                                id="quota_value"
                                                                label="Quota Value"
                                                                name="quota_value"
                                                                value={biValue}
                                                                onChange={e => {
                                                                    setBiValue(e.target.value);
                                                                }}
                                                                placeholder="Enter Quota Value"
                                                                required="true"
                                                                borderRadius={'10px'}
                                                            />
                                                            {error?.quota_value ? <Text fontSize="sm" color={"red"} mb="5" mt="1">
                                                                {error?.quota_value}
                                                            </Text> : null}
                                                            <Button mt={2} px={2}
                                                                rounded={'lg'}
                                                                bg={'blue.100'}
                                                                w="130px"
                                                                whiteSpace="normal"
                                                                wordBreak="break-word"
                                                                onClick={() => AccountQuotaCreation('bi')}>
                                                                Update Quota
                                                            </Button>
                                                        </Flex>
                                                    </Card>
                                                    <Card w={'48%'} rounded={'lg'} m={1}>
                                                        <Text>Premium AI</Text>
                                                        <Text>{displaceQuotaValue('pi') + ' Minute Monthly'}</Text>
                                                        <Flex w="100%" p={2} rounded={'lg'} borderWidth={'0.5px'} borderColor={'gray.300'} flexDir="column">
                                                            <InputField
                                                                w={'150px'}
                                                                mb="0"
                                                                me="3px"
                                                                id="quota_value"
                                                                label="Quota Value"
                                                                name="quota_value"
                                                                value={piValue}
                                                                onChange={e => {
                                                                    setPiValue(e.target.value);
                                                                }}
                                                                placeholder="Enter Quota Value"
                                                                required="true"
                                                                borderRadius={'10px'}
                                                            />

                                                            {error?.quota_value ? <Text fontSize="sm" color={"red"} mb="5" mt="1">
                                                                {error?.quota_value}
                                                            </Text> : null}
                                                            <Button mt={2} px={2}
                                                                rounded={'lg'}
                                                                bg={'blue.100'}
                                                                w="130px"
                                                                whiteSpace="normal"
                                                                wordBreak="break-word"
                                                                onClick={() => AccountQuotaCreation('pi')}>
                                                                Update Quota
                                                            </Button>
                                                        </Flex>
                                                    </Card>
                                                </Flex>
                                            </Flex>
                                        </TabPanel>
                                        <TabPanel p={0} h="100%">
                                            <Flex flexDir="column" maxW="100%">
                                                <Text>Language Accessible for the Account</Text>
                                                <Alert borderRadius='8px' status='info' variant='left-accent' m={2} mr={2}>
                                                    <Flex>
                                                        <AlertIcon />
                                                        <Flex direction='column'>
                                                            <AlertTitle mr='12px'>Language Per account</AlertTitle>
                                                            <AlertDescription>Here you can assign a group of specific language for this account.</AlertDescription>
                                                        </Flex>
                                                    </Flex>
                                                    {/* <CloseButton position='absolute' right='8px' top='8px' /> */}
                                                </Alert>
                                            </Flex>
                                        </TabPanel>
                                        <TabPanel p={0} h="100%">
                                            <Card h="98%" w="99%">
                                                <Flex flexDir="column">
                                                    <Text as="h2" fontWeight="bold" fontSize="large">
                                                        Manage Document AI
                                                    </Text>
                                                    <Flex gap={2} alignItems="center" mt={2}>
                                                        <Text> {accountInfo[0]?.translation_support ? 'Disable' : 'Enable'} Document AI</Text>
                                                        <Switch as="span" colorScheme='green' isChecked={accountInfo[0]?.translation_support} onChange={mamageDocumentAiSupport} isDisabled={btnDisabled?.documentAi} />
                                                    </Flex>
                                                </Flex>
                                            </Card>
                                        </TabPanel>
                                        <TabPanel p={0} h="100%">
                                            <Card h="98%" w="99%">
                                                <Flex flexDir="column">
                                                    <Text as="h2" fontWeight="bold" fontSize="large">
                                                        Manage Device Support
                                                    </Text>
                                                    <Flex gap={2} alignItems="center" mt={2}>
                                                        <Text> {accountInfo[0]?.device_support ? 'Disable' : 'Enable'} Device support</Text>
                                                        <Switch as="span" colorScheme='green' isChecked={accountInfo[0]?.device_support} onChange={manageDeviceSupport} isDisabled={btnDisabled?.deviceSupport} />
                                                    </Flex>
                                                </Flex>
                                            </Card>
                                        </TabPanel>
                                        <TabPanel p={0} h="100%">
                                            <Card h="98%" w="99%">
                                                {renderSelectedAccountDetails}
                                                <Flex mt={8} h="100%" overflowY="hidden" pb={2}>
                                                    <Box w="100%" h="100%" bg={blackWhiteBg} borderRadius={'10px'} mt="3" overflowY="auto" className='custom-scrollbar'>
                                                        {accountInfo != null && accountInfo?.length > 0
                                                            ? <AccountUpload accountDetails={accountInfo[0]} />
                                                            : null}
                                                    </Box>
                                                </Flex>
                                            </Card>
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                            </Card>
                        </Flex>
                        : <Text>Collecting...</Text>}
                </Box>
                {/* {configurationCollapseFlag ? (
                    
                ) : (
                    <Box w="5%" p="3" borderRadius="10px" bg={brandBg} h="100%" ml="2" pl="5">
                        <Box w={"100%"} h="100%">
                            <AiFillLeftCircle
                                cursor="pointer"
                                onClick={() => {
                                    setConfiguationCollapseFlag(!configurationCollapseFlag);
                                }}
                                size="20px"
                            />
                            <Text
                                fontSize="16px"
                                fontWeight={'bold'}
                                mt="2"
                                css={{
                                    writingMode: "vertical-lr",
                                    textOrientation: "mixed",
                                }}>
                                Account Upload Details
                            </Text>
                        </Box>
                    </Box>
                )} */}
            </Flex>
            <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={AlertCancelRef}
        onClose={onAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Change Account Type
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You want to change account type to <Text as="span" fontWeight="bold" textTransform="capitalize">{accountInfo[0]?.account_access_class === ACCOUNT_ACCESS_CLASS.ADVANCE ? ACCOUNT_ACCESS_CLASS.BASIC : ACCOUNT_ACCESS_CLASS.ADVANCE}</Text>.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={AlertCancelRef} onClick={onAlertClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={updateAccType} ml={3}>
                Yes
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

        </Card>
    )
}

export default AccountDetails