import React, { useState, useEffect, useRef } from "react";
import {  useNavigate, useLocation } from "react-router-dom";
import QRCode from "react-qr-code";
// Chakra Import
import {
    Box,
    Flex,
    Text,
    Link,
    Image,
    Button,
    Select,
    Icon,
    Spacer,
    Alert,
    Drawer,
    Spinner,
    Tooltip,
    RadioGroup,
    Stack,
    Radio,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,    
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    SliderMark,
    AlertIcon,
    FormLabel,
    SimpleGrid,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Textarea,
    useToast,
    Checkbox,
    AlertDialog,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
} from "@chakra-ui/react";
import InputField from "../../../../components/fields/InputField";
import moment from "moment";
import Card from "../../../../components/card/Card";
import {
    AiOutlineCheckCircle,
    AiOutlineCopy,
    AiOutlineLink,
    AiOutlineMail,
    AiOutlineStop,
    AiOutlineSync,
    AiTwotoneAudio,
    AiOutlineVideoCamera,
    AiOutlineClockCircle,
    AiOutlineAntDesign
} from "react-icons/ai";
// Api Call Imports
import {
    getSingleLink,
    addNewLink,
    getAllLinks,
    disableLink,
    shareLink,
    refreshLink,
    getLinkCustomerEnabled,
    shareLinkWithCustomer,
    resetLeaderCode
} from "../../../../services/businessServices";
import { formatTime, formatSeconds } from '../../../../utils/TimeFunctions';
import MultichannelBg from "../../../../assets/communicator/multichannelai.png";

// Component Imports
import { toastFunctionToaster } from "../../../../utils/toastFunction";
import { useBlueButton, useGrayTextColor, useHOrange, useNoResultFoundText, usePillButtonBg, useRedButton, useTextColor, useYellowButton } from "../../../../theme/globalColorTheme";
import LinkEmailLeaderCode from "./LinkEmailLeaderCode";
import LinkQrPdf from "../../../subscriber/links/LinkQrPdf";
import TagsInput from "react-tagsinput";


function LinkManager() {
    const toast = useToast();
    const { state } = useLocation();
    const navigate = useNavigate();
    const yellowButtonBg = useYellowButton();
    const ErrorText = useNoResultFoundText()
    const buttonText = useTextColor()
    const grayText = useGrayTextColor()
    const buttonBg = usePillButtonBg()
    const blueButtonBg = useBlueButton()
    const redButton = useRedButton()
    const blueButton = useBlueButton()
    const orangeBg = useHOrange();
    const currentHost = window.location.hostname.includes('localhost') ? 'https://lingolet.ai/' : window.location.protocol + "//" + window.location.hostname + "/";

    const btnRef = React.useRef();
    const { isOpen:isAlertOpen, onOpen:onAlertOpen, onClose:onAlertClose } = useDisclosure()
 	const cancelRef = useRef()
	const [activeLink, setActiveLink] = useState('')
    const [loading, isLoading] = useState(true);

    const {
        isOpen: isOpenQr,
        onOpen: onOpenQr,
        onClose: onCloseQr,
    } = useDisclosure();
    const qrRef = React.useRef();
    const linkUuid = state?.linkUuid ? state?.linkUuid : null;
    const type = state?.type ? state?.type : null;

    const [linkInfo, setLinkInfo] = useState([]);
    const [saveDetailsLoader, setSaveDetailsLoader] = useState(false);

    const [showTooltip, setShowTooltip] = React.useState(false);
    const [linkUrl, setLinkUrl] = useState(null);
    const [modalQrCode, setModalQrCode] = useState(null);
    const [customerInfo, setCustomerInfo] = useState([]);
    const [linkCustomer, setLinkCustomer] = useState([]);
    const [linkCustomers, setLinkCustomers] = useState([]);
    const [linkUrlWithCode, setLinkUrlWithCode] = useState(null);

    const [toEmails, setToEmails] = useState([]);
    const [urlGroup, setUrlGroup] = useState(1);
    const [sendCode, setSendCode] = React.useState(false);

    //   Todo:Fetch all projects
    useEffect(() => {
        getLinkDetails();
        collectLinkCustomers();
    }, []);

    const getLinkDetails = () => {
        if (linkUuid != null) {
            isLoading(true);
            let paramObj = {
                link_uuid: linkUuid,
            };
            getSingleLink(paramObj)
                .then((res) => {
                    isLoading(false);
                    if (res?.data[0]?.status) {
                        setLinkInfo(res?.data[0]?.info);
                        setLinkCustomer(res?.data[0]?.customer);
                    }
                })
                .catch((err) => {
                    isLoading(false);
                });
        }
    };

    const refreshDynaLink = (linkUuidx) => {
        if (linkUuidx != null) {
            let objBody = {
                link_uuid: linkUuidx,
            };
            refreshLink(objBody)
                .then((res) => {
                    if (res?.result) {
                        if (res?.data[0]?.status) {
                            getLinkDetails();
                            toast(
                                toastFunctionToaster(
                                    "Link access code is successfully refreshed",
                                    "success"
                                )
                            );
                        } else {
                            toast(
                                toastFunctionToaster(
                                    "Failed to refresh the access code...",
                                    "error"
                                )
                            );
                        }
                    } else {
                        toast(
                            toastFunctionToaster(
                                "Failed to refresh the access code...",
                                "error"
                            )
                        );
                    }
                })
                .catch((err) => {
                    toast(
                        toastFunctionToaster(
                            "Failed to refresh the access code...",
                            "error"
                        )
                    );
                });
        } else {
            toast(
                toastFunctionToaster("Failed to refresh the access code...", "error")
            );
        }
    };

    // Todo:Reset Leader Code
    const refreshLeaderCode = (linkUuidx) => {
        if (linkUuidx != null) {
            let objBody = {
                link_uuid: linkUuidx,
            };
            resetLeaderCode(objBody)
                .then((res) => {
                    if (res?.result) {
                        if (res?.data[0]?.status) {
                            getLinkDetails();
                            toast(
                                toastFunctionToaster(
                                    "Leader access code is successfully refreshed",
                                    "success"
                                )
                            );
                        } else {
                            toast(
                                toastFunctionToaster(
                                    "Failed to refresh the leader access code...",
                                    "error"
                                )
                            );
                        }
                    } else {
                        toast(
                            toastFunctionToaster(
                                "Failed to refresh the leader access code...",
                                "error"
                            )
                        );
                    }
                })
                .catch((err) => {
                    toast(
                        toastFunctionToaster(
                            "Failed to refresh the leader access code...",
                            "error"
                        )
                    );
                });
        } else {
            toast(
                toastFunctionToaster("Failed to refresh the leader access code...", "error")
            );
        }
    };

    

    const disableSelectedLink = () => {
        const {uuid, code} = activeLink
        let paramObj = {
            link_uuid: uuid,
            link_code: code,
        };
        handleAlertClose()
        disableLink(paramObj)
            .then((res) => {
                if (res?.result && res?.data?.length > 0) {
                    if (res?.data[0]?.status) {
                        getLinkDetails("active");
                        toast(
                            toastFunctionToaster(
                                "Selected dynamic link is successfully disabled.",
                                "success"
                            )
                        );
                    } else {
                        toast(
                            toastFunctionToaster(
                                "Unable to complete the selected request..",
                                "error"
                            )
                        );
                    }
                } else {
                    toast(
                        toastFunctionToaster(
                            "Unable to complete the selected request..",
                            "error"
                        )
                    );
                }
            })
            .catch((err) => {
                toast(
                    toastFunctionToaster(
                        "Unable to complete the selected request...",
                        "error"
                    )
                );
            });
    };

    const collectLinkCustomers = () => {
        let paramObj = {
            records_per_page: 0,
            page_id: 0,
        };
        getLinkCustomerEnabled(paramObj)
            .then((res) => {
                if (res?.data[0]?.count) {
                    isLoading(false);
                    setLinkCustomers(res?.data[0]?.records);
                } else {
                    isLoading(false);
                    setLinkCustomers([]);
                }
            })
            .catch((err) => {
                setLinkCustomers([]);
            });
    };

    //   Todo:API to create new project
    const shareDynaLink = () => {
        if (toEmails?.length > 0 && linkUuid != null && (urlGroup == '11' || urlGroup == '10')) {
            let objBody = {
                target_emails: toEmails?.join(","),
                link_uuid: linkUuid,
                host_name: currentHost,
                url_group: urlGroup,
                is_code: sendCode,
            };
            shareLink(objBody)
                .then((res) => {
                    if (res?.result) {
                        toast(toastFunctionToaster(res.message, "success"));
                    } else {
                        toast(toastFunctionToaster(res.message, "error"));
                    }
                })
                .catch((err) => {
                    toast(toastFunctionToaster(err.message, "error"));
                });
        } else {
            toast(
                toastFunctionToaster(
                    "You must provide email addresse and select which url to share..",
                    "error"
                )
            );
        }
    };

    const shareLinkWithSelectedCustomer = (lnkCustomerUuid) => {
        let paramObj = {
            customerUuid: lnkCustomerUuid,
            linkUuid: linkUuid,
        };
        shareLinkWithCustomer(paramObj)
            .then((res) => {
                if (res?.data[0]?.status) {
                    getLinkDetails();
                    toast(toastFunctionToaster(res.message, "success"));
                } else {
                    toast(toastFunctionToaster(res.message, "error"));
                }
            })
            .catch((err) => {
                toast(toastFunctionToaster('Unable to share LingoLink with selected customer..', "error"));
            });
    };

    const renderAllCustomers = linkCustomers?.map((linkCust, index) => (
        <option
            key={linkCust.uuid}
            value={linkCust.uuid}
            style={{ color: "black" }}
        >
            {linkCust.info}
        </option>
    ));

    const launchLinkPrefix = () => {
        if (window.location.pathname.includes('/account')) {
            return '/account';
        } else if (window.location.pathname.includes('/business')) {
            return '/business';
        }
        return null;
    }

    function handleOpenAlert(uuid, code){
		setActiveLink({uuid, code})
		onAlertOpen()
	}

    function handleAlertClose(){
		onAlertClose()
		setActiveLink('')
	}

    const renderLinkDetails = linkInfo !== null && linkInfo?.length > 0
        ? linkInfo?.map((item, index) => (
            <Flex w={'100%'} direction={'column'}>
                <Flex w={'100%'} p={1} align={'center'}>
                    <Flex w={'85%'} direction={'column'} >
                        <Flex w={'100%'} mb="1" flexWrap='wrap'>
                            {linkInfo[0]?.multichannel
                                ? <Flex w={'50px'}>
                                    <Image h={'40px'} w={'40px'} borderRadius="5px" src={MultichannelBg} />
                                </Flex> : null}
                            <Text
                                rounded={"md"}
                                bg={linkInfo[0]?.active ? "gray.200" : "red.200"}
                                color={grayText}
                                px={2}

                            >
                                {linkInfo[0]?.dead ? (
                                    <Icon
                                        as={AiOutlineStop}
                                        color={"red.600"}
                                        ml={1}
                                        mr={1}
                                        mt={1}
                                    />
                                ) : null}
                                {linkInfo[0]?.title}
                            </Text>
                            <Flex  align={'center'} mr="2" ml="2">
                                <Text p="6px" w='85px' fontWeight={"bold"} textAlign="center" bg={"green.200"} borderRadius={'0px'}  fontSize={"sm"} color={grayText} borderLeftRadius={'10px !important'} mr='-3'>
                                {linkInfo[0]?.code}
                                </Text>
                                <Button
                                    onClick={() => refreshDynaLink(linkInfo[0]?.uuid)}
                                    size={"sm"}
                                    bg={blueButton}
                                    color={buttonText}
                                    colorScheme="gray"
                                    _hover={{bg:blueButton, color:buttonText}}
                                    borderRadius={'10px'}
                                >
                                    <Text as="span" ml="1"> Change Access Code</Text>
                                </Button>
                            </Flex>
                            {item?.multichannel ? (
                                <Flex  align={'center'}>
                                    <Text p="6px" w='60px' fontWeight={"bold"} pl='10px' bg={"green.200"} borderRadius={'0px'}  fontSize={"sm"} color={grayText} borderLeftRadius={'10px !important'} mr='-3'>
                                        {linkInfo[0]?.leader_code}
                                    </Text>
                                        <Button
                                            onClick={() => refreshLeaderCode(linkInfo[0]?.uuid)}
                                            size={"sm"}
                                            bg={blueButton}
                                            color={buttonText}
                                            colorScheme="gray"
                                            buttonText
                                            borderRadius={'10px'}
                                            _hover={{bg:blueButton, color:buttonText}}
                                        >
                                            <Text as="span" ml="1"> Change Leader Code</Text>
                                        </Button>
                                </Flex>
                            ):null}
                        </Flex>
                            {linkInfo[0]?.trial
                                ? <Flex w={"100%"}>
                                    <Text>
                                        Link Start Time (UTC):{" "}
                                        {moment(linkInfo[0]?.starttime).format("MMM DD,YYYY,HH:mm a")}
                                    </Text>
                                    <Text ml={2}>
                                        Link End Time (UTC):{" "}
                                        {moment(linkInfo[0]?.endtime).format("MMM DD,YYYY,HH:mm a")}
                                    </Text>
                                </Flex> : null}
                            {linkInfo[0]?.trial
                                ? <Flex w={"100%"} >
                                    <Text>
                                        Link Assigned Time: <b>{linkInfo[0]?.hours} Hours</b>
                                    </Text>
                                    <Text ml={3}>
                                        {" "}
                                        | Time Left: <b>{linkInfo[0]?.time_left}</b>
                                    </Text>
                                </Flex> : null}
                            <Flex w={'100%'} p={0} mt={1} flexWrap={'wrap'}>
                                <Button
                                    onClick={() => {
                                        navigate(launchLinkPrefix() + "/linkmananger/sessions", {
                                            state: {
                                                linkUuid: linkInfo[0]?.uuid,
                                                linkCode: linkInfo[0]?.code,
                                            },
                                        });
                                    }}
                                    bg={blueButtonBg}
                                    size="xs"
                                >
                                    List all Sessions
                                </Button>
                                <Button
                                    onClick={() => {
                                        navigate(launchLinkPrefix() + "/linkmananger/calls", {
                                            state: {
                                                linkUuid: linkInfo[0]?.uuid,
                                                linkCode: linkInfo[0]?.code,
                                                linkKey: linkInfo[0]?.key
                                            },
                                        });
                                    }}
                                    ml={1}
                                    bg={blueButtonBg}
                                    size="xs"
                                >
                                    List all Calls
                                </Button>
                                <Tooltip
                                    hasArrow
                                    placement={"top"}
                                    bg="teal.500"
                                    onMouseEnter={() => setShowTooltip(true)}
                                    onMouseLeave={() => setShowTooltip(false)}
                                    color="white"
                                    label="Add logo, background image for specific link"
                                >
                                    <Button
                                        ml={1}
                                        bg={blueButtonBg}
                                        onClick={() => {
                                            navigate(launchLinkPrefix() + "/linkmananger/configuration", {
                                                state: {
                                                    linkUuid: linkInfo[0]?.uuid,
                                                    linkKey: linkInfo[0]?.key
                                                },
                                            });
                                        }}
                                        size={"xs"}
                                    >
                                        <Icon as={AiOutlineAntDesign} mr={2} />
                                        Configure Link
                                    </Button>
                                </Tooltip>
                                <Button ml={1} bg={yellowButtonBg} size={"xs"}
                                    onClick={() => {
                                        navigate(launchLinkPrefix() + "/linkmananger/transcripts",
                                            {
                                                state: {
                                                    linkDetails: linkInfo
                                                },
                                            }
                                        );
                                    }}>
                                    <Icon as={AiOutlineAntDesign} mr={2} /> Transcripts
                                </Button>
                                <Button ml={1} bg={orangeBg} size={"xs"} overflow="hidden">
                                <LinkQrPdf linkItem={linkInfo}/>
                                </Button>
                                {item?.multichannel ? (
                                    <LinkEmailLeaderCode linkItem={item}/>
                                ):null}
                            </Flex>
                    </Flex>
                    <Flex w={'15%'} justifyContent='flex-end'>
                        <Flex maxW={'300px'} ml={1}>
                            <Flex w={'100%'}>
                                {/* <Tooltip
                                    hasArrow
                                    placement={"top"}
                                    bg="teal.500"
                                    onMouseEnter={() => setShowTooltip(true)}
                                    onMouseLeave={() => setShowTooltip(false)}
                                    color="white"
                                    label="Share Link and Access Code to anyone over Email"
                                >
                                    <Button
                                        bg={"blue.100"}
                                        onClick={() => {
                                            setCustomerInfo(null);
                                            collectLinkCustomers();
                                            setLinkUrl(linkInfo[0]?.url);
                                            setLinkUrlWithCode(linkInfo[0]?.fullurl);
                                        }}
                                        size={"sm"}
                                    >
                                        <Icon as={AiOutlineMail} mr={2} />
                                        Share
                                    </Button>
                                </Tooltip> */}
                                <Tooltip
                                    hasArrow
                                    placement={"top"}
                                    bg="teal.500"
                                    onMouseEnter={() => setShowTooltip(true)}
                                    onMouseLeave={() => setShowTooltip(false)}
                                    color="white"
                                    label="Disable Link (Instantly becomes unusable)"
                                >
                                    <Button
                                        ml={1}
                                        bg={redButton}
                                        onClick={() => {
                                            handleOpenAlert(linkInfo[0]?.uuid, linkInfo[0]?.code);
                                        }}
                                        size={"sm"}
                                        color={buttonText}
                                    >
                                        Disable Link
                                    </Button>
                                </Tooltip>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex w={'100%'}>
                    <Flex
                        w={"100%"}
                        p={1}
                        direction={'column'}
                        bg={linkInfo[0]?.trial ? (linkInfo[0]?.calls?.expired ? 'red.100' : 'green.100') : "gray.100"}
                        borderWidth={"0.5px"}
                        borderColor={"blue.100"}
                        rounded={"md"}
                    >
                        <Flex w={"100%"} direction={"column"}>
                            <Flex w={'100%'} mt={1} mb={1}>
                                <Flex w={'100%'} >
                                    <Text mt={2} mr={2} fontSize={"lg"} color={"blue.600"}>
                                        {linkInfo[0]?.url ? currentHost + linkInfo[0]?.url : "No Url"}
                                    </Text>
                                </Flex>
                                <Tooltip
                                    hasArrow
                                    placement={"top"}
                                    bg="teal.500"
                                    onMouseEnter={() => setShowTooltip(true)}
                                    onMouseLeave={() => setShowTooltip(false)}
                                    color="white"
                                    label="Copy Url"
                                >
                                    <Box
                                        w={"35px"}
                                        ml={1}
                                        bg={"gray.200"}
                                        pr={2}
                                        rounded={"md"}
                                        mr={2}
                                    >
                                        <Icon
                                            color={'black'}
                                            onClick={() => navigator.clipboard.writeText(currentHost + linkInfo[0]?.url)}
                                            ml={2}
                                            mt={1}
                                            h={5}
                                            _hover={{ cursor: "pointer" }}
                                            w={5}
                                            as={AiOutlineCopy}
                                        />
                                    </Box>
                                </Tooltip>
                                <Tooltip
                                    hasArrow
                                    placement={"top"}
                                    bg="teal.500"
                                    onMouseEnter={() => setShowTooltip(true)}
                                    onMouseLeave={() => setShowTooltip(false)}
                                    color="white"
                                    label="Open Url in new Tab"
                                >
                                    <Link
                                        p={1}
                                        w={'35px'}
                                        bg={"gray.200"}
                                        rounded={"md"}
                                        mr={2}
                                        align={'center'}
                                        href={currentHost + linkInfo[0]?.url}
                                        target="_blank"
                                    >
                                        <Icon
                                            ml={2}
                                            mt={1}
                                            h={5}
                                            _hover={{ cursor: "pointer" }}
                                            w={5}
                                            as={AiOutlineLink}
                                            color={'black'}
                                        />
                                    </Link>
                                </Tooltip>
                            </Flex>
                            <Flex w={'100%'} mt={1} mb={1} borderTopColor={'gray.300'} borderTopWidth={'0.5px'}>
                                <Flex w={'100%'} mt={1}>
                                    <Text mt={2} mr={2} fontSize={"lg"} color={"blue.600"}>
                                        {linkInfo[0]?.fullurl ? currentHost + linkInfo[0]?.fullurl : "No Url"}
                                    </Text>
                                </Flex>
                                <Tooltip
                                    hasArrow
                                    placement={"top"}
                                    bg="teal.500"
                                    onMouseEnter={() => setShowTooltip(true)}
                                    onMouseLeave={() => setShowTooltip(false)}
                                    color="white"
                                    label="Copy Url"
                                >
                                    <Box
                                        mt={1}
                                        w={"35px"}
                                        ml={1}
                                        bg={"gray.200"}
                                        pr={2}
                                        rounded={"md"}
                                        mr={2}
                                    >
                                        <Icon
                                            onClick={() => navigator.clipboard.writeText(currentHost + linkInfo[0]?.fullurl)}
                                            ml={2}
                                            mt={1}
                                            h={5}
                                            _hover={{ cursor: "pointer" }}
                                            w={5}
                                            as={AiOutlineCopy}
                                            color={'black'}
                                        />
                                    </Box>
                                </Tooltip>
                                <Tooltip
                                    hasArrow
                                    placement={"top"}
                                    bg="teal.500"
                                    onMouseEnter={() => setShowTooltip(true)}
                                    onMouseLeave={() => setShowTooltip(false)}
                                    color="white"
                                    label="Open Url in new Tab"
                                >
                                    <Link
                                        mt={1}
                                        p={1}
                                        w={'35px'}
                                        bg={"gray.200"}
                                        rounded={"md"}
                                        mr={2}
                                        align={'center'}
                                        href={currentHost + linkInfo[0]?.fullurl}
                                        target="_blank"
                                    >
                                        <Icon
                                            ml={2}
                                            mt={1}
                                            h={5}
                                            _hover={{ cursor: "pointer" }}
                                            w={5}
                                            as={AiOutlineLink}
                                            color={'black'}
                                        />
                                    </Link>
                                </Tooltip>
                            </Flex>
                            <Flex w={'100%'}>
                                {linkInfo[0]?.trial ? (
                                    (linkInfo[0]?.calls?.expired
                                        ? <Flex w={'100%'} bg={'red.200'} align={'center'} rounded={'md'} p={1} borderWidth={"0.05px"} borderColor={"gray.400"} >
                                            <Box w={'250px'}>
                                                <Text color={"red.700"}>Expired Trial Link</Text>
                                            </Box>
                                            <Flex w={'100%'} direction={'column'}>
                                                <Box w={'100%'}>
                                                    <Icon as={AiOutlineClockCircle} mr={1} mt={1} h={5} w={5} /> Time: <b>{formatSeconds(linkInfo[0]?.calls?.calls[6].toFixed(2))}</b>  | Used: <b>{formatSeconds(linkInfo[0]?.calls?.calls[7].toFixed(2))}</b> | Remaining: <b>{linkInfo[0]?.calls?.calls[8] > 0 ? formatSeconds(linkInfo[0]?.calls?.calls[8].toFixed(2)) : "00:00:00"}</b>
                                                </Box>
                                                <Flex w={'100%'}>
                                                    <Box w={'100%'}>
                                                        <Icon as={AiTwotoneAudio} mr={1} mt={1} h={5} w={5} /> Calls: <b>{linkInfo[0]?.calls?.calls[0]} | Made: {linkInfo[0]?.calls?.calls[1]} | Remaining: {linkInfo[0]?.calls?.calls[2]}</b>
                                                    </Box>
                                                    <Box w={'100%'}>
                                                        <Icon as={AiOutlineVideoCamera} mr={1} mt={1} h={5} w={5} /> Calls: <b>{linkInfo[0]?.calls?.calls[3]} | Made: {linkInfo[0]?.calls?.calls[4]} | Remaining: {linkInfo[0]?.calls?.calls[5]}</b>)
                                                    </Box>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                        : <Flex w={'100%'} bg={'green.200'} align={'center'} rounded={'md'} p={1} borderWidth={"0.05px"} borderColor={"gray.400"}>
                                            <Box w={'250px'}>
                                                <Text color={"blue.700"}>Active Trial Link</Text>
                                            </Box>
                                            <Flex w={'100%'} direction={'column'}>
                                                <Box w={'100%'}>
                                                    <Icon as={AiOutlineClockCircle} mr={1} mt={1} h={5} w={5} /> Time: <b>{formatSeconds(linkInfo[0]?.calls?.calls[6].toFixed(2))}</b> | Used: <b>{formatSeconds(linkInfo[0]?.calls?.calls[7].toFixed(2))}</b>  | Remaining: <b>{linkInfo[0]?.calls?.calls[8] > 0 ? formatSeconds(linkInfo[0]?.calls?.calls[8].toFixed(2)) : "00:00:00"} </b>
                                                </Box>
                                                <Flex w={'100%'}>
                                                    <Box w={'100%'}>
                                                        <Icon as={AiTwotoneAudio} mr={1} mt={1} h={5} w={5} /> Calls: <b>{linkInfo[0]?.calls?.calls[0]} | Made: {linkInfo[0]?.calls?.calls[1]} | Remaining: {linkInfo[0]?.calls?.calls[2]}</b>
                                                    </Box>
                                                    <Box w={'100%'}>
                                                        <Icon as={AiOutlineVideoCamera} mr={1} mt={1} h={5} w={5} /> Calls: <b>{linkInfo[0]?.calls?.calls[3]} | Made: {linkInfo[0]?.calls?.calls[4]} | Remaining: {linkInfo[0]?.calls?.calls[5]}</b>)
                                                    </Box>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    )
                                ) : (
                                    <Flex w={'100%'}>
                                        {linkInfo[0].multichannel === false
                                            ? <Flex w={'100%'} bg={'green.200'} align={'center'} rounded={'md'} p={1} borderWidth={"0.05px"} borderColor={"gray.400"}>
                                                <Text fontSize={'md'} color={"green.700"}>Not a trial/demo link.</Text>
                                            </Flex> : null}
                                    </Flex>
                                )}
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex w={'100%'} h={'150px'}>
                    <Flex maxW={'250px'}>
                        <Flex w={'100%'} direction={'column'} p={4}>
                            <Box w={'100%'}
                                onClick={() => {
                                    setModalQrCode(linkInfo[0]?.url);
                                    onOpenQr();
                                }}
                                _hover={{ cursor: 'pointer', borderColor: 'red.600', borderWidth: '1px' }}
                                p={1} borderColor={'gray.400'} borderWidth={'1px'} rounded={'md'}>
                                <QRCode
                                    size={90}
                                    style={{ height: "auto", maxWidth: "100", width: "100" }}
                                    value={linkInfo[0]?.url}
                                    viewBox={`0 0 90 90`}
                                />
                            </Box>
                            <Text>QR(Url)</Text>
                        </Flex>
                    </Flex>
                    <Flex maxW={'250px'}>
                        <Flex w={'100%'} direction={'column'} p={4}>
                            <Box w={'100%'}
                                onClick={() => {
                                    setModalQrCode(linkInfo[0]?.fullurl);
                                    onOpenQr();
                                }}
                                _hover={{ cursor: 'pointer', borderColor: 'red.600', borderWidth: '1px' }}
                                p={1} borderColor={'gray.400'} borderWidth={'1px'} rounded={'md'}>
                                <QRCode
                                    size={90}
                                    style={{ height: "auto", maxWidth: "100", width: "100" }}
                                    value={linkInfo[0]?.fullurl}
                                    viewBox={`0 0 90 90`}
                                />
                            </Box>
                            <Text>QR(Url+Code)</Text>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex w={'100%'} h={'300px'}>
                    {linkInfo[0]?.design?.logo
                        ? <Flex w={'300px'} h={'100px'} p={5} direction={'column'} align={'center'}>
                            <Text>Lingolink Logo</Text>
                            <Image src={linkInfo[0]?.design?.logo} w={'160px'} />
                        </Flex> : null}
                    {linkInfo[0]?.design?.background
                        ? <Flex w={'100%'} p={5} direction={'column'} align={'center'}>
                            <Text>Lingolink Background</Text>
                            <Image src={linkInfo[0]?.design?.background} minW={'400px'} minH={'200px'} />
                        </Flex> : null}
                </Flex>
            </Flex>)) : null;

    return (
        <>
            <Box direction="column" pt={{ sm: "125px", lg: "75px" }}>
                <Flex
                    p="3"
                    alignItems="center"
                    borderBottomWidth="1px"
                    justifyContent={"space-between"}>
                    <Text fontWeight={"500"} fontSize={"18px"}>
                        Manage your LingoLink 
                    </Text>
                    <Spacer />
                    <Flex w={'150px'}>
                        <Button ml={1} bg={buttonBg} size="sm" color={buttonText}
                            onClick={() => {
                                navigate(launchLinkPrefix() + "/linklisting", {
                                });
                            }}>
                            Back to Link Listing
                        </Button>
                    </Flex>
                </Flex>
                {loading ? (
                    <Flex alignItems="center" justifyContent="center" h="50vh">
                        <Spinner size="xl" />
                    </Flex> ) : 
                    linkInfo?.length > 0
                    ? <Flex
                        p={2}
                        w={"100%"}
                        mt={2}
                        rounded={'md'}
                        borderWidth={"0.05px"}
                        borderColor={linkInfo[0]?.active ? "gray.200" : "red.200"}
                    >
                        <Flex w={'100%'} p={1} rounded={'md'} borderWidth={'1px'} borderColor={'gray.200'}>
                            {renderLinkDetails}
                        </Flex>
                        <Flex ml={1} minW={'400px'} maxW={'400px'}
                            direction={'column'} p={2}
                            rounded={'md'} borderWidth={'1px'} borderColor={'gray.200'}>
                            <Flex w={'100%'} size={'xl'} direction={'column'}>
                                <Text>{"Email LingoLink to Subscriber(s)"}</Text>
                            </Flex>
                            <Text mt={5} mb="2" fontSize={"sm"}>
                                Please select which URL you want to share in  this email:
                            </Text>
                            <RadioGroup onChange={setUrlGroup} value={urlGroup}>
                                <Stack direction='column'>
                                    <Radio value={'10'}>{currentHost + linkInfo[0].url}</Radio>
                                    <Radio value={'11'}>{currentHost + linkInfo[0].fullurl}</Radio>
                                </Stack>
                            </RadioGroup>
                            <Text mb="2" fontSize={"md"} color={"blue.500"}>

                            </Text>
                            <FormLabel mt="3" ml="2" required>
                                <Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
                                    To Email Address(es):
                                </Text>
                                <TagsInput 
                                    style={{ width: '100%' }}
                                    maxTags={100}
                                    value={toEmails}
                                    onChange={tags => setToEmails(tags)}
                                    inputProps={{ placeholder: 'Enter Email' }}
                                />
                                <Text
                                    textAlign={"right"}
                                    fontWeight={"500"}
                                    fontSize={"sm"}
                                    mt="2"
                                >
                                    {toEmails?.length}/100
                                </Text>
                                <Text>
                                    Note: An email will be sent to all above emails address with the
                                    link
                                </Text>
                            </FormLabel>
                            <FormLabel mt="3" ml="2" required>
                                <Flex w={"100%"}>
                                    <Checkbox
                                        onChange={() => setSendCode(!sendCode)}
                                        checked={sendCode}
                                    ></Checkbox>
                                    <Text ml={1}>
                                        Do you want to add Link Access Code with Email too?
                                    </Text>
                                </Flex>
                            </FormLabel>
                            <Flex w={'100%'}>
                                <Button
                                    colorScheme="blue"
                                    onClick={() => {
                                        shareDynaLink();
                                    }}
                                    isDisabled={
                                        toEmails === null || toEmails?.length === 0 ? true : false
                                    }
                                    isLoading={saveDetailsLoader ? true : false}
                                >
                                    Send Email
                                </Button>
                            </Flex>
                            {/* <Tabs>
                            <TabList>
                                <Tab>Assign Customer</Tab>
                                <Tab>Email Customer</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    {linkCustomer?.length > 0
                                    ?<Flex w={'100%'} direction ={'column'} bg={'green.100'} rounded={'md'} p={2}>
                                        <Text w={'100%'}>Link is already shared with {linkCustomer?.length > 0 ? linkCustomer[0]?.email : 'xxx'}</Text>
                                    </Flex>
                                    :<Flex w={'100%'} direction ={'column'}>
                                        <Flex w={'100%'} direction={'column'}>
                                            <Text>{"Assign LingoLink with Customers"}</Text>
                                        </Flex>
                                        <Flex w={'100%'} direction={'column'}>
                                            {linkCustomers?.length > 0
                                            ?<Flex w={'100%'} p={2}
                                                rounded={'md'} borderWidth={'1px'} borderColor={'gray.200'}
                                                direction={'column'}>
                                                <Text mb={4} py={2}>Select customer from list below to share Lingolink:</Text>
                                                <Select
                                                    value={customerInfo}
                                                    name="customerInfo"
                                                    id={'customerInfo'}
                                                    placeholder="Select Customer"
                                                    size="md"
                                                    // color={textColor}
                                                    borderRadius="16px"
                                                    onChange={e => {
                                                    setCustomerInfo(e.target.value);
                                                    }}
                                                >
                                                {renderAllCustomers}
                                                </Select>
                                                {customerInfo
                                                ?<Button mt={4}  my={4} bg={'blue.100'} 
                                                onClick={() => shareLinkWithSelectedCustomer(customerInfo)}
                                                rounded={'md'} size={'sm'}>Share Link with above customer</Button>:null}
                                            </Flex>
                                            :<Text color={'red.300'}><Icon as={AiOutlineStop} mt={1} mr={1} /> Please add customers first to share LingoLink</Text>}
                                        </Flex>
                                    </Flex>}
                                </TabPanel>
                                <TabPanel>
                                </TabPanel>
                            </TabPanels>
                        </Tabs> */}
                        </Flex>
                        <AlertDialog
								isOpen={isAlertOpen}
								leastDestructiveRef={cancelRef}
								onClose={onAlertClose}
							>
								<AlertDialogOverlay>
									<AlertDialogContent>
										<AlertDialogHeader fontSize='lg' fontWeight='bold'>
											Disable Link
										</AlertDialogHeader>

										<AlertDialogBody>
											Are you sure? Disabled link instantly becomes unusable
										</AlertDialogBody>

										<AlertDialogFooter>
											<Button ref={cancelRef} onClick={onAlertClose}>
												Cancel
											</Button>
											<Button colorScheme='red' onClick={disableSelectedLink} ml={3}>
												Disable Link
											</Button>
										</AlertDialogFooter>
									</AlertDialogContent>
								</AlertDialogOverlay>
							</AlertDialog>
                    </Flex>
                    : <Flex w={'100%'} p={2} bg={'red.100'}>
                        <Text color={ErrorText}>Error collecting Selected Link Details</Text>
                    </Flex>}
            </Box>
            <Modal finalFocusRef={qrRef} isOpen={isOpenQr} onClose={onCloseQr}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>QR Code for the LingoLink</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {modalQrCode != null
                            ? <Flex w={'100%'} direction={'column'}>
                                <QRCode
                                    title={currentHost + modalQrCode}
                                    size={90}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={currentHost + modalQrCode}
                                    viewBox={`0 0 90 90`}
                                />
                                <Text p={1} bg={'blue.100'}>{currentHost + modalQrCode}</Text>
                            </Flex>
                            : <Text color={'red.400'}>There is some issue with selected URL, please try again..</Text>}
                    </ModalBody>
                    <ModalFooter>
                        <Button size={'sm'} colorScheme='blue'
                            onClick={() => {
                                setModalQrCode(null);
                                onCloseQr();
                            }}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default LinkManager;
