import React from "react";
import { BiUserCircle, BiMoney, BiDollarCircle } from "react-icons/bi";
import { Icon } from "@chakra-ui/react";
import {
  AiOutlineSetting,
  AiOutlineDashboard,
  AiOutlineMobile,
  AiOutlineSelect,
  AiOutlineLink,
  AiOutlineCar,
  AiFillCreditCard,
  AiOutlineGift,
  AiOutlineUngroup,
  AiOutlineSecurityScan,
  AiOutlineUserSwitch,
  AiOutlineUserAdd,
  AiOutlineOrderedList,
  AiOutlineAccountBook,
  AiOutlineMan,
  AiOutlineUser,
  AiOutlineMoneyCollect,
  AiOutlineDollarCircle,
  AiOutlineTranslation,
} from "react-icons/ai";

// Business Component Imports
import AccountDashboard from "../komponents/accountmanagement/dashboard/AccountDashboard";
import AccountTeam from "../komponents/accountmanagement/management/AccountTeam";
import BusinessProfile from "../komponents/business/profile/BusinessProfile";
import LocationsListing from "../komponents/accountmanagement/locations/LocationsListing";
import LocationsSubscribers from "../komponents/accountmanagement/locations/LocationSubscribers";

// Events
import LocationEvents from "../komponents/accountmanagement/events/LocationEvents";
import LocationBilling from "../komponents/accountmanagement/billing/LocationBilling";
import AccountBilling from "../komponents/accountmanagement/billing/AccountBilling";
import LocationCallHistory from "../komponents/accountmanagement/billing/LocationCallHistory";
import AccountCallHistory from "../komponents/accountmanagement/billing/AccountCallHistory";

// LingoLinks
import LinkListing from "../komponents/business/workspace/Links/LinkListing";
import LinkManager from "../komponents/business/workspace/Links/LinkManager";
import LinkSession from "../komponents/business/workspace/Links/LinkSession";
import LinkCalls from "../komponents/business/workspace/Links/LinkCalls";
import LinkConfiguration from "../komponents/business/workspace/Links/LinkConfiguration";

// Language Associate
import LanguageAssociateList from "../komponents/business/LangAssociates/LanguageAssociateList";

// Common
import Helper from "../komponents/common/helper/Helper";
import PlatformTrust from "../komponents/common/helper/PlatformTrust";
import ApplicationSettings from "../komponents/common/ApplicationSettings";
import AccountDesign from "../komponents/accountmanagement/management/AccountDesign";
import SubDomainSetup from "../komponents/accountmanagement/management/SubDomainSetup";
import CardListing from "../komponents/accountmanagement/LingoCard/CardListing";
import AdminSchedule from "../komponents/business/workspace/schedule/AdminSchedule";
import LanguagePrefrence from "../komponents/accountmanagement/management/LanguagePreference";
import { ACCOUNT_ACCESS_CLASS } from "../komponents/business/accounts/accountmanagement/constants";
import DeviceSupport from "../komponents/accountmanagement/DeviceSupport/DeviceSupport";
import TranslationSupport from "../komponents/accountmanagement/translationSupport/TranslationSupport";
import Sharing from "../komponents/accountmanagement/sharing/Sharing";
import ProjectListing from "../komponents/business/workspace/projects/ProjectListing";
import ProjectContent from "../komponents/business/workspace/projects/ProjectContent";
import ContentWorkflowMgmt from "../komponents/business/workspace/projects/ContentWorkflowMgmt";
import ContentWorkflowResults from "../komponents/business/workspace/projects/ContentWorkflowResult";


const accountRoutes = (isLspAccountType=false, accountType=ACCOUNT_ACCESS_CLASS.BASIC, translationsupport = false, devicesupport=false) => {
  return ([
    {
      name: "Dashboard",
      path: "/account",
      icon: <Icon as={AiOutlineDashboard} width="25px" height="25px" />,
      collapse: true,
      items: [
        {
          name: "Dashboard",
          layout: "/account",
          path: "/dashboard",
          component: <AccountDashboard />,
          secondary: true,
          showToMenu: true,
          icon: <Icon as={AiOutlineDashboard} width="20px" height="20px" />,
        },
      ],
    },
    {
      name: "Location+Subscriber",
      path: "/account",
      icon: <Icon as={AiOutlineAccountBook} width="25px" height="25px" />,
      collapse: true,
      isHidden: accountType === ACCOUNT_ACCESS_CLASS.BASIC,
      items: [
        {
          name: "Manage",
          layout: "/account",
          path: "/locations",
          component: <LocationsListing />,
          secondary: true,
          showToMenu: true,
          icon: <Icon as={AiOutlineUserAdd} width="20px" height="20px" />,
        },
        {
          name: "Manage",
          layout: "/account",
          path: "/locations/subscribers",
          component: <LocationsSubscribers />,
          secondary: true,
          showToMenu: false,
          icon: <Icon as={AiOutlineUserAdd} width="20px" height="20px" />,
        },
        {
          name: "Subscriber Events",
          layout: "/account",
          path: "/events",
          component: <LocationEvents />,
          secondary: true,
          showToMenu: true,
          icon: <Icon as={AiOutlineUngroup} width="20px" height="20px" />,
        },
      ]
    },
    {
      name: "LingoLink",
      path: "/account",
      icon: <Icon as={AiOutlineLink} width="25px" height="25px" />,
      collapse: true,
      items: [
        {
          name: "LingoLink",
          layout: "/account",
          path: "/linklisting",
          component: <LinkListing />,
          secondary: true,
          showToMenu: true,
          icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
        },
        {
          name: "Schedule",
          layout: "/account",
          path: "/schedule",
          component: <AdminSchedule />,
          secondary: true,
          showToMenu: true,
          icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
        },
        {
          name: "LingoLink",
          layout: "/account",
          path: "/linkmanager",
          component: <LinkManager />,
          secondary: true,
          showToMenu: false,
          icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
        },
        {
          name: "Link Configuration",
          layout: "/account",
          path: "/linkmananger/configuration",
          component: <LinkConfiguration />,
          secondary: true,
          showToMenu: false,
          icon: <Icon as={AiOutlineLink} width="20px" height="20px" />,
        },
        {
          name: "Dynamic Links",
          layout: "/account",
          path: "/linkmananger/sessions",
          component: <LinkSession />,
          secondary: true,
          showToMenu: false,
          icon: <Icon as={AiOutlineLink} width="20px" height="20px" />,
        },
        {
          name: "Dynamic Links",
          layout: "/account",
          path: "/linkmananger/calls",
          component: <LinkCalls />,
          secondary: true,
          showToMenu: false,
          icon: <Icon as={AiOutlineSecurityScan} width="20px" height="20px" />,
        },
        {
          name: "LingoCard",
          layout: "/account",
          path: "/cardlisting",
          component: <CardListing />,
          secondary: true,
          showToMenu: true,
          icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
        },
        {
          name: "Sharing",
          layout: "/account",
          path: "/sharing",
          component: <Sharing />,
          secondary: true,
          showToMenu: true,
          icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
        },
        {
          name: "Language Preference",
          layout: "/account",
          path: "/languagepreference",
          component: <LanguagePrefrence />,
          secondary: true,
          showToMenu: true,
          icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
        },
      ]
    },
    {
      name: "Language Associates",
      path: "/account",
      icon: <Icon as={AiOutlineUserAdd} width="25px" height="25px" />,
      collapse: true,
      isHidden: !isLspAccountType,
      items: [
        {
          name: "Associate Listing",
          layout: "/account",
          path: "/associateListing",
          component: <LanguageAssociateList />,
          secondary: true,
          showToMenu: true,
          icon: <Icon as={AiOutlineOrderedList} width="20px" height="20px" />,
        },
      ],
    },
    {
      name: "Call History",
      path: "/account",
      icon: <Icon as={AiOutlineMoneyCollect} width="25px" height="25px" />,
      collapse: true,
      items: [
        {
          name: "Account Specifc",
          layout: "/account",
          path: "/account_callhistory",
          component: <AccountCallHistory />,
          secondary: true,
          showToMenu: true,
          icon: <Icon as={AiOutlineOrderedList} width="20px" height="20px" />,
        },
        {
          name: "Locations Specific",
          layout: "/account",
          path: "/location_callhistory",
          component: <LocationCallHistory />,
          secondary: true,
          showToMenu: true,
          icon: <Icon as={AiOutlineOrderedList} width="20px" height="20px" />,
        }
      ],
    },
    {
      name: "Billing & Payments",
      path: "/account",
      icon: <Icon as={AiOutlineDollarCircle} width="25px" height="25px" />,
      collapse: true,
      items: [
        {
          name: "Account Billing",
          layout: "/account",
          path: "/account_billing",
          component: <AccountBilling />,
          secondary: true,
          showToMenu: true,
          icon: <Icon as={AiOutlineDollarCircle} width="20px" height="20px" />,
        },
        {
          name: "Location Billing",
          layout: "/account",
          path: "/location_billing",
          component: <LocationBilling />,
          secondary: true,
          showToMenu: true,
          icon: <Icon as={AiOutlineDollarCircle} width="20px" height="20px" />,
        },
      ],
    },
    {
      name: "Device Support",
      path: "/account",
      icon: <Icon as={AiOutlineMobile} width="25px" height="25px" />,
      collapse: true,
      isHidden:!devicesupport,
      items: [
        {
          name: "Device Support",
          layout: "/account",
          path: "/device-support",
          component: <DeviceSupport/> ,
          secondary: true,
          showToMenu: true,
          icon: <Icon as={AiOutlineMobile} width="20px" height="20px" />,
        }
      ],
    },
    {
      name: "Translation Support",
      path: "/account",
      icon: <Icon as={AiOutlineTranslation} width="25px" height="25px" />,
      collapse: true,
      isHidden:!translationsupport,
      items: [
			{
				name: "Projects",
				layout: "/account",
				path: "/projects",
				component: <ProjectListing />,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
			},
			{
				name: "Content",
				layout: "/account",
				path: "/project/content",
				component: <ProjectContent />,
				secondary: true,
				showToMenu: false,
				icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
			},
			{
				name: "Workflow",
				layout: "/account",
				path: "/project/content/workflow",
				component: <ContentWorkflowMgmt />,
				secondary: true,
				showToMenu: false,
				icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
			},
			{
				name: "Workflow",
				layout: "/account",
				path: "/project/content/workflow_result",
				component: <ContentWorkflowResults />,
				secondary: true,
				showToMenu: false,
				icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
			},
		],
    },
    {
      name: "Settings",
      path: "/account",
      icon: <Icon as={AiOutlineSetting} width="25px" height="25px" />,
      collapse: true,
      items: [
        {
          name: "Team Members",
          layout: "/account",
          path: "/team",
          component: <AccountTeam />,
          secondary: true,
          showToMenu: true,
          icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
        },
        {
          name: "Profile",
          layout: "/account",
          path: "/profile",
          component: <BusinessProfile />,
          secondary: true,
          showToMenu: true,
          icon: <Icon as={AiOutlineUser} width="20px" height="20px" />,
        },
        {
          name: "Application settings",
          layout: "/account",
          path: "/applicationsettings",
          component: <ApplicationSettings />,
          secondary: true,
          showToMenu: true,
          icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
        },
        {
          name: "Account Design",
          layout: "/account",
          path: "/applicationdesign",
          component: <AccountDesign />,
          secondary: true,
          showToMenu: true,
          icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
        },
        {
          name: "Domain setup",
          layout: "/account",
          path: "/domainsetup",
          component: <SubDomainSetup/>,
          secondary: true,
          showToMenu: true,
          icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
        }
      ],
    },
    {
      name: "Help",
      path: "/account",
      icon: <Icon as={AiOutlineGift} width="25px" height="25px" />,
      collapse: true,
      items: [
        {
          name: "Get Help",
          layout: "/account",
          path: "/help",
          component: <Helper />,
          secondary: true,
          showToMenu: true,
          icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
        },
        {
          name: "Trust in Platform",
          layout: "/account",
          path: "/trust",
          component: <PlatformTrust />,
          secondary: true,
          showToMenu: true,
          icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
        },
      ],
    },
  ]).filter(item => !item.isHidden)
}

export default accountRoutes;
