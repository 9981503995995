// React Chakra Imports
import React from "react";
import {
	Box,
	Flex,
	Text,
	Button,
	Icon,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
} from "@chakra-ui/react";
import { AiOutlineMail, AiOutlineBlock } from "react-icons/ai";

// Component Imports
import TrustControl from "./TrustControl";
import TrustOverview from "./TrustOverview";
import TrustResource from "./TrustResource";
import TrustSubprocessor from "./TrustSubprocessor";

function TrustCenter() {
	return (
		<Box>
			<Box
				borderWidth={"2px"}
				bboxShadow="md"
				p="3"
				borderRadius={"10px"}
			>
				<Flex alignItems={"center"} justifyContent={"space-between"}>
					<Text fontSize={"18px"} fontWeight={500}>
						Trust Center
					</Text>
					<Button size="sm" borderRadius="5px" colorScheme="purple">
						Request Access
					</Button>
				</Flex>
				<Text fontSize={"24px"} my="4" fontWeight={600}>
					LingoLet
				</Text>
				<Text fontSize={"16px"} mb="2" fontWeight={400}>
					Lingolet AI is a scalable software platform built to assist Language Service Providers (LSP) worldwide. .
				</Text>
				<Flex alignItems={"center"}>
					<Icon as={AiOutlineMail} mr="2"></Icon>
					<Text>support@lingolet.ai</Text>
				</Flex>
				<Flex alignItems={"center"}>
					<Icon as={AiOutlineBlock} mr="2"></Icon>
					<Text>Privacy Policy</Text>
				</Flex>
			</Box>
			<Box mt="5">
				<Tabs>
					<TabList>
						<Tab>Overview</Tab>
						<Tab>Resouces</Tab>
						<Tab>Controls</Tab>
						<Tab>Subprocessors</Tab>
					</TabList>

					<TabPanels>
						<TabPanel>
							<TrustOverview />
						</TabPanel>
						<TabPanel>
							<TrustResource />
						</TabPanel>
						<TabPanel>
							<TrustControl />
						</TabPanel>
						<TabPanel>
							<TrustSubprocessor />
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Box>
		</Box>
	);
}

export default TrustCenter;
