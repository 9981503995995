import React, { useState } from 'react'
// Chakra Import
import {
    Box,
    Flex,
    Text,
    Button,
    Drawer,
    RadioGroup,
    Stack,
    Radio,
    FormLabel,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Textarea,
    useToast,
    Checkbox,
} from "@chakra-ui/react";

// Component Imports
import { useBlueButton } from '../../../theme/globalColorTheme';
import { toastFunctionToaster } from '../../../utils/toastFunction';

// Api Services
import { shareSubLingoLink } from '../../../services/accountServices';
import TagsInput from 'react-tagsinput';


function LinkEmailWidget(props) {
    const toast = useToast()
    const btnRef = React.useRef()
    const subscriberEmail = props?.subscriberEmail ? props?.subscriberEmail : null;
    const accountUuid = props?.accountId ? props?.accountId : null
    const locationUuid = props?.locationId ? props?.locationId : null
    const linkInfo = props?.linkItem
    const blueButtonBg = useBlueButton();
    const [urlGroup, setUrlGroup] = useState('10');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [sendCode, setSendCode] = React.useState(false);
    const [saveDetailsLoader, setSaveDetailsLoader] = useState(false);

    const [toEmails, setToEmails] = useState(subscriberEmail ? [subscriberEmail] : []);
    const currentHost = window.location.hostname.includes('localhost') ? 'https://lingolet.ai/' : window.location.protocol + "//" + window.location.hostname + "/";


    // Todo:Send link to the customer
    const shareSelectedCustomerLink = () => {
        setSaveDetailsLoader(true)
        if (toEmails?.length > 0 && linkInfo?.uuid != null && (urlGroup === '11' || urlGroup === '10')) {
            if (accountUuid != null && locationUuid != null) {
                let objBody = {
                    link_account_uuid: accountUuid,
                    link_location_uuid: locationUuid,
                    target_emails: toEmails.join(","),
                    link_uuid: linkInfo?.uuid,
                    host_name: currentHost,
                    url_group: urlGroup,
                    is_code: sendCode,
                };
                shareSubLingoLink(objBody)
                    .then((res) => {
                        setSaveDetailsLoader(false)
                        if (res?.result) {
                            toast(toastFunctionToaster(res.message, "success"));
                        } else {
                            toast(toastFunctionToaster(res.message, "error"));
                        }
                    })
                    .catch((err) => {
                        setSaveDetailsLoader(false)
                        toast(toastFunctionToaster(err.message, "error"));
                    });
            } else {
                setSaveDetailsLoader(false)
                toast(
                    toastFunctionToaster(
                        "You must provide email addresse and select which url to share..",
                        "error"
                    )
                );
            }
        }
        else {
            setSaveDetailsLoader(false)
        }
    };

    return (
        <>
            <Button justifyContent={'start'} ref={btnRef} w='100%' size="sm" bg={blueButtonBg} borderRadius="0" onClick={onOpen}>Email / ShareLink to Subscriber</Button>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                finalFocusRef={btnRef}
                size="md"
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerBody p="0px">
                        {Object.keys(linkInfo)?.length
                            ? <Flex w={"100%"} mt={2} h="98%">
                                <Flex w="100%"
                                    direction={'column'} overflow={'hidden'}>
                                    <Flex w={'100%'} direction={'column'} borderBottomWidth={'1px'} borderColor="grey.200" p="3">
                                        <Text fontSize={'lg'} fontWeight={'600'}>{"Send LingoLink to Subscribers"}</Text>
                                        <DrawerCloseButton />
                                    </Flex>
                                    <Box p="3" h="95%">
                                        <Text mt={5} mb="2" fontSize={"md"}>
                                            Please select which URL you want to share in  this email:
                                        </Text>
                                        <RadioGroup onChange={setUrlGroup} value={urlGroup}>
                                            <Stack direction='column'>
                                                <Radio value={'10'}>{currentHost + linkInfo.url}</Radio>
                                                <Radio value={'11'}>{currentHost + linkInfo.fullurl}</Radio>
                                            </Stack>
                                        </RadioGroup>
                                        <Text mb="2" fontSize={"md"} color={"blue.500"}>

                                        </Text>
                                        <FormLabel mt="3" ml="2" required>
                                            <Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
                                                To Email Address(es):
                                            </Text>
                                            
                                            <TagsInput
                                                maxTags={100}
                                                style={{ width: '100%' }}
                                                value={toEmails}
                                                onChange={tags => setToEmails(tags)}
                                                inputProps={{ placeholder: 'Enter Email' }}
                                            />
                                            <Text
                                                textAlign={"right"}
                                                fontWeight={"500"}
                                                fontSize={"sm"}
                                                mt="2"
                                            >
                                                {toEmails?.length}/200
                                            </Text>
                                            <Text>
                                                Note: An email will be sent to all above emails address with the
                                                link
                                            </Text>
                                        </FormLabel>
                                        <FormLabel mt="3" ml="2" required>
                                            <Flex w={"100%"}>
                                                <Checkbox
                                                    onChange={() => setSendCode(!sendCode)}
                                                    checked={sendCode}
                                                ></Checkbox>
                                                <Text ml={1}>
                                                    Do you want to add Link Access Code with Email too?
                                                </Text>
                                            </Flex>
                                        </FormLabel>
                                    </Box>
                                    <Flex w={'100%'} mt="4" alignItems={'center'} p="3" borderTopWidth={'1px'} borderColor="grey.200">
                                        <Button
                                            bg={blueButtonBg}
                                            borderRadius={'10px'}
                                            size="sm"
                                            mr="2"
                                            onClick={() => {
                                                shareSelectedCustomerLink();
                                            }}
                                            isDisabled={
                                                toEmails === null || toEmails?.length === 0 ? true : false
                                            }
                                            isLoading={saveDetailsLoader ? true : false}
                                        >
                                            Send Email
                                        </Button>

                                        <Button variant='outline' borderRadius={'10px'} colorScheme='red' size="sm" onClick={onClose}>
                                            Close
                                        </Button>
                                    </Flex>

                                </Flex>
                            </Flex>
                            : <Flex w={'100%'} p={2} bg={'red.100'}>
                                <Text>Error collecting Selected Link Details</Text>
                            </Flex>}
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default LinkEmailWidget