import { Box, Button, Card, Flex, Menu, MenuButton, MenuItem, MenuList, Select, Spacer, Text } from '@chakra-ui/react';
import React from 'react'
import ResponsivePagination from "react-responsive-pagination";
import { useGradientColor, useTextColor } from '../../theme/globalColorTheme';
import { PAGINATION_OPTIONS } from './constants';
import { ChevronDownIcon } from '@chakra-ui/icons';



const Pagination = ({currentPage, totalPages, setCurrentPage,setRecordsPerPage,recordsPerPage}) => {
    const optionsText = useTextColor()
    const gradientColor = useGradientColor()
	const handleChange = (value) => {
		setRecordsPerPage(value)
	}

  return (
    <Card w={"100%"} borderRadius='10px' mt={3} p="10px" borderWidth={'1px'} borderColor={gradientColor}>
					<Flex w={"100%"}alignItems="center">
						<Box minW={"30%"}>
							<Flex width="100%" alignItems="center">
									<Text minW={'40%'} mr='5px'>Records per page</Text>
									<Menu>
										<MenuButton as={Button} rightIcon={<ChevronDownIcon />} px={2} py={0} colorScheme='blue' h="30px" pl={3}>
											{recordsPerPage}
										</MenuButton>
										<MenuList>
										{PAGINATION_OPTIONS.map(item => (<MenuItem value={String(item)} color={optionsText} onClick={()=>setRecordsPerPage(item)}>{item}</MenuItem>))}
										</MenuList>
									</Menu>
									{/* <Select maxW={'30%'} style={{color:optionsText}} onChange={value=>{handleChange(value.target.value)}} value={recordsPerPage}>
										{PAGINATION_OPTIONS.map(item => (<option value={String(item)} style={{color:optionsText}}>{item}</option>))}
									</Select> */}
							</Flex>
						</Box>
						<Spacer />
						<Box>
							<ResponsivePagination
								current={currentPage}
								total={totalPages}
								onPageChange={pageNumber => {
									setCurrentPage(pageNumber);
								}}
							/>
						</Box>
					</Flex>
				</Card>
  )
}

export default Pagination