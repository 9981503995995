import {
	Alert,
	AlertIcon,
	Box,
	Button,
	Card,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	FormControl,
	FormHelperText,
	FormLabel,
	Icon,
	Input,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Slider,
	SliderFilledTrack,
	SliderMark,
	SliderThumb,
	SliderTrack,
	Spacer,
	Text,
	Textarea,
	Tooltip,
	useColorModeValue,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { AiFillLeftCircle, AiFillRightCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { useBlackWhiteBg, useBlueButton, useBrandBg, useGreenBg, usePillButtonBg, useRedButton, useTextColor, useWhiteLightdarkBg, useYellowButton } from "../../theme/globalColorTheme";
import { SCHEDULE_CONTENT_TYPE, SCHEDULE_DETAILS, SCHEDULE_FREQUENCY, SCHEDULE_SERVICE_TYPES, SCHEDULE_STATUS, SIDEBAR_TYPES } from "./links/constants";
import { cancelSubSchedule, createSubSchedule, fetchAccLocInfo, getSubScheduleList, shareSubSchedule, updateSubSchedule } from "../../services/accountServices";
import { toastFunctionToaster } from "../../utils/toastFunction";
import Pagination from "../../components/pagination/Pagination";
import { ChevronDownIcon } from "@chakra-ui/icons";

const SubscriberSchedule = () => {
	const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(false);
	const [errors, setErrors] = useState({});
	const [showTooltip, setShowTooltip] = React.useState(false);
	const brandBg = useBrandBg();
	const blackWhiteBg = useBlackWhiteBg();
	const [accountuuid, setAccountUuid] = useState("");
	const [locationUuid, setLocationUuid] = useState("");
	const toast = useToast();
	const [totalPages, setTotalPages] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [recordsPerPage, setRecordsPerPage] = useState(5);
	const [scheduleList, setScheduleList] = useState([]);
	const [btnLoading, setButtonLoading] = useState({
		createSchedule: false,
	});
	const [cancelBtnLoading, setCancelBtnLoading] = useState("");

	const [cardFilter, setCardFilter] = useState(SCHEDULE_STATUS.OPEN);
	const [sideBarType, setSideBarType] = useState("create");
	const [initialDetails, setInitialDetails] = useState({});
	const [isDisableUpdate, setIsDisableUpdate] = useState(true);
	const [activeCardSchedule, setActiveCardSchedule] = useState(null);
	const [toEmails, setToEmails] = useState("");
	const [shareMessage, setShareMessage] = useState("");
	const redBg = useRedButton();
	const greenBg = useGreenBg();
	const yellowBg = useYellowButton();
	const blueButtonBg = useBlueButton();
	const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
	const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
	const whiteAndLightDarkColor = useWhiteLightdarkBg()
	const blueColor = useBlueButton()
	const blackWhiteColor = useTextColor()
	const pillButtonBg = usePillButtonBg()


	const [formData, setFormData] = useState(SCHEDULE_DETAILS);

	const handeCreateSchedule = e => {
		e.preventDefault();
		if (!validateForm()) {
			const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
			const paramObj = {
				link_account_uuid: accountuuid,
				link_location_uuid: locationUuid,
				request_timezone: userTimezone,
				...formData,
			};
			setButtonLoading(data => ({ ...data, createSchedule: true }));
			createSubSchedule(paramObj)
				.then(resp => {
					if (resp.data[0]?.status) {
						setConfiguationCollapseFlag(false);
						toast(toastFunctionToaster("Successfully Added Schedule", "success"));
						getScheduleList();
					} else {
						toast(toastFunctionToaster("Failed to Add Schedule", "error"));
					}
					setButtonLoading(data => ({ ...data, createSchedule: false }));
				})
				.catch(err => {
					setButtonLoading(data => ({ ...data, createSchedule: false }));
					toast(toastFunctionToaster("Failed to Add Schedule", "error"));
				});
		}
	};

	const handleOnValueChange = e => {
		const { name, value } = e.target;

		setFormData(data => {
			const updatedData = { ...data, [name]: value };
			if (sideBarType === SIDEBAR_TYPES.UPDATE) {
				setIsDisableUpdate(JSON.stringify(initialDetails) === JSON.stringify(updatedData));
			}

			return updatedData;
		});
	};

	const handleUpdateSchedule = () => {
		if (validateForm) {
			setButtonLoading(data => ({ ...data, createSchedule: true }));
			const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
			const paramObj = {
				...formData,
				link_account_uuid: accountuuid,
				link_location_uuid: locationUuid,
				request_timezone: userTimezone,
			};
			updateSubSchedule(paramObj)
				.then(resp => {
					if (resp.data[0]?.status) {
						toast(toastFunctionToaster("Successfully Updated Schedule", "success"));
						handleCloseRightTab();
						getScheduleList();
					} else {
						toast(toastFunctionToaster("Failed to Updated Schedule", "error"));
					}
					setButtonLoading(data => ({ ...data, createSchedule: false }));
				})
				.catch(err => {
					toast(toastFunctionToaster("Failed to Updated Schedule", "error"));
					setButtonLoading(data => ({ ...data, createSchedule: false }));
				});
		}
	};

	const handleOpenModal = card => {
		onModalOpen()
		setActiveCardSchedule(card);
	};

	const validateForm = () => {
		let foundErrors = false;
		for (const [key, value] of Object.entries(formData)) {
			if (!value) {
				foundErrors = true;
				setErrors(errors => {
					return { ...errors, [key]: "This field is required" };
				});
			} else {
				delete errors[key];
			}
		}
		return foundErrors;
	};

	const fetchSubscriberAccAndLocDetails = () => {
		const paramObj = { key: "subscriber" };
		fetchAccLocInfo(paramObj)
			.then(resp => {
				if (resp?.data[0]?.status) {
					setAccountUuid(resp?.data[0]?.accUuid);
					setLocationUuid(resp?.data[0]?.locUuid);
				} else {
					setAccountUuid("");
					setLocationUuid("");
				}
			})
			.catch(err => {
				setAccountUuid("");
				setLocationUuid("");
			});
	};

	const getScheduleList = () => {
		const paramObj = {
			link_account_uuid: accountuuid,
			link_location_uuid: locationUuid,
			schedule_status: cardFilter,
			records_per_page: recordsPerPage,
			page_id: currentPage,
		};

		getSubScheduleList(paramObj)
			.then(resp => {
				if (resp?.data[0]?.total_records) {
					setScheduleList(resp?.data[0]?.records);
					setTotalPages(resp?.data[0]?.total_pages);
				} else {
					setScheduleList([]);
					setTotalPages(0);
				}
			})
			.catch(err => {
				setScheduleList([]);
				setTotalPages(0);
			});
	};

	const handleOpenRightSideBar = (type, cardDetails) => {
		if (type === SIDEBAR_TYPES.CREATE) {
			setSideBarType(type);
			setIsDisableUpdate(false);
			setFormData(SCHEDULE_DETAILS);
			setConfiguationCollapseFlag(true);
		} else {
			setIsDisableUpdate(true);
			setSideBarType(type);
			setFormData({ ...cardDetails, request_duration: cardDetails.requested_duration });
			setInitialDetails({ ...cardDetails, request_duration: cardDetails.requested_duration });
			setConfiguationCollapseFlag(true);
		}
	};

	const handleCloseRightTab = () => {
		setIsDisableUpdate(false);
		setSideBarType(SIDEBAR_TYPES.CREATE);
		setFormData(SCHEDULE_DETAILS);
		setConfiguationCollapseFlag(!configurationCollapseFlag);
	};

	const handleModalClose = () => {

		handleCancelSchedule(activeCardSchedule?.schedule_uuid);
		onModalClose();
	};

	const handleCancelSchedule = uuid => {
		if (uuid !== null){
			const paramObj = {
				link_account_uuid: accountuuid,
				link_location_uuid: locationUuid,
				schedule_uuid: uuid,
			};
			setCancelBtnLoading(uuid);
			cancelSubSchedule(paramObj)
				.then(resp => {
					if (resp.data[0]?.status) {
						toast(toastFunctionToaster("Successfully cancelled Schedule", "success"));
						getScheduleList();
					} else {
						toast(toastFunctionToaster("Failed to cancel Schedule", "error"));
					}
					setCancelBtnLoading("");
				})
				.catch(err => {
					toast(toastFunctionToaster("Failed to cancel Schedule", "error"));
					setCancelBtnLoading("");
				});
			} else {
			toast(toastFunctionToaster("Failed to cancel Schedule", "error"));
		}
	};

	const handleOpenDrawer = schedule => {
		setActiveCardSchedule(schedule);
		onDrawerOpen();
	};

	const handleCloseDrawer = () => {
		setActiveCardSchedule(null);
		setToEmails("");
		setShareMessage("");
		onDrawerClose();
	};

	function shareSchedule() {
		const paramObj = {
			link_account_uuid: accountuuid,
			link_location_uuid: locationUuid,
			schedule_uuid: activeCardSchedule.schedule_uuid,
			target_emails: toEmails,
			card_message: shareMessage,
		};

		shareSubSchedule(paramObj)
			.then(resp => {
				if (resp.data[0]?.status) {
					toast(toastFunctionToaster("Succesfully shared schedule", "success"));
					handleCloseDrawer();
				} else {
					toast(toastFunctionToaster("Failed to shared schedule", "error"));
				}
			})
			.catch(err => {
				toast(toastFunctionToaster("Failed to shared schedule", "error"));
			});
	}

	useEffect(() => {
		fetchSubscriberAccAndLocDetails();
	}, []);

	useEffect(() => {
		if (accountuuid && locationUuid) {
			getScheduleList();
		}
	}, [accountuuid, locationUuid, recordsPerPage, currentPage, cardFilter]);

	useEffect(() => {
		setCurrentPage(1);
	}, [recordsPerPage]);

	return (
		<Flex direction="column" pt={{ sm: "125px", lg: "75px" }} mt="4">
			<Card w={"100%"} rounded={"md"} p={1} mb={2}>
				<Flex p="2" alignItems="center" justifyContent={"space-between"}>
					<Text fontWeight={"500"} fontSize={"18px"} _firstLetter={{ textTransform: "capitalize" }}>
						{cardFilter} LingoCards: {scheduleList.length}
					</Text>
					<Spacer />
					<Flex align={"center"}>
						<Box
							p={1}
							align={"center"}
							w={"100px"}
							_hover={{ cursor: "pointer" }}
							roundedLeft={"lg"}
							bg={cardFilter === SCHEDULE_STATUS.OPEN ? "green.600" : greenBg}
							color={cardFilter === SCHEDULE_STATUS.OPEN ? "white" : "inherit"}
							size="sm"
							onClick={() => {
								setCardFilter(SCHEDULE_STATUS.OPEN);
							}}>
							{cardFilter === SCHEDULE_STATUS.OPEN ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
							OPEN
						</Box>
						<Box
							p={1}
							bg={cardFilter === SCHEDULE_STATUS.CLOSED ? "red.600" : redBg}
							color={cardFilter === SCHEDULE_STATUS.CLOSED ? "white" : "inherit"}
							align={"center"}
							w={"100px"}
							size="sm"
							_hover={{ cursor: "pointer" }}
							onClick={() => {
								setCardFilter(SCHEDULE_STATUS.CLOSED);
							}}>
							{cardFilter === SCHEDULE_STATUS.CLOSED ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
							CLOSED
						</Box>
						<Box
							p={1}
							bg={cardFilter === SCHEDULE_STATUS.CANCELED ? "yellow.600" : yellowBg}
							color={cardFilter === SCHEDULE_STATUS.CANCELED ? "white" : "inherit"}
							align={"center"}
							w={"110px"}
							size="sm"
							_hover={{ cursor: "pointer" }}
							roundedRight={"lg"}
							onClick={() => {
								setCardFilter(SCHEDULE_STATUS.CANCELED);
							}}>
							{cardFilter === SCHEDULE_STATUS.CANCELED ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
							CANCELED
						</Box>
						<Button
							colorScheme="blue"
							size="sm"
							borderRadius="lg"
							p={1}
							ml={4}
							onClick={() => handleOpenRightSideBar("create")}>
							Create New Schedule
						</Button>
					</Flex>
				</Flex>
			</Card>
			<Card w={"100%"} rounded={"md"} p="1px">
				<Flex h={'calc(100vh - 200px)'} w="100%">
					<Box
						w={!configurationCollapseFlag ? "95%" : "70%"}
						bg={brandBg}
						p="1"
						borderRadius={"10px"}
						overflow="hidden">
						<Card w={"100%"} p={2} rounded={"md"} h="full">
							<Box w={'100%'} h={scheduleList?.length ? 'calc(100vh - 220px)' : 'calc(100vh - 150px)'}>
								{scheduleList?.length ? (
									scheduleList.map(schedule => (
										<Card w="100%" borderRadius="md" mb={2} p={4} bg={whiteAndLightDarkColor}>
											<Flex flexDir="column" gap={2}>
												<Flex>
													<Box
														p={2}
														bg={cardFilter === SCHEDULE_STATUS.OPEN ? greenBg : redBg}
														borderRadius="md">
														<Text
															fontWeight={"500"}
															fontSize={"18px"}
															_firstLetter={{ textTransform: "capitalize" }}>
															{schedule?.service_type}
														</Text>
													</Box>
													<Spacer />
													{cardFilter === SCHEDULE_STATUS.OPEN && (
														<Flex>
															<Button
																ml={1}
																bg={blueColor}
																size="sm"
																onClick={() =>
																	handleOpenRightSideBar(SIDEBAR_TYPES.UPDATE, schedule)
																}>
																Update
															</Button>
															<Button
																ml={1}
																bg={blueColor}
																size="sm"
																onClick={() => handleOpenModal(schedule)}
																isLoading={cancelBtnLoading === schedule.schedule_uuid}>
																Cancel
															</Button>
															<Button
																ml={1}
																bg={blueColor}
																size="sm"
																onClick={() => handleOpenDrawer(schedule)}>
																Share
															</Button>
														</Flex>
													)}
												</Flex>
												<Flex gap={4}>
													<Box>
														<Text>
															Content-Type:{" "}
															<Text as="span" fontWeight="bold">
																{schedule?.content_type}
															</Text>
														</Text>
													</Box>
													<Box>
														<Text>
															Duration:{" "}
															<Text as="span" fontWeight="bold">
																{schedule?.requested_duration} min
															</Text>
														</Text>
													</Box>
												</Flex>
												<Flex gap={4}>
													<Box>
														<Text>
															Requested Date:{" "}
															<Text as="span" fontWeight="bold">
																{schedule?.requested_date}
															</Text>
														</Text>
													</Box>
													<Box>
														<Text>
															Requested Time:{" "}
															<Text as="span" fontWeight="bold">
																{schedule?.requested_time}
															</Text>
														</Text>
													</Box>
													<Box>
														<Text>
															Frequency:{" "}
															<Text as="span" fontWeight="bold">
																{schedule?.request_frequency}
															</Text>
														</Text>
													</Box>
												</Flex>
												<Flex></Flex>
											</Flex>
										</Card>
									))
								) : (
									<Alert status="warning" mt="4" rounded={'lg'}>
										<AlertIcon />
										<Text>You dont have any {cardFilter} Schedules</Text>
										<Text
											ml={5}
											_hover={{ cursor: "pointer", textDecoration: "underline" }}
											onClick={() => setConfiguationCollapseFlag(true)}>
											<b>CREATE NEW Schedule!!</b>
										</Text>
									</Alert>
								)}
							</Box>
							{scheduleList.length ? (
								<Pagination
									currentPage={currentPage}
									setCurrentPage={setCurrentPage}
									setRecordsPerPage={setRecordsPerPage}
									totalPages={totalPages}
									recordsPerPage={recordsPerPage}
								/>
							) : null}
						</Card>
					</Box>
					<Modal isOpen={isModalOpen} onClose={onModalClose}>
						<ModalOverlay />
						<ModalContent>
							<ModalHeader>Cancel Schedule</ModalHeader>
							<ModalCloseButton />
							<ModalBody>
								<Text>Are you sure you want to cancel this schedule? </Text>
							</ModalBody>

							<ModalFooter gap={4}>
								<Button colorScheme="red" onClick={handleModalClose}>
									Yes
								</Button>
								<Button colorScheme="blue" mr={3} onClick={onModalClose}>
									Close
								</Button>
							</ModalFooter>
						</ModalContent>
					</Modal>

					<Drawer isOpen={isDrawerOpen} placement="right" onClose={handleCloseDrawer} size="md">
						<DrawerOverlay />
						<DrawerContent>
							<DrawerCloseButton />
							<DrawerHeader borderBottomWidth={"1px"}>Share Schedule</DrawerHeader>
							<DrawerBody overflow="hidden" overflowY={"scroll"} mt="3">
								<Box p="3" h="95%">
									<FormLabel mt="3" ml="2" required>
										<Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
											To Email Address(es):
										</Text>
										<Textarea
											mb="0"
											me="30px"
											rows="10"
											id="toEmails"
											value={toEmails}
											name="toEmails"
											onChange={e => {
												setToEmails(e.target.value);
											}}
											placeholder="Enter emails (comma separated)"
											w="100%"
											maxlength={200}
										/>
										<Text textAlign={"right"} fontWeight={"500"} fontSize={"sm"} mt="2">
											{toEmails?.length || 0}/200
										</Text>
									</FormLabel>

									<FormLabel mt="3" ml="2" required>
										<Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
											Message:
										</Text>
										<Textarea
											mb="0"
											me="30px"
											rows="10"
											id="shareMessage"
											value={shareMessage}
											name="cardShareMessage"
											onChange={e => {
												setShareMessage(e.target.value);
											}}
											placeholder="Enter message"
											w="100%"
											maxlength={500}
										/>
										<Text textAlign={"right"} fontWeight={"500"} fontSize={"sm"} mt="2">
											{shareMessage?.length || 0}/500
										</Text>
									</FormLabel>
									<Text>
										Note: An email will be sent to all above emails address with the message
									</Text>
								</Box>
							</DrawerBody>
							<DrawerFooter>
								<Flex
									w={"100%"}
									mt="4"
									alignItems={"center"}
									p="3"
									borderTopWidth={"1px"}
									borderColor="grey.200">
									<Button
										bg={blueButtonBg}
										borderRadius={"10px"}
										size="sm"
										mr="2"
										onClick={shareSchedule}
										isDisabled={!toEmails || !shareMessage}
										// isLoading={saveCardLoader ? true : false}
									>
										Send Email
									</Button>

									<Button
										variant="outline"
										borderRadius={"10px"}
										colorScheme="red"
										size="sm"
										onClick={handleCloseDrawer}>
										Close
									</Button>
								</Flex>
							</DrawerFooter>
						</DrawerContent>
					</Drawer>
					{configurationCollapseFlag ? (
						<Flex minW={"305px"} maxW={"605px"} overflow={"hidden"} overflowY={"auto"} flex={1}>
							<Box minW="97%" position="relative" p="3" borderRadius="15px" bg={brandBg} ml="2">
								<Text fontWeight={"500"}>{sideBarType} Schedule</Text>
								<Box w={"100%"} align={"center"}></Box>
								<Box position="absolute" top="0" right="0" mr="2" mt="3" zIndex="999">
									<AiFillRightCircle
										cursor="pointer"
										size="20px"
										onClick={() => {
											setConfiguationCollapseFlag(!configurationCollapseFlag);
										}}
									/>
								</Box>
								<Box
									w="100%"
									h="95%"
									bg={blackWhiteBg}
									borderRadius={"10px"}
									mt="3"
									p={2}
									overflowY="auto"
									overflowX="hidden"
									className="custom-scrollbar">
									<form>
										<Flex gap={4} flexDir="column">
											<FormControl>
												<FormLabel>Service Type</FormLabel>
												<Menu>
													<MenuButton as={Button} w="full" rightIcon={<ChevronDownIcon />} variant="outline">
														{formData.service_type}
													</MenuButton>
													<MenuList>
													{
														Object.entries(SCHEDULE_SERVICE_TYPES).map(([objKey, objValue]) => (
															<MenuItem value={objValue} key={objKey}  onClick={()=>handleOnValueChange({target:{name:"service_type", value:objValue}})}>
																{objValue}
															</MenuItem>
														))
													}
													</MenuList>
												</Menu>
												<FormHelperText color="red.300" fontSize="small">
													{errors?.service_type}
												</FormHelperText>
											</FormControl>

											<FormControl>
												<FormLabel>Content Type</FormLabel>
												<Menu>
													<MenuButton as={Button} w="full" rightIcon={<ChevronDownIcon />} variant="outline">
														{formData.content_type}
													</MenuButton>
													<MenuList>
													{
														Object.entries(SCHEDULE_CONTENT_TYPE).map(([objKey, objValue]) => (
															<MenuItem value={objValue} key={objKey}  onClick={()=>handleOnValueChange({target:{name:"content_type", value:objValue}})}>
																{objValue}
															</MenuItem>
														))
													}
													</MenuList>
												</Menu>
												<FormHelperText color="red.300" fontSize="small">
													{errors?.content_type}
												</FormHelperText>
											</FormControl>

											<FormControl>
												<FormLabel>
													Date
													<Text as="span" color="red.200" fontWeight="bold">
														*
													</Text>
												</FormLabel>
												<Input
													color={blackWhiteColor}
													type="date"
													value={formData.requested_date}
													onChange={handleOnValueChange}
													name="requested_date"
													required></Input>
												<FormHelperText color="red.300" fontSize="small">
													{errors?.requested_date}
												</FormHelperText>
											</FormControl>

											<FormControl>
												<FormLabel>
													Time
													<Text as="span" color="red.200" fontWeight="bold">
														*
													</Text>
												</FormLabel>
												<Input
													color={blackWhiteColor}
													type="time"
													value={formData.requested_time}
													onChange={handleOnValueChange}
													name="requested_time"
													required></Input>
												<FormHelperText color="red.300" fontSize="small">
													{errors?.requested_time}
												</FormHelperText>
											</FormControl>

											<FormControl>
												<FormLabel>Frequency</FormLabel>
												<Menu>
													<MenuButton as={Button} w="full" rightIcon={<ChevronDownIcon />} variant="outline">
														{formData.request_frequency}
													</MenuButton>
													<MenuList>
													{
														Object.entries(SCHEDULE_FREQUENCY).map(([objKey, objValue]) => (
															<MenuItem value={objValue} key={objKey}  onClick={()=>handleOnValueChange({target:{name:"request_frequency", value:objValue}})}>
																{objValue}
															</MenuItem>
														))
													}
													</MenuList>
												</Menu>
												
												<FormHelperText color="red.300" fontSize="small">
													{errors?.request_frequency}
												</FormHelperText>
											</FormControl>

											<FormControl>
												<FormLabel>Duration</FormLabel>
												<Box
													px={"1.5"}
													py={4}
													border="1px"
													borderColor="gray.200"
													borderRadius="md">
													<Slider
														id="slider"
														defaultValue={formData.request_duration}
														min={0}
														max={200}
														colorScheme="teal"
														onChange={v =>
															handleOnValueChange({
																target: { name: "request_duration", value: v },
															})
														}
														onMouseEnter={() => setShowTooltip(true)}
														onMouseLeave={() => setShowTooltip(false)}>
														<SliderMark value={50} mt="1" ml="-2.5" fontSize="sm">
															50
														</SliderMark>
														<SliderMark value={100} mt="1" ml="-2.5" fontSize="sm">
															100
														</SliderMark>
														<SliderMark value={150} mt="1" ml="-2.5" fontSize="sm">
															150
														</SliderMark>
														<SliderMark value={200} mt="1" ml="-5" fontSize="sm">
															200
														</SliderMark>
														<SliderTrack bg={pillButtonBg}>
															<SliderFilledTrack />
														</SliderTrack>
														<Tooltip
															hasArrow
															bg="teal.500"
															color="white"
															placement="top"
															isOpen={showTooltip}
															label={`${formData.request_duration}`}>
															<SliderThumb />
														</Tooltip>
													</Slider>
													<FormHelperText color="red.300" fontSize="small">
														{errors?.request_duration}
													</FormHelperText>
												</Box>
											</FormControl>
											<Button
												colorScheme="blue"
												type="submit"
												size="md"
												onClick={
													sideBarType === SIDEBAR_TYPES.CREATE
														? handeCreateSchedule
														: handleUpdateSchedule
												}
												isLoading={btnLoading.createSchedule}
												isDisabled={isDisableUpdate}>
												{sideBarType} Schedule
											</Button>
										</Flex>
									</form>
								</Box>
							</Box>
						</Flex>
					) : (
						<Box w="5%" p="3" borderRadius="10px" bg={brandBg} h="100%" ml="2" pl="5">
							<Box w={"100%"} h="100%">
								<AiFillLeftCircle
									cursor="pointer"
									onClick={() => {
										handleCloseRightTab();
									}}
									size="20px"
								/>
								<Text
									fontSize="16px"
									mt="2"
									css={{
										writingMode: "vertical-lr",
										textOrientation: "mixed",
									}}>
									Create Schedule
								</Text>
							</Box>
						</Box>
					)}
				</Flex>
			</Card>
		</Flex>
	);
};

export default SubscriberSchedule;
