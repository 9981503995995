import axios from "./axios";
import { serviceAPIs } from "./serviceApis";
import { getEmailId } from "../utils/localStorageIndex";

export const login = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().loginApi, data, config);
};

export const getToken = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().tokenApi, data, config);
};

export const logout = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().logoutApi, data, config);
};

export const updatePassword = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().updatePassword, data, config);
};

export const getPageConfig = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().domainConfiguration, data, config);
};

export const updateDefaultPassword = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().updateDefaultPassword, data, config);
};

export const resetPassword = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().resetEmailPassword, data, config);
};

export const googleSignOn = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(
    serviceAPIs().googleLogin + "?token=" + data,
    config
  );
};

export const googleRegister = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(
    serviceAPIs().googleRegister + "?token=" + data,
    config
  );
};

export const verifyUserEmail = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().verifyEmail, data, config);
};

export const reGenerateEmailCode = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().regenerateVerifiedCode, data, config);
};

export const createBusinessUserWithDomain = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().addBusinessUserDomain, data, config);
};

export const fetchPersonalInfoEx = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().userInfoEx, data, config);
};

export const fetchPersonalInfo = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().userInfo, data, config);
};

export const fetchHeadshotUrl = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().uploadHeadshot, data, config);
};

export const updateUserInfo = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.put(serviceAPIs().updateInfo, body, config);
};

export const getAppConf = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().getAppConf, body, config);
};

export const getHelpToc = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.get(serviceAPIs().getToc, body, config);
};

export const getHelpFileContent = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.get(serviceAPIs().getHelpFileContent+`?file_name=${body?.file_name}`, body, config);
};


