import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import jsPDF from "jspdf";

// Chakra Import
import {
    Box,
    Flex,
    Text,
    Button,
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Image,
    DrawerFooter,
} from "@chakra-ui/react";

// Component Imports
import { useHOrange } from "../../../theme/globalColorTheme";
import InputField from "../../../components/fields/InputField";
import lingoLogo from "../../../media/images/Lingolet-2C-large.png";

import QrCodeToImage from "./QrCodeToImage";


function LinkQrPdf(props) {
    const btnRef = React.useRef()
    const [isPdfInProgeress, setIsPdfInProgress] = useState(false)
    const pdfContentRef = React.useRef()
    const [pageHeader, setPageHeader] = useState('Page Heading');
    const [pageSubHeader, setPageSubHeader] = useState('Sub Page Heading');
    const linkInfo = window.location.pathname.includes('subscriber')
    ? props?.linkItem?.fullurl
    : props?.linkItem?.[0]?.fullurl;
    const orangeBg = useHOrange();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const sideMenuStatus = useSelector(store => store.notificationHeader);
    const subscriberLogo = sideMenuStatus?.parentLogo;

    const printQrCode = new jsPDF();
    const generatePdf = () => {
        setIsPdfInProgress(true)
        const content1 = pdfContentRef.current;

        printQrCode.html(content1, {
            callback: function (printQrCode) {
                printQrCode.save('LingoletQR.pdf', {returnPromise:true}).then(()=>{
                    setIsPdfInProgress(false)
                })
            },
            margin: 65,
            html2canvas: {
                scale: 0.2,
                useCORS:true,
            },
            image: {
                type:"png",
                quality: 200
            },
        });
    }
    return (
        <>
            <Button justifyContent={'start'} ref={btnRef} w='100%' size="sm" bg={orangeBg} borderRadius="0" onClick={onOpen}> QRCode PDF</Button>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                finalFocusRef={btnRef}
                size="xl"
            >
                <DrawerOverlay />
                <DrawerCloseButton />
                <DrawerContent>
                    <DrawerBody p="0px">
                        <Box>
                            <Flex justifyContent={'space-between'} alignItems={'center'} borderBottomWidth={'1px'} p="15px">
                                <Text fontWeight={'600'}>Design Page</Text>
                                <Box>
                                    <Button
                                        onClick={() => generatePdf()}
                                        colorScheme='blue' mr='2' size='sm' borderRadius='5px' isLoading={isPdfInProgeress}>Download PDF</Button>
                                </Box>
                            </Flex>
                            <Flex h='90vh' p='20px' justifyContent={'space-between'} mt='3'>
                                <Box w='49%' borderWidth={'1px'} h='100%' borderRadius={'5px'} p='10px'>
                                    <InputField
                                        mb="0"
                                        me="30px"
                                        label="Page Heading (Max 50 Words)"
                                        value={pageHeader}
                                        name="pageHeader"
                                        borderRadius="5px"
                                        onChange={e => {
                                            setPageHeader(e.target.value);
                                        }}
                                        placeholder="Enter Page Heading"
                                        w="100%"
                                        maxlength={50}
                                    />
                                    <Box mt="3">
                                        <InputField
                                            mb="0"
                                            me="30px"
                                            label="Page Sub Heading (Max 100 Words)"
                                            value={pageSubHeader}
                                            name="pageSubHeader"
                                            borderRadius="5px"
                                            onChange={e => {
                                                setPageSubHeader(e.target.value);
                                            }}
                                            placeholder="Enter Page Sub Heading"
                                            w="100%"
                                            maxlength={100}
                                        />
                                    </Box>
                                </Box>
                                <Box w='49%' borderWidth={'1px'} h='100%' borderRadius={'5px'} p='10px'>
                                    <Flex ref={pdfContentRef} direction={'column'} w={'100%'} h={'100%'}>
                                        <Box textAlign={'center'} mb="2rem">
                                            <Image mx='auto' h="100px" mb='2rem' objectFit={'contain'} src={subscriberLogo? subscriberLogo: lingoLogo} />
                                            <Text fontSize='24px' fontWeight='600'>{pageHeader}</Text>
                                            <Text fontSize='20px' fontWeight='500' mt='3'>{pageSubHeader}</Text>
                                        </Box>
                                        <Flex alignItems={'center'} justifyContent={'center'} h='80%' flexWrap={'wrap'}>
                                            <QrCodeToImage
                                                value={linkInfo}
                                            />
                                            <Flex w='100%' justifyContent={'flex-end'} mt="2">
                                                <Flex alignItems={'center'} >
                                                    {subscriberLogo ? (
                                                        <Image mr="2" h="80px" src={subscriberLogo}/>
                                                    ) : null}
                                                    <Box>
                                                        <Text>Powered By</Text>
                                                        <Image h="60px" w='60%' objectFit={'contain'} src={lingoLogo} />
                                                    </Box>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Box>
                            </Flex>
                        </Box>
                    </DrawerBody>
                    <DrawerFooter borderTopWidth={'1px'}>
                        <Button colorScheme="red" size='sm' mr={3} borderRadius={'10px'}
                            onClick={() => { onClose() }}>
                            Close
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default LinkQrPdf