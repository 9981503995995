//used in LinkListing

export const INIT_PAGE = 1
export const INIT_RECORDS_PER_PAGE = 5
export const INIT_CALL_TIME_VALUE = 5
export const INIT_VIDEO_CALL_VALUE = 5
export const INIT_VOICE_CALL_VALUE = 5
export const INIT_DURATION_VALUE = 48
export const INIT_SELECTED_VALUE = ['hi', 'bi']
export const INIT_LINK_CHOICE = 'active'
export const INIT_CURRENT_SELECTION = 'Active'
export const LINGOLINK_TYPES = {
    "NO_LIMIT":"noLimit",
    "SEVEN_DAYS_TRIAL":"sevenDaysTrial",
    "FIXED_DURATION":"fixedDuration"
}

export const LANG_PREF_TYPES = {
    ACTIVE:"active",
    DISABLE:"disabled"
}

export const SIDEBAR_TYPES = {
    CREATE:"create",
    UPDATE:"update"
}