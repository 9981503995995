import { Alert, AlertIcon, Box, Card, Flex, Spinner, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { getAccAssignedDevices } from '../../../services/accountServices'
import { toastFunctionToaster } from '../../../utils/toastFunction'
import Pagination from '../../../components/pagination/Pagination'
import DeviceData from './DeviceData'

const DeviceSupport = () => {
    const [currPage, setCurrPage] = useState(1)
    const [recordsPerPage, setRecordsPerPage] = useState(5)
    const [totalPages, setTotalPages] = useState(1)
    const [devicesListLoading, setDevicesListLoading] = useState(false)
    const [devicesList, setDevicesList] = useState([])

    const toast = useToast()

    function fetchDeviceList() {
        setDevicesListLoading(true)
        const paramObj = {
            page_id: currPage,
            records_per_page: recordsPerPage
        }
        getAccAssignedDevices(paramObj).then(res => {
            if (res?.data[0]?.status) {
                setDevicesList(res?.data[0]?.data)
                setTotalPages(res?.data[0]?.total_pages)
            } else {
                toast(toastFunctionToaster("Failed to get device list", "error"))
                setDevicesList([])
                setTotalPages(0)
            }
        }).catch(err => {
            toast(toastFunctionToaster("Failed to get device list", "error"))
            setDevicesList([])
            setTotalPages(0)
        }).finally(() => {
            setDevicesListLoading(false)
        })
    }

    useEffect(() => {
        fetchDeviceList()
    }, [recordsPerPage, currPage])

    useEffect(() => {
        setCurrPage(1)
    }, [recordsPerPage])
    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }} pl={3}>
            <Card w={'100%'} mt={2}>
                <Flex h="74vh">
                    {
                        devicesListLoading ?
                            (<Flex alignItems="center" justifyContent="center" h="full" w="full">
                                <Spinner size="xl" />
                            </Flex>) : devicesList?.length ? (
                                <Flex w={'100%'} direction={'column'}>
                                    <Flex w={'100%'} bg={'gray.200'} py={2} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
                                        <Box minW={'150px'} fontSize={'xs'} ml={1} pl={2}>
                                            <Text fontSize={'xs'}>Assignment Date</Text>
                                        </Box>
                                        <Box minW={'150px'} fontSize={'xs'} ml={1} pl={2}>
                                            <Text fontSize={'xs'}>Device</Text>
                                        </Box>
                                        <Box minW={'90px'} fontSize={'sm'} ml={1} pl={2}>
                                            <Text fontSize={'xs'}>Locked Status</Text>
                                        </Box>
                                        <Box minW={'155px'} fontSize={'sm'} ml={1} pl={2}>
                                            <Text fontSize={'xs'}>Activation Status</Text>
                                        </Box>
                                        <Box minW={'100px'} fontSize={'sm'} ml={1} pl={2}>
                                            <Text fontSize={'xs'}>Activation Code</Text>
                                        </Box>
                                    </Flex>
                                    <DeviceData devicesList={devicesList} />
                                </Flex>
                            ) : (
                                <Flex w="full" h="fit-content">
                                    <Alert status="warning" mt="4">
                                        <AlertIcon />
                                        No Devices Found
                                    </Alert>
                                </Flex>
                            )
                    }
                </Flex>
            </Card>
            {devicesList.length ? <Pagination currentPage={currPage} recordsPerPage={recordsPerPage} setCurrentPage={setCurrPage} setRecordsPerPage={setRecordsPerPage} totalPages={totalPages} /> : null}
        </Flex>
    )
}

export default DeviceSupport