import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import {
    Box,
    Link,
    Spacer,
    Icon,
    Button,
    FormControl,
    FormLabel,
    PinInput,
    PinInputField,
    Input,
    VStack, Heading, Flex,
    Image,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useToast,
    useDisclosure,
    useColorModeValue
} from "@chakra-ui/react";
import {
    AiOutlineCheckCircle,
    AiOutlineCopy,
    AiOutlineLink,
    AiOutlineMail,
    AiOutlineStop,
    AiOutlineSync
} from "react-icons/ai";
import moment from "moment";

// Api Call Imports

import {
    getAllLinkSessions
} from "../../../../services/businessServices";

// Component Imports
import { toastFunctionToaster } from "../../../../utils/toastFunction";
import { useGrayTextColor, useNoResultFoundText, useBlueButton } from "../../../../theme/globalColorTheme";

const LinkSession = () => {
    const toast = useToast();
    const { state } = useLocation();
    const navigate = useNavigate();
    const btnRef = React.useRef();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [loading, isLoading] = useState(false);

    const notFoundText = useNoResultFoundText()
    const blueButton = useBlueButton()
    const grayText = useGrayTextColor()

    const linkUuid = state?.linkUuid ? state?.linkUuid : null;
    const linkCode = state?.linkCode ? state?.linkCode : null;

    const [linkSessions, setLinkSessions] = useState([]);

    //   Todo:Fetch all projects
    useEffect(() => {
        getLinkSessions();
    }, []);

    const getLinkSessions = () => {
        if (linkUuid != null && linkCode != null) {
            isLoading(true);
            let paramObj = {
                link_uuid: linkUuid,
                link_code: linkCode,
                records_per_page: 0,
                page_id: 0,
            };
            getAllLinkSessions(paramObj)
                .then((res) => {
                    isLoading(false);
                    if (res?.data[0]?.total_records > 0) {
                        setLinkSessions(res?.data[0]?.records);
                    }
                })
                .catch((err) => {
                    isLoading(false);
                });
        }
    };

    const renderSessions = linkSessions !== null && linkSessions?.length > 0
        ? linkSessions?.map((item, index) => (
            <Flex w={'100%'} key={index} p={2} mt={2} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
                <Flex w={'50px'}>
                    <Text >{index + 1}</Text>
                </Flex>
                <Flex w={'250px'}>
                    <Text fontSize={'sm'}>{moment(item?.created).format("MMM DD,YYYY,HH:mm:ss a")}</Text>
                </Flex>
                {/* <Flex w={'250px'}>
                <Text>{moment(item?.now).format("MMM DD,YYYY,HH:mm a")}</Text>
            </Flex> */}
                <Flex w={'250px'} direction={'column'}>
                    <Text>IP: {item?.ip}</Text>
                    <Text>DNS: {item?.dns}</Text>
                </Flex>
                <Flex w={'60px'}>
                    <Text fontWeight={500} color={'red.300'}>{item?.key}</Text>
                </Flex>
                <Flex w={'100%'} direction={'column'}>
                    <Text>{item?.session}</Text>
                </Flex>
                <Flex w={'300px'}>
                    <Text>{item?.time_elapsed}</Text>
                </Flex>
            </Flex>
        ))
        : <Text bg={'red.100'} ml={1} py={3} color={notFoundText}>There are <b>no sessions</b> in this dynamic link</Text>;

    const launchLinkPrefix = () => {
        if (window.location.pathname.includes("/account")) {
            return "/account";
        } else if (window.location.pathname.includes("/business")) {
            return "/business";
        }
        return null;
    };

    return (
        <>
            <Box direction="column" pt={{ sm: "125px", lg: "75px" }}>
                {linkUuid != null && linkCode != null
                    ? <Flex w={'100%'} direction={'column'} align={'center'}>
                        <Flex w={'100%'} direction={'column'}>
                            <Flex
                                w={'100%'}
                                p="3"
                                alignItems="center"
                                borderBottomWidth="1px"
                                justifyContent={"space-between"}
                            >
                                <Text fontWeight={"500"} fontSize={"18px"}>
                                    Dynamic Link Sessions {linkSessions?.length > 0 ? linkSessions?.length : null}
                                </Text>
                                <Spacer />
                                <Button bg="yellow.200" size="sm"
                                    onClick={() => {
                                        getLinkSessions();
                                    }}>
                                    <Icon as={AiOutlineSync} color={grayText} />
                                </Button>
                                <Button ml={1} bg={blueButton} size="sm"
                                    onClick={() => {
                                        navigate(launchLinkPrefix() + "/linkmanager", {
                                            state: {
                                                linkUuid: linkUuid,
                                            },
                                        });
                                    }}>
                                    Back to Link Manager
                                </Button>
                                <Button ml={1} colorScheme="blue" size="sm"
                                    onClick={() => {
                                        navigate(launchLinkPrefix() + "/linklisting", {
                                        });
                                    }}>
                                    Back to LingoLinks
                                </Button>
                            </Flex>
                            {renderSessions}
                        </Flex>
                    </Flex>
                    : <Flex w={'100%'} direction={'column'} align={'center'}>
                        <Heading color="#gray.800" fontSize={"26px"} fontWeight={"normal"}>
                            There is no session data for this dynamic link!!
                        </Heading>
                    </Flex>}
            </Box>
        </>
    );
};

export default LinkSession;
