// Chakra Imports
import React, { useState, useRef, useEffect } from "react";
import {
    Box,
    Flex,
    Button,
    Icon,
    useMediaQuery,
} from "@chakra-ui/react";

// Component Import
import BasicAICommDemo from "./BasicAICommDemo";

// API
import { callStartedEx, callCompletedEx } from "../../../../services/dynamicPageSvc";
import { AiOutlineAudio, AiOutlineVideoCamera } from "react-icons/ai";

const BasicAIInAction = ({ launchKey, launchCode, sessionKey, selectedSettings, basicAITutorialFlag }) => {
    const callProviderCode = 21; // Secure AI

    const localVideoRef = useRef(null); // Ref for local video container
    // const callReadiness = callReadyVal ? callReadyVal : false;
    const [callReadiness, setCallReadiness] = useState(false);
    const [callTrial, setCallTrial] = useState(false);
    const [callData, setCallData] = useState([0, 0, 0, 0]);
    const [currentCall, setCurrentCall] = useState(null);
    const [agentDetails, setAgentDetails] = useState(null);
    const [callUuid, setCallUuid] = useState(null);
    const [hangupFlag, setHangupFlag] = useState(false);
    const [muteUnmuteFlag, setMuteUnmuteFlag] = useState(false);
    const [pauseUnpauseFlag, setPauseUnpauseFlag] = useState(false);
    const [callType, setCallType] = useState("audio");
    const [selectedValue, setSelectedValue] = useState(selectedSettings);
    const localVideoElement = document.getElementById("localVideo");
    const [pauseLayer, setPauseLayer] = useState(false);
    // Get local and remote video elements
    const remoteVideoElement = document.getElementById("remoteVideo");
    const [mediaStream, setMediaStream] = useState(null);
    const [callStarted, setCallStarted] = useState(null);

    // Timer UseEffect
    const [time, setTime] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const intervalRef = useRef();

    // Time Start Stop Function
    const startTimer = () => {
        resetTimer();
        if (!isRunning) {
            setIsRunning(true);
            intervalRef.current = setInterval(() => {
                setTime(prevTime => prevTime + 1);
            }, 1000);
        }
    };

    const stopTimer = () => {
        clearInterval(intervalRef.current);
        setIsRunning(false);
        // Todo:Once call is hangup reset timer
    };

    const resetTimer = () => {
        clearInterval(intervalRef.current);
        setIsRunning(false);
        setTime(0);
    };


    const handleMute = () => {
        if (mediaStream) {
            mediaStream.getAudioTracks().forEach(track => {
                track.enabled = false; // Disable the audio track
            });
            console.log("Audio muted.");
            // updateLogDetails("Audio muted.");
        }
    };

    const handleUnmute = () => {
        // Unmute the audio
        if (mediaStream) {
            mediaStream.getAudioTracks().forEach(track => {
                track.enabled = true; // Enable the audio track
            });
            console.log("Audio unmuted.");
            // updateLogDetails("Audio unmuted.");
        }
    };

    const handlePause = () => {
        // Implement pause logic
        setPauseLayer(true);
        // updateLogDetails("Pause button clicked");
        if (localVideoRef.current) {
            localVideoRef.current.pause();
            console.log(currentCall);
            if (currentCall) {
                currentCall.pauseVideo();
            }
        }
    };

    const handleUnpause = () => {
        // Implement unpause logic
        setPauseLayer(false);
        // updateLogDetails("Unpause button clicked");
        if (localVideoRef.current) {
            localVideoRef.current.play();
        }
    };

    const callStartedProcess = () => {
        if (launchKey != null && sessionKey != null && callType != null && callReadiness) {
            let paramObj = {
                provider: callProviderCode,
                callType: callType,
                launchKey: launchKey,
                launchSession: sessionKey,
                source_language: selectedSettings?.sourceLangName,
                target_language: selectedSettings?.targetLangName,
                agentInfo: agentDetails != null ? JSON.stringify(agentDetails) : "{}",
            };
            callStartedEx(paramObj)
                .then(res => {
                    if (res?.data[0]?.status) {
                        setCallReadiness(res?.data[0]?.ready);
                        if (res?.data[0]?.ready == false) {
                            // updateLogDetails("Call can not be completed as trials period is expired.");
                        } else {
                            // updateLogDetails("Call Started successully with call registration..");
                            if (callType === "audio") {
                                setCallStarted(true);
                            }
                            // DO NOT DELETE THE FOLLOWING LINE (Avkash)
                            handleCall();
                        }
                        setCallUuid(res?.data[0]?.uuid);
                        setCallTrial(res?.data[0]?.calls?.trial);
                        setCallData(res?.data[0]?.calls?.value);
                    }
                })
                .catch(err => {
                    // updateLogDetails("Unable to get call started working...");
                });
        }
    };

    const callCompletedProcess = () => {
        if (launchKey != null && sessionKey != null && callUuid != null) {
            let paramObj = {
                provider: callProviderCode,
                callUuid: callUuid,
                launchKey: launchKey,
                launchSession: sessionKey,
                callLog: "none",
                agentInfo: agentDetails != null ? JSON.stringify(agentDetails) : "{}",
            };
            callCompletedEx(paramObj)
                .then(res => {
                    if (res?.data[0]?.status) {
                        setCallUuid(null);
                        setCallTrial(res?.data[0]?.calls?.trial);
                        setCallData(res?.data[0]?.calls?.value);
                        setCallReadiness(res?.data[0]?.ready);
                        // updateLogDetails("Call stopped successfully with call registration updated..");
                        // updateLogDetails(
                        //     "You have completed " + callType + " call with total call time: " + formatTime(time) + "."
                        // );
                        if (callType === "audio") {
                            setCallStarted(false);
                        }
                    }
                })
                .catch(err => {
                    // updateLogDetails("Unable to get call stopped correctly...");
                });
        }
    };

    const handleCall = () => {

    }


    return (
        <Box h="100%" bg={'gray.100'}>
            <Box>
                {selectedValue ? (
                    <Flex w={"100%"} p={0} m={0}> </Flex>
                ) : null}
            </Box>
            <BasicAICommDemo
                launchKey={launchKey}
                sessionKey={sessionKey}
                launchCode={launchCode}
                callProviderCode={callProviderCode}
                selectedSettings={selectedSettings}
                basicAITutorialFlag={basicAITutorialFlag}
            />
            {callType === 'audio' ? null : (
                <Flex
                    direction={"column"}
                    rounded={"md"}
                    borderColor={"gray.300"}
                    borderWidth={"0.5px"}
                    w={"100%"}
                    h={"80px"}>
                    {" "}

                    {callReadiness ? (
                        <Flex
                            flexWrap="wrap"
                            id="call-controls"
                            mt="1px"
                            align={"center"}
                            justifyContent={"center"}
                            bg="#fff">
                            {!hangupFlag ? (
                                <Button
                                    id="button-call"
                                    onClick={() => {
                                        callStartedProcess();
                                        setHangupFlag(true);
                                        startTimer();
                                    }}
                                    mr="2"
                                    colorScheme="green"
                                    size="sm"
                                    rounded="none"
                                    mt="2px">
                                    {callType === "audio" ? (
                                        <Icon as={AiOutlineAudio} mr={1} />
                                    ) : (
                                        <Icon as={AiOutlineVideoCamera} mr={1} />
                                    )}{" "}
                                    Call
                                </Button>
                            ) : null}
                            {hangupFlag ? (
                                <Button
                                    id="button-hangup"
                                    // onClick={handleHangupCall}
                                    onClick={() => {
                                        callCompletedProcess();
                                        setHangupFlag(false);
                                        stopTimer();
                                    }}
                                    mr="2"
                                    colorScheme="red"
                                    size="sm"
                                    rounded="none"
                                    mt="2px">
                                    Hangup
                                </Button>
                            ) : null}
                            {!muteUnmuteFlag ? (
                                <Button
                                    id="button-mute"
                                    onClick={() => {
                                        handleMute();
                                        setMuteUnmuteFlag(true);
                                    }}
                                    mr="2"
                                    variant="outline"
                                    colorScheme="red"
                                    size="sm"
                                    rounded="none"
                                    mt="2px">
                                    Mute
                                </Button>
                            ) : null}
                            {muteUnmuteFlag ? (
                                <Button
                                    id="button-unmute"
                                    onClick={() => {
                                        handleUnmute();
                                        setMuteUnmuteFlag(false);
                                    }}
                                    mr="2"
                                    variant="outline"
                                    colorScheme="green"
                                    size="sm"
                                    rounded="none"
                                    mt="2px">
                                    Unmute
                                </Button>
                            ) : null}
                            {callType === "video" ? (
                                <>
                                    {!pauseUnpauseFlag ? (
                                        <Button
                                            id="button-pause"
                                            onClick={() => {
                                                handlePause();
                                                setPauseUnpauseFlag(true);
                                            }}
                                            mr="2"
                                            colorScheme="purple"
                                            size="sm"
                                            rounded="none"
                                            mt="2px">
                                            Pause
                                        </Button>
                                    ) : null}
                                    {pauseUnpauseFlag ? (
                                        <Button
                                            id="button-unpause"
                                            onClick={() => {
                                                handleUnpause();
                                                setPauseUnpauseFlag(false);
                                            }}
                                            variant="outline"
                                            colorScheme="purple"
                                            size="sm"
                                            rounded="none"
                                            mt="2px">
                                            Unpause
                                        </Button>
                                    ) : null}
                                </>
                            ) : null}
                        </Flex>
                    ) : null}
                </Flex>)}
        </Box>
    );
};

export default BasicAIInAction;


