import React, { useEffect, useState } from "react";
import moment from "moment";
import {
	Box,
	Button,
	Input,
	Heading,
	Spinner,
	Flex,
	Text,
	Icon,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
} from "@chakra-ui/react";
import {
	AiFillLeftCircle,
	AiFillRightCircle,
	AiTwotoneAudio,
	AiOutlineVideoCamera,
} from "react-icons/ai";

// Component Imports
import { formatTime, formatSeconds } from "../../../utils/TimeFunctions";
import Card from "../../../components/card/Card";

// Api Call Imports
import {
	useBrandBg,
	useGreenBg,
	useRedBg,
} from "../../../theme/globalColorTheme";
import {
	fetchLocationsAndSubs,
	fetchAccountEvents,
	fetchSubscriberLinks,
	fetchSubscriberLinksCallHistory,
} from "../../../services/accountServices";
import Pagination from "../../../components/pagination/Pagination";

const AccountBilling = props => {
	const redBg = useRedBg();
	const greenBg = useGreenBg();
	const [loading, isLoading] = useState(false);
	const brandBg = useBrandBg();

    

    return (
		<Flex direction="column" pt={{ sm: "125px", lg: "75px" }} pl={3}>
			<Card w={"100%"} mt={2}>

			</Card>
		</Flex>
	);
};

export default AccountBilling;
