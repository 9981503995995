// Chakra imports
import { Box, Flex, Text, useColorMode } from "@chakra-ui/react";
// Custom components
import Card from "../../../components/card/Card";
import FixedPlugin from "../../../components/fixedPlugin/FixedPlugin";
import Footer from "../../../components/footer/FooterAuthCentered";
import Navbar from "../../../components/navbar/NavbarAuth";
import PropTypes from "prop-types";

import { useEffect } from "react";

function AuthCentered(props) {
  console.log(props)
  //eslint-disable-next-line
  const { colorMode } = useColorMode();

  const { children, title, description, image, cardTop, cardBottom, pageLogo, pageBackground } = props;

  const custom_logo = props.pageLogo ? props.pageLogo : null;
  const custom_bg = props.pageBackground ? props.pageBackground : null;
  //const current_logo = window.location.hostname.includes('lspcloud') ? true : false;
  const current_logo = true;
  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
      backgroundImage={custom_bg}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="100% 100%"
      mx={{ base: "10px", lg: "0px" }}
      minH="100vh"
    >
      <FixedPlugin />
      <Box
        position="absolute"
        minH={{ base: current_logo ? '10vh' : '50vh', md: current_logo ? '10vh' : '50vh' }}
        maxH={{ base: current_logo ? '10vh' : '50vh', md: current_logo ? '10vh' : '50vh' }}
        w={{ md: "calc(100vw)" }}
        maxW={{ md: "calc(100vw)" }}
        left="0"
        right="0"
        bgRepeat="no-repeat"
        overflow="hidden"
        top="0"
        bgGradient={
          colorMode === "dark"
            ? "linear(to-b, #fff, #fff)"
            : "linear(to-b, blue.200, blue.400)" //"linear(to-b, brand.400, brand.600)"
        }
        mx={{ md: "auto" }}
      />
      <Navbar logo={custom_logo} />
      <Card
        border={'1px'} borderColor={'gray.200'}
        w={{ base: "100%", md: "max-content" }}
        h="max-content"
        mx="auto"
        maxW="100%"
        mt={cardTop}
        mb={cardBottom}
        p={{ base: "10px", md: "50px" }}
        pt={{ base: "10px", md: "30px" }}
        mr={current_logo ? '10px' : null}
      >
        {title && description ? (
          <Flex
            direction="column"
            textAlign="center"
            justifyContent="center"
            align="center"
            mt="25px"
            mb="30px"
          >
            <Text fontSize="4xl" color="white" fontWeight="bold">
              {title}
            </Text>
            <Text
              fontSize="md"
              color="white"
              fontWeight="normal"
              mt="10px"
              mb="26px"
              w={{ base: "90%", sm: "60%", lg: "40%", xl: "333px" }}
            >
              {description}
            </Text>
          </Flex>
        ) : null}
        {children}
      </Card>
      <Footer  confName={props?.confName} confVersion={props?.confVersion}/>
    </Flex>
  );
}
// PROPS

AuthCentered.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.any,
};

export default AuthCentered;