import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Text,
  Flex,
  Box,
  Input,
  Spacer,
  Stack,
  Icon,
  Spinner,
  FormControl,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { MdUpload } from "react-icons/md";
import Card from "../../../../../components/card/Card.js";
import { toastFunctionToaster } from "../../../../../utils/toastFunction";

// API
import axios from "../../../../../services/axios";
import { getEmailId } from "../../../../../utils/localStorageIndex";
import { uploadContentDocument } from "../../../../../services/moduleServices.js";

export default function UploadDocs(props) {
  const toast = useToast();
  const { used, total, ...rest } = props;

  const projectUuid = props.projectUuid ? props.projectUuid : null;

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";
  const dispatch = useDispatch();
  const [loading, isLoading] = useState(true);
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadFileUrl, setUploadFileUrl] = useState(null);
  const emaiId = getEmailId();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [uploadStatus, setUploadStatus] = useState();
  const [uploadStatusLoading, setUploadStatusLoading] = useState(false);

  function postObjectData(s3Data, selectedFile) {
    // dispatch(setProfileLogoFlag(false));
    const { url, fields } = s3Data;
    const payload = new FormData();

    // add all the other fields
    Object.entries(fields).forEach(([key, val]) => {
      payload.append(key, val);
    });
    // payload.append("Content-Type", "image/jpeg");
    // This must be the last line in the payload otherwise 412 error may occur
    // this is the file blob, eg from <input type="file">
    payload.append("file", selectedFile);

    var ax_instance = axios.create();
    delete ax_instance.defaults.headers.common["Authorization"];
    const { data: result } = ax_instance
      .post(url, payload)
      .then((res) => {
        isLoading(false);
        if (res?.data?.length > 0) {
          setUploadStatus("File is uploaded successfully");
          setUploadStatusLoading(false);
          props?.handleChange(0);
          //   dispatch(setProfileLogoFlag(true));
        } else {
          setUploadStatus("File is uploaded successfully");
          setUploadStatusLoading(false);
          props?.handleChange(0);
          //   dispatch(setProfileLogoFlag(true));
        }
      })
      .catch((err) => {
        isLoading(false);
        setUploadStatus("Upload failed with Error :" + err);
      });
  }

  const handleFileChange = (e) => {
    if (e.target.files) {
      if (e.target.files[0].size <= 25000000){ //25MB
        setUploadFile(e.target.files[0]);
        setUploadFileUrl(URL.createObjectURL(e.target.files[0]));
      } else {
        toast(toastFunctionToaster('Selected file size is over 25MB..', "error"));
      }
    }
  };
  const handleSubmitNew = async () => {
    setUploadStatusLoading(true);
    if (uploadFile) {
      let objData = {
        project_uuid: projectUuid,
        content_choice: "resource",
        file_name: uploadFile.name,
        file_type: uploadFile.type,
      };
      setUploadStatus("Getting upload url for the selected file..");
      const response = await uploadContentDocument(objData);
      if (response && response.data && response.data.length > 0) {
        const finalUrl = response.data[0].signedPackage.signedUrl;
        setUploadStatus("Trying to upload the selected file..");
        const s3UploadResult = await postObjectData(finalUrl, uploadFile);
      } else {
        setUploadStatusLoading(false);
        setUploadStatus("Unable to get the upload signed url..");
      }
    }
  };

  const resetFileContent = () => {
    setUploadFile(null);
    setUploadFileUrl(null);
  }

  return (
    <Card {...rest} mb="20px" align="center" p="20px">
      <Box w={"100%"}>
        <FormControl cursor="pointer">
          <Text fontSize={"12px"}>{uploadFileUrl}</Text>
          <Text mb={4} fontSize={"12px"}>
            {uploadFile && `${uploadFile.name} - ${uploadFile.type}`}
          </Text>
          {uploadStatusLoading ? (
            <Flex alignItems="center" justifyContent="center" h="30vh">
              <Spinner size="xl" />
            </Flex>
          ) : (
            <Box
              position="relative"
              height="200px"
              width="100%"
              borderWidth="2px"
              borderColor={brandColor}
              borderRadius={"10px"}
              cursor="pointer"
            >
              <Box
                position="absolute"
                top="0"
                left="0"
                height="100%"
                width="100%"
                display="flex"
                flexDirection="column"
                cursor="pointer"
              >
                <Stack
                  height="100%"
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justify="center"
                  spacing="4"
                  cursor="pointer"
                >
                  <Box textAlign="center">
                    <Icon as={MdUpload} w="80px" h="80px" color={brandColor} />
                    <Flex justify="center" mx="auto" mb="12px">
                      <Text fontSize="xl" fontWeight="700" color={brandColor}>
                        Upload Documents of MP3, MP4, PDF, DOC, TXT types (Each 25MB Max)
                      </Text>
                    </Flex>
                    <Text
                      fontSize="sm"
                      fontWeight="500"
                      color="secondaryGray.500"
                    >
                      MP3, DOCX, PDF, and TXT files are allowed with 25MB maximum file size.
                    </Text>
                  </Box>
                </Stack>
              </Box>
              <Input
                type="file"
                height="100%"
                width="100%"
                position="absolute"
                top="0"
                left="0"
                opacity="0"
                aria-hidden="true"
                accept=".pdf, .docx, .txt, .mp3" // Include .pdf here to accept PDF files
                cursor="pointer"
                onChange={handleFileChange}
              />
            </Box>
          )}
        </FormControl>
      </Box>

      {uploadFile ? (
        <Flex direction={"column"} w={"100%"} p={10} alignItems={"center"}>
          <Box w={"100%"}>
            <Text color="green">{uploadStatus}</Text>
          </Box>
          <Flex w={"100%"} align={"center"}>
            <Button
              me="auto"
              mb="2"
              w="200px"
              variant="brand"
              fontWeight="500"
              onClick={handleSubmitNew}
            >
              Add Resource Document
            </Button>
            <Button
              ml={1}
              me="auto"
              mb="2"
              w="200px"
              variant="brand"
              fontWeight="500"
              onClick={() => resetFileContent()}
            >
              Reset
            </Button>
            <Spacer />
          </Flex>
        </Flex>
      ) : null}
    </Card>
  );
}
