import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// Chakra imports
import {
  Box,
  Tab,
  Flex,
  Tabs,
  Spacer,
  Icon,
  Text,
  Alert,
  Button,
  Spinner,
  TabList,
  AlertIcon,
  TabPanel,
  TabPanels,
  AlertTitle,
  SimpleGrid,
  AlertDescription,
} from "@chakra-ui/react";
import {
  ViewIcon,
  SmallAddIcon,
  AttachmentIcon,
  ChevronLeftIcon,
} from "@chakra-ui/icons";
import {
  BiCheckboxMinus,
  BiSolidPlusSquare,
  BiCheckCircle,
  BiStopCircle,
} from "react-icons/bi";
import moment from "moment";
import { AiFillBuild } from "react-icons/ai";

// Components
import Card from "../../../../components/card/Card";
import UploadDocs from "./components/UploadDocs";
import AddTextContent from "./components/AddTextContent";
import EditableContentList from "./components/EditableContentList";
import { ContentListingHeader } from "./components/ContentListingHeader";
import { translationLanguages } from "../../../../utils/TranslationLanguages";
import { transcriptionLanguages } from "../../../../utils/TranscriptionLanguage";

// API
import { getEmailId } from "../../../../utils/localStorageIndex";
import {
  getProjectContents,
  getProjectActiveJobs,
} from "../../../../services/moduleServices";
import { useBlackWhiteBg } from "../../../../theme/globalColorTheme";

export default function ProjectContent() {
  const { state } = useLocation();
  const emaiId = getEmailId();
  const navigate = useNavigate();
  const [titleShowHideFlag, setTitleShowHideFlag] = useState(false);
  const projectUuid = state?.projectUuid ? state?.projectUuid : null;
  //   const moduleUuid = state.moduleUuid ? state.moduleUuid : props?.moduleId;

  const [tabIndex, setTabIndex] = useState(0);
  const [loading, isLoading] = useState(false);
  const [contentList, setContentList] = useState([]);
  const [activeJobs, setActiveJobs] = useState([]);

  const [reloadResource, setReloadResource] = useState(true);


  useEffect(() => {
    fetchAllContents();
    fetchActiveJobs();
  }, [reloadResource]);

  const fetchAllContents = () => {
    if (projectUuid != null) {
      let objData = {
        project_uuid: projectUuid,
        filter_key: "",
        filter_value: "",
        records_per_page: 10,
        page_id: 0,
      };
      isLoading(true);
      getProjectContents(objData)
        .then((res) => {
          isLoading(false);
          if (res.result) {
            if (res.data.length > 0) {
              setContentList(res.data[0]);
              setReloadResource(false);
              isLoading(false);
            } else {
              setContentList([]);
              setReloadResource(false);
              isLoading(false);
            }
          } else {
            setReloadResource(false);
            isLoading(false);
          }
        })
        .catch((err) => {
          setReloadResource(false);
          isLoading(false);
        });
    }
  };

  const fetchActiveJobs = () => {
    if (projectUuid != null) {
      //&& activeJobs?.length == 0) {
      let objData = {
        project_uuid: projectUuid,
      };
      getProjectActiveJobs(objData)
        .then((res) => {
          isLoading(false);
          if (res.result) {
            if (res.data.length > 0) {
              setActiveJobs(res.data[0]);
            } else {
              setActiveJobs([]);
            }
          }
        })
        .catch((err) => { });
    }
  };

  const reRenderTableData = (data) => {
    fetchAllContents();
  };

  // Todo:Reset tab after module is assigned to associate
  const handleTabsChange = (index) => {
    setTabIndex(index);
    fetchAllContents();
  };

  const reloadResourceData = () => {
    fetchAllContents();
  };

  const getTranslationLanguagesFromCode = (langArr) => {
    let languageList = [];
    let langCodes = langArr.split(",");
    if (langCodes.length > 0) {
      langCodes.map((item, index) => {
        let obj = translationLanguages.find((o) => o.code === item);
        if (obj != null || obj != undefined) {
          languageList.push(obj?.name);
        }
      });
    }
    return languageList;
  };

  const getTranscriptionLanguagesFromCode = (langArr) => {
    let languageList = [];
    let langCodes = langArr.split(",");
    if (langCodes.length > 0) {
      langCodes.map((item, index) => {
        let obj = transcriptionLanguages.find((o) => o.code === item);
        if (obj != null || obj != undefined) {
          languageList.push(obj?.name);
        }
      });
    }
    return languageList;
  };

  const renderLanguageListDetails = (langData) => {
    if (langData.length > 0) {
      return langData?.map((item, index) => {
        return (
          <Text m={1} p={1} fontSize={"xs"} bg={"blue.100"} rounded={"md"}>
            {item}
          </Text>
        );
      });
    }
  };

  const getJobExType = (jobName) => {
    if (jobName != null) {
      let doc = ["docx", "doc", "pdf", "txt"].map((item, index) => {
        if (jobName.indexOf(item) >= 0) return true;
        else return false;
      });
      if (jobName.indexOf("mp3") >= 0) {
        return "(Audio File)";
      } else if (
        jobName.indexOf("docx") >= 0 ||
        jobName.indexOf("pdf") >= 0 ||
        jobName.indexOf("txt") >= 0 ||
        jobName.indexOf("doc") >= 0
      ) {
        return "(Document)";
      } else if ("realtime".indexOf(jobName) >= 0) {
        return "(Realtime Text)";
      }
    }
  };

  const renderAllWorkflows = () => {
    if (activeJobs?.length > 0) {
      return activeJobs?.map((item, index) => {
        return (
          <Card
            w={"100%"}
            p={1}
            mt={1}
            bg={item?.completed === false ? "green.100" : null}
          >
            <Flex key={index} w={"100%"} mt={2} color={"black"}>
              <Box minW={"150px"} fontSize={"sm"} align={"center"}>
                <Text>
                  {moment(item?.created).format("MMM DD,YYYY,HH:mm a")}
                </Text>
                <Button size="xs" bg={"green.100"} mt={1}>
                  Workflow Info
                </Button>
              </Box>
              <Box
                w={"100%"}
                borderLeftWidth={"0.05px"}
                borderLeftColor={"gray.200"}
                ml={1}
                pl={2}
              >
                <Flex
                  w={"100%"}
                  align={"center"}
                  borderBottomWidth={"0.05px"}
                  borderBottomColor={"gray.200"}
                >
                  <Text>
                    <b>{item?.code}</b>
                  </Text>
                  <Text fontSize={"xs"} ml={2}>
                    {getJobExType(item?.job_name)}
                  </Text>
                </Flex>
                {item?.code === "Translation" ? (
                  <Flex
                    w={"100%"}
                    align={"center"}
                    borderBottomWidth={"0.05px"}
                    borderBottomColor={"gray.200"}
                  >
                    <Text mr={1}>Source Language</Text>
                    {renderLanguageListDetails(
                      getTranslationLanguagesFromCode(item?.source)
                    )}
                    <Text ml={5} mr={1}>
                      Target Language(s)
                    </Text>
                    {renderLanguageListDetails(
                      getTranslationLanguagesFromCode(item?.languages)
                    )}
                  </Flex>
                ) : null}
                {item?.code === "Transcription" ? (
                  <Flex
                    w={"100%"}
                    align={"center"}
                    borderBottomWidth={"0.05px"}
                    borderBottomColor={"gray.200"}
                  >
                    <Text mr={1}>Source Language</Text>
                    {renderLanguageListDetails(
                      getTranscriptionLanguagesFromCode(item?.source)
                    )}
                    <Text ml={5} mr={1}>
                      Target Language(s)
                    </Text>
                    {renderLanguageListDetails(
                      getTranscriptionLanguagesFromCode(item?.languages)
                    )}
                  </Flex>
                ) : null}
                <Flex
                  w={"100%"}
                  mt={1}
                  borderBottomWidth={"0.05px"}
                  borderBottomColor={"gray.200"}
                >
                  <Text mt={2} color={"blue.400"} fontSize={"xs"}>
                    Submitted By: {item?.email}
                  </Text>
                </Flex>
              </Box>
              <Box
                minW={"100px"}
                borderLeftWidth={"0.05px"}
                borderLeftColor={"gray.200"}
                ml={1}
                pl={2}
              >
                {item?.completed ? (
                  <Text fontSize={"sm"} color={"blue.600"}>
                    Completed @{" "}
                    {moment(item?.completed_time).format("MMM DD,YYYY,HH:mm a")}
                  </Text>
                ) : item?.started && item?.completed === false ? (
                  <Text fontSize={"sm"} color={"green.600"}>
                    Started @{" "}
                    {moment(item?.started_time).format("MMM DD,YYYY,HH:mm a")}
                  </Text>
                ) : (
                  <Text color={"red.500"}>Not Started</Text>
                )}
              </Box>
              <Box
                minW={"250px"}
                borderLeftWidth={"0.05px"}
                borderLeftColor={"gray.200"}
                ml={1}
                pl={2}
              >
                {item?.priority ? (
                  <Flex w={"200px"}>
                    <Icon
                      mt={1}
                      as={BiCheckCircle}
                      color={"green.300"}
                      mr={1}
                    />{" "}
                    Top Priority
                  </Flex>
                ) : null}
                {item?.proofreading ? (
                  <Flex w={"200px"}>
                    <Icon
                      mt={1}
                      as={BiCheckCircle}
                      color={"green.300"}
                      mr={1}
                    />{" "}
                    Proofreading Added
                  </Flex>
                ) : (
                  <Flex w={"200px"}>
                    <Icon mt={1} as={BiStopCircle} color={"red.200"} mr={1} />{" "}
                    Proofreading Not Added
                  </Flex>
                )}
                {item?.email_response ? (
                  <Flex w={"200px"}>
                    <Icon
                      mt={1}
                      as={BiCheckCircle}
                      color={"green.300"}
                      mr={1}
                    />{" "}
                    Result in Email
                  </Flex>
                ) : (
                  <Flex w={"200px"}>
                    <Icon mt={1} as={BiStopCircle} color={"red.200"} mr={1} />{" "}
                    Result not in email
                  </Flex>
                )}
              </Box>
              <Box
                minW={"150px"}
                borderLeftWidth={"0.05px"}
                borderLeftColor={"gray.200"}
                ml={1}
                pl={2}
              >
                {item?.billed ? (
                  <Flex w={"200px"}>
                    <Icon
                      mt={1}
                      as={BiCheckCircle}
                      color={"green.300"}
                      mr={1}
                    />{" "}
                    Billed
                  </Flex>
                ) : (
                  <Flex w={"200px"}>
                    <Icon
                      mt={1}
                      as={BiCheckCircle}
                      color={"green.300"}
                      mr={1}
                    />{" "}
                    Not Billed
                  </Flex>
                )}
                {item?.billed === false ? null : item?.paid ? (
                  <Flex w={"200px"}>
                    <Icon
                      mt={1}
                      as={BiCheckCircle}
                      color={"green.300"}
                      mr={1}
                    />{" "}
                    Paid
                  </Flex>
                ) : (
                  <Flex w={"200px"}>
                    <Icon
                      mt={1}
                      as={BiCheckCircle}
                      color={"green.300"}
                      mr={1}
                    />{" "}
                    Paid
                  </Flex>
                )}
              </Box>
              <Box
                minW={"150px"}
                borderLeftWidth={"0.05px"}
                borderLeftColor={"gray.200"}
                ml={1}
                pl={2}
              >
                {item?.completed ? (
                  <Button
                    onClick={() => {
                      navigate("/business/project/content/workflow_result", {
                        state: {
                          projectUuid: projectUuid,
                          workflowUuid: item?.wuuid,
                          contentUuid: item?.cuuid,
                          // contentTitle: contentTitle? contentTitle: null
                        },
                      });
                    }}
                    size={"sm"}
                    bg={"blue.100"}
                  >
                    View Results
                  </Button>
                ) : (
                  <Button size={"sm"} bg={"red.100"}>
                    Results No Ready
                  </Button>
                )}
              </Box>
            </Flex>
          </Card>
        );
      });
    } else {
      return <Text>There are no active jobs in this project....</Text>;
    }
  };

  // Chakra Color Mode
  return (
    <>
      <Box direction="column" pt={{ sm: "125px", lg: "75px" }}>
        <Card direction="column" p="0" mt="5" borderWidth="1px">
          <Tabs index={tabIndex} onChange={handleTabsChange}>
            <Card w={"100%"}>
              <Flex w={"100%"} justifyContent={"space-between"}>
                <Flex alignItems={"center"}>
                  <Text fontWeight={"500"} fontSize={"18px"}>
                    TODO: local/server
                  </Text>
                  {projectUuid ? (
                    <Text fontWeight={"500"} fontSize={"18px"}>
                      Content Count : {contentList.length}
                    </Text>
                  ) : (
                    <Button
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      <Icon mr="2" as={ChevronLeftIcon}></Icon>
                      Back to Projects
                    </Button>
                  )}
                </Flex>
                {projectUuid ? (
                  <Flex>
                    {titleShowHideFlag === true ? null : (
                      <TabList mr="3">
                        <Tab
                          fontWeight={"500"}
                          fontSize={"18px"}
                          borderBottomWidth="3px"
                        >
                          <Icon mr="3" as={ViewIcon}></Icon>
                          <Flex w={"100%"} direction={"column"} align={"start"}>
                            <Text>Content</Text>
                            <Text fontSize={"xs"}>(Listing and Review)</Text>
                          </Flex>
                        </Tab>
                        <Tab
                          fontWeight={"500"}
                          fontSize={"18px"}
                          borderBottomWidth="3px"
                        >
                          <Icon mr="3" as={SmallAddIcon}></Icon>
                          <Flex w={"100%"} direction={"column"} align={"start"}>
                            <Text>Realtime</Text>
                            <Text fontSize={"xs"}>
                              (Translation & Transcription)
                            </Text>
                          </Flex>
                        </Tab>
                        <Tab
                          fontWeight={"500"}
                          fontSize={"18px"}
                          borderBottomWidth="3px"
                        >
                          <Icon
                            mr="3"
                            fontSize={"14px"}
                            as={AttachmentIcon}
                          ></Icon>
                          <Flex w={"100%"} direction={"column"} align={"start"}>
                            <Text>Upload Content</Text>
                            <Text fontSize={"xs"}>(Doc, PDF, & Audio)</Text>
                          </Flex>
                        </Tab>
                        <Tab
                          fontWeight={"500"}
                          fontSize={"18px"}
                          borderBottomWidth="3px"
                        >
                          <Icon mr="3" as={AiFillBuild}></Icon>
                          <Flex w={"100%"} direction={"column"} align={"start"}>
                            <Text>Batch Jobs</Text>
                            <Text fontSize={"xs"}>
                              (Active and Completed Today)
                            </Text>
                          </Flex>
                        </Tab>
                      </TabList>
                    )}
                    {/* {titleShowHideFlag === true ? (
                                <Icon
                                h="25px"
                                w="25px"
                                fontWeight={"bold"}
                                as={BiSolidPlusSquare}
                                cursor={"pointer"}
                                color="blue.200"
                                onClick={() => {
                                    setTitleShowHideFlag(false);
                                }}
                                ></Icon>
                            ) : (
                                <Icon
                                h="30px"
                                w="30px"
                                as={BiCheckboxMinus}
                                cursor={"pointer"}
                                color="blue.200"
                                onClick={() => {
                                    setTitleShowHideFlag(true);
                                }}
                                ></Icon>
                            )} */}
                  </Flex>
                ) : null}
              </Flex>
            </Card>
            {titleShowHideFlag === true ? null : (
              <TabPanels
                h="calc(100vh - 300px)"
                overflow="auto"
                css={{
                  "&::-webkit-scrollbar": {
                    width: "4px",
                  },
                  "&::-webkit-scrollbar-track": {
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#fff",
                    borderRadius: "24px",
                  },
                }}
              >
                <TabPanel>
                  <Box w={"100%"}>
                    <>
                      <Card px="0">
                        {loading ? (
                          <Flex
                            alignItems="center"
                            justifyContent="center"
                            mt="10"
                            h="50vh"
                          >
                            <Spinner size="xl" />
                          </Flex>
                        ) : contentList && contentList.length > 0 ? (
                          <>
                            <EditableContentList
                              emailId={emaiId}
                              tableData={contentList}
                              columnsData={ContentListingHeader}
                              onDataReceived={reRenderTableData}
                              projectUuid={projectUuid}
                              reloadData={reloadResourceData}
                            />
                          </>
                        ) : (
                          <Box w={"100%"} p={5}>
                            <Alert
                              borderRadius="8px"
                              status="error"
                              variant="subtle"
                            >
                              <Flex>
                                <AlertIcon />
                                <Flex direction="column">
                                  <AlertTitle mr="12px">
                                    Empty Resources List
                                  </AlertTitle>
                                  <AlertDescription>
                                    There are no resources added...!!
                                  </AlertDescription>
                                </Flex>
                              </Flex>
                            </Alert>
                          </Box>
                        )}
                      </Card>
                    </>
                  </Box>
                </TabPanel>
                <TabPanel>
                  <Card>
                    <AddTextContent
                      projectUuid={projectUuid}
                      handleChange={handleTabsChange}
                    />
                  </Card>
                </TabPanel>
                <TabPanel>
                  <Box w={"100%"}>
                    <SimpleGrid
                      mt={"10px"}
                      mb="20px"
                      columns={{ sm: 1, lg: 1 }}
                      spacing={{ base: "20px", xl: "20px" }}
                    >
                      <Flex direction="column">
                        <UploadDocs
                          projectUuid={projectUuid}
                          handleChange={handleTabsChange}
                        />
                      </Flex>
                    </SimpleGrid>
                  </Box>
                </TabPanel>
                <TabPanel>
                  <Box w={"100%"}>
                    <Flex w={"100%"}>
                      <Spacer />
                      <Button
                        bg={"blue.300"}
                        size={"sm"}
                        onClick={() => fetchActiveJobs()}
                      >
                        Refresh List
                      </Button>
                    </Flex>
                    {renderAllWorkflows()}
                  </Box>
                </TabPanel>
              </TabPanels>
            )}
          </Tabs>
        </Card>
      </Box>
    </>
  );
}
