import {
    Card,
    Button,
    Flex,
    Text, useToast,
} from '@chakra-ui/react';
import { memo, useEffect, useState } from 'react';
import { fetchValidLanguages, getAllLocLanguages, updateLocationLanguageList } from '../../../services/accountServices';
import { toastFunctionToaster } from '../../../utils/toastFunction';
import Multiselect from 'multiselect-react-dropdown';

const LanguageSelection = ({ accountUuid, locationUuid }) => {
    const [languageList, setLanguageList] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const toast = useToast();
    const [showMultiSelect, setShowMultiSelect] = useState(false)

    useEffect(() => {
        handleGetAllLanguages();
        handleGetValidLanguages();
    }, []);

    const handleGetValidLanguages = () => {
        const reqbody = {
            account_uuid: accountUuid,
            location_uuid: locationUuid,
            type: 'translation',
        };
        fetchValidLanguages(reqbody)
            .then(resp => {
                if (resp?.status) {
                   resp?.languages[0] ? setSelectedLanguages(resp?.languages) : setSelectedLanguages([])
                } else {
                    toast(toastFunctionToaster(resp.message, 'error'));
                }
            })
            .catch(e => {
                toast(toastFunctionToaster(e.message, 'error'));
            });
    };

    const handleGetAllLanguages = () => {
        const reqbody = {
            type: 'translation',
            org: 'aws',
        };
        getAllLocLanguages(reqbody)
            .then(resp => {
                if (resp?.result) {
                    setLanguageList(resp.data);
                } else {
                    toast(toastFunctionToaster(resp?.message, 'error'));
                }
            })
            .catch(e => {
                toast(toastFunctionToaster(e?.message, 'error'));
            });
    };

    const handleUpdateLanguageList = () => {
        if(selectedLanguages.length){
            const reqbody = {
                "account_uuid": accountUuid,
                "location_uuid": locationUuid,
                "type": 'supported_languages', // Here we are setting location specific supported_language field
                "languages": selectedLanguages.join(",")
            }
            updateLocationLanguageList(reqbody)
                .then(resp => {
                    if (resp?.status) {
                        setSelectedLanguages(resp?.languages);
                        toast(toastFunctionToaster('Language list is updated..', 'success'));
                        setShowMultiSelect(false)
                    } else {
                        toast(toastFunctionToaster(resp?.message, 'error'));
                    }
                    return true
                })
                .catch(e => {
                    toast(toastFunctionToaster(e?.message, 'error'));
                });
        }
        setShowMultiSelect(false)
    }


    const RenderSelectedLanguages = memo(({ selectedLanguages }) => {
        const filteredData = languageList.filter(item =>
            selectedLanguages.includes(item.code)
        );

        return (filteredData.length ? (
            <Flex flexDir="column" mt='2' w="full">
                <Flex gap={4} flexWrap="wrap">
                    {filteredData.map(lang => (
                        <Text p={1} bg="green.300" borderRadius="lg" key={lang?.name} textAlign="center">
                            {lang?.name}
                        </Text>
                    ))}
                </Flex>
            </Flex>
        ) : (
            <Text p={1} bg="green.300" borderRadius="lg" w='100px'>
                All Available
            </Text>
        ))
    })

    const handleTargetSelect = selectedList => {
        if (selectedList.length > 0) {
            let languagesArr = [];
            selectedList?.map(data => {
                languagesArr?.push(data?.code);
            });
            setSelectedLanguages(languagesArr);
        }else{
            setSelectedLanguages([])
        }
    };

    const RenderLanguageListBox = memo(({ languageList, selectedLanguages }) => {
        const filterData = languageList.filter(item => !selectedLanguages.includes(item.code))
        const selectedValues = languageList.filter(item => selectedLanguages.includes(item.code))
        return (filterData.length ?
            (
                <Flex direction="column" gap={5} w="full" h="full">
                    <Multiselect
                        style={{
                            width:"100%",
                        }}
                        options={filterData}
                        selectedValues={selectedValues}
                        onSelect={selectedList => handleTargetSelect(selectedList)}
                        onRemove={selectedList => handleTargetSelect(selectedList)}
                        displayValue="name"
                        placeholder="Select languages"
                    />
                    <Flex flex='1' justifyContent="center" alignItems="end">
                    <Button colorScheme='blue' onClick={handleUpdateLanguageList} p='4' w="fit-content">Finalize Language Selection</Button>
                    </Flex>
                </Flex>

            ) : <>No options found</>)
    })

    return (
        <>
         <Card w={'100%'} h={'100%'} rounded={'lg'} m={1}>
            <Flex  w={'100%'} direction="column" overflow="auto" h="full">
                <Flex overflowY="auto" pb={10} css={{
                        "&::-webkit-scrollbar": {
                            width: "4px",
                        },
                        "&::-webkit-scrollbar-track": {
                            width: "6px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            background: "#A8A8A8",
                            borderRadius: "24px",
                        },
                    }}
                    mt={3}
                    w="full"
                    flexWrap={'wrap'}
                    p="2"
                    h="full"
                    >
                       {!showMultiSelect ? (<Flex borderWidth='1px' w='100%' p="2" h="full" direction="column">
                            <Flex flex="1" direction="column">
                            <Text fontWeight="bold" mb="1">Currently Selected Language</Text>
                            <RenderSelectedLanguages selectedLanguages={selectedLanguages} />
                            </Flex>
                            <Flex justifyContent="center" ><Button onClick={()=>setShowMultiSelect(true)} colorScheme='blue'>
                                Update Languages
                            </Button>
                            </Flex>
                        </Flex>):
                        ( <Flex borderWidth='1px' w='100%' p="2" direction="column" h="full">
                            <Text fontWeight="bold" mb="1">Update Languages</Text>
                            <RenderLanguageListBox languageList={languageList} selectedLanguages={selectedLanguages} />
                        </Flex>)}
                </Flex>
            </Flex>
        </Card>
        </>
    );

};

export default LanguageSelection;
