import React, { useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";

const QrCodeToImage = ({ value }) => {
    const [showImage, setShowImage] = useState(false)
    const svgRef = useRef();
    const pngImageRef = useRef()

    const currentHost =  window.location.hostname.includes('localhost') ? 'https://lingolet.ai/' : window.location.protocol + "//" + window.location.hostname;

    useEffect(() => {
        const pngImage = new Image()
        if (svgRef.current) {
            const svgElement = svgRef.current.querySelector("svg");
            if (svgElement) {
                const svgData = new XMLSerializer().serializeToString(svgElement);
                const blob = new Blob([svgData], { type: "image/svg+xml" })
                const svgDataUrl = URL.createObjectURL(blob)


                pngImage.addEventListener('load', () => {
                    const width = svgElement.getAttribute('width')
                    const height = svgElement.getAttribute('height')
                    const canvas = document.createElement('canvas')

                    canvas.setAttribute('width', width)
                    canvas.setAttribute('height', height)

                    const context = canvas.getContext('2d')
                    context.drawImage(pngImage, 0, 0, width, height)

                    const dataUrl = canvas.toDataURL('image/png')
                    pngImageRef.current.src = dataUrl
                    setShowImage(true)
                    URL.revokeObjectURL(svgDataUrl);
                })

                pngImage.src = svgDataUrl
            }
        }
        return(()=>{
            pngImage.removeEventListener('load',()=>{})
        })
    }, [value]);


    return (
        <>
            {!showImage && <div ref={svgRef}>
                <QRCode size={400}
                    style={{ height: "auto", maxWidth: "300", width: "300" }}
                    //value={String(`https://www.lingolet.ai/${value?.fullurl}`)}
                    value={`${currentHost}/${value}`}
                    viewBox={`0 0 90 90`} />
            </div>}
            <img ref={pngImageRef} alt="qr-code" style={{ height: "60%", maxWidth: "300px" }} />
        </>
    );

};

export default QrCodeToImage;
