import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// Chakra imports
import {
  Box,
  Spacer,
  Flex,
  Icon,
  Text,
  Alert,
  Button,
  Spinner,
  Divider,
  CircularProgress,
  CircularProgressLabel,
  Tabs, TabList, TabPanels, Tab, TabPanel,
  AlertIcon,
  AlertTitle,
  SimpleGrid,
  AlertDescription,
  useToast,
  Progress,
} from "@chakra-ui/react";
import {
  ViewIcon,
  SmallAddIcon,
  AttachmentIcon,
  ChevronLeftIcon,
} from "@chakra-ui/icons";
import { BiCheckboxMinus, BiSolidPlusSquare, BiCheckCircle, BiCaretLeft, BiCaretDown } from "react-icons/bi";
import moment from "moment";
import DocViewer, { DocViewerRenderers} from "@cyntler/react-doc-viewer";
import ReactAudioPlayer from 'react-audio-player';

// Components
import Card from "../../../../components/card/Card";
import UploadDocs from "./components/UploadDocs";
import AddTextContent from "./components/AddTextContent";
import EditableContentList from "./components/EditableContentList";
import { ContentListingHeader } from "./components/ContentListingHeader";
import { translationLanguages } from '../../../../utils/TranslationLanguages';
import { transcriptionLanguages } from '../../../../utils/TranscriptionLanguage';
import { toastFunctionToaster } from "../../../../utils/toastFunction.js";

// API
import { getEmailId } from "../../../../utils/localStorageIndex";
import { getContentWorkflowResult, sendEmailWithResult } from "../../../../services/moduleServices";

function ContentWorkflowResults() {
    const toast = useToast();
    const { state } = useLocation();
    const emaiId = getEmailId();
    const navigate = useNavigate();

    const projectUuid = state?.projectUuid ? state?.projectUuid : null;
    const workflowUuid = state?.workflowUuid ? state?.workflowUuid : null;
    const contentUuid = state?.contentUuid ? state?.contentUuid : null;
    const contentTitle = state?.contentTitle ? state?.contentTitle : null;

    const [loading, isLoading] = useState(false);
    const [showHeader, isShowHeader] = useState(false);
    const [jobFailed, setJobFailed] = useState(false);
    const [workflowData, setWorkflowData] = useState([]);
    const [contentData, setContentData] = useState([]);
    const [resultData, setResultData] = useState([]);
    const [transcriptionData, setTranscriptionData] = useState(null);
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        if (projectUuid != null && workflowUuid != null && contentUuid != null){
            fetchWorkflowDetails();
        }
    }, []);

    const fetchWorkflowDetails = () => {
        isLoading(true);
        let objData = {
            workflow_uuid: workflowUuid,
            project_uuid: projectUuid,
            content_uuid: contentUuid
        };
        getContentWorkflowResult(objData)
          .then((res) => {
            if (res?.result && res?.data?.length > 0) {
                isLoading(false);
                setWorkflowData(res?.data[0]?.workflow);
                setJobFailed(res?.data[0]?.workflow[0]?.status === 'failed' ? true : false);
                setContentData(res?.data[0]?.workflow[0]?.content);
                setResultData(res?.data[0]?.workflow[0]?.results);
            } else {
                isLoading(false);
            }
          })
          .catch((err) => {
            isLoading(false);
          });
    };

    const completeResultsWithEmail = () => {
        let objData = {
            workflow_uuid: workflowUuid,
            project_uuid: projectUuid,
            content_uuid: contentUuid
        };
        sendEmailWithResult(objData)
          .then((res) => {
            if (res?.result && res?.data?.length > 0) {
                if (res?.data[0]?.status === true){
                    toast(toastFunctionToaster("An email is sent to the workflow created with results", "success"));
                } else {
                    toast(toastFunctionToaster("Unable to deliver message with results", "error"));
                }
            }
        })
        .catch((err) => {
            toast(toastFunctionToaster("Unable to deliver message with results", "error"));
        });
    };

    const DynamicTabs = (dynamicTab) => {
        return (
          <Tabs>
            <TabList>
              {dynamicTab.map((tab, index) => (
                <Tab key={index}>{tab.tabName}</Tab>
              ))}
            </TabList>
            <TabPanels>
              {dynamicTab.map((tab, index) => (
                <TabPanel key={index}>
                  <Box p={4}>
                    <Text color="#fff">{tab.tabContent}</Text>
                  </Box>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        );
    };

    const renderWorkflowSourceTabs = contentData !== null && contentData?.length > 0
    ? contentData?.map((item, index) => (   
        <Tab key={index}>{item?.language ? item?.language : null} Source Document ({item?.contentType})</Tab>
    ))
    : null;
     
    const renderWorkflowSourceContent = contentData !== null && contentData?.length > 0
    ? contentData?.map((item, index) => (
            <TabPanel key={index}>
                {item.contentType === 'pdf'
                ?<Flex w={'100%'} direction={'column'}>
                    <DocViewer 
                        pluginRenderers={DocViewerRenderers} 
                        documents={[{uri: item?.s3_url, fileName:item?.title, fileType: 'pdf'}]}
                        config={{
                            theme:{
                            'pdf-download':true
                            },
                            header: {
                            disableHeader: true,
                            disableFileName: false,
                            retainURLParams: true,
                            },
                            csvDelimiter: ",", // "," as default,
                            pdfZoom: {
                            defaultZoom: 1.1, // 1 as default,
                            zoomJump: 0.2, // 0.1 as default,
                            },
                            pdfDownload: true,
                            pdfVerticalScrollByDefault: false, // false as default
                        }}
                    />
                    <Box w={'100%'}>
                        Page {pageNumber} of {numPages}
                    </Box>
                </Flex>:null}
                {item.contentType === 'doc'
                ?<Flex w={'100%'} direction={'column'}>
                    <DocViewer 
                        style={{width: 1200, height: 800}}
                        pluginRenderers={DocViewerRenderers} 
                        documents={[{uri:  item?.s3_url, fileName: item?.title, fileType: 'doc'}]}
                        config={{
                            theme:{
                            height:"100%"
                            },
                            header: {
                                disableHeader: true,
                                disableFileName: false,
                                retainURLParams: true,
                            },
                        }}
                    />
                    <Box w={'100%'}>
                        Page {pageNumber} of {numPages}
                    </Box>
                </Flex>:null}
                {item.contentType === 'audio'
                ?<Flex w={'100%'} direction={'column'}>
                    <Text fontSize={'lg'}>Play your Source Content (audio)</Text>
                    <ReactAudioPlayer
                        src={item.s3_url}
                        autoPlay={false}
                        controls
                    />
                </Flex>:null}
                {item?.contentType === 'text'
                    ?<Flex w={'100%'}>
                        <Card w={'100%'} 
                            h="calc(100vh - 400px)"
                            overflow="auto"
                            borderTopWidth="0px"
                            css={{
                            '&::-webkit-scrollbar': {
                                width: '4px',
                            },
                            '&::-webkit-scrollbar-track': {
                                width: '6px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '24px',
                            },
                            }}     
                        mt={1}>
                            <pre style={{whiteSpace: 'pre-wrap', fontFamily: 'inherit'}}>{item?.source_text}</pre>
                            {/* <Text>{item?.result_text}</Text> */}
                        </Card>
                    </Flex>:null}
            </TabPanel>
        ))
    : null;

    const renderBillingDetails = workflowData !== null && workflowData?.length > 0
    ? workflowData?.map((item, index) => (
        <Flex key={index} direction={'column'} w={'100%'}>
            {jobFailed
            ?<Flex key={index} w={'100%'} p={2}>
                <Text>Job failed so there is no charges for this workflow.</Text>
            </Flex>
            :<Box w={'100%'} ml={1} pl={2}>
                {item?.billed 
                ?<Flex w={'200px'}>
                    <Icon mt={1} as={BiCheckCircle} color={'green.300'} mr={1}/> Billed
                </Flex>
                :<Flex w={'200px'}>
                    <Icon mt={1} as={BiCheckCircle} color={'green.300'} mr={1}/> Not Billed
                </Flex>}
                {item?.billed === false ? ( null ) :
                    item?.paid
                    ?(<Flex w={'200px'}>
                        <Icon mt={1} as={BiCheckCircle} color={'green.300'} mr={1}/> Paid
                    </Flex>) : (
                    <Flex w={'200px'}>
                        <Icon mt={1} as={BiCheckCircle} color={'red.300'} mr={1}/> Not Paid
                    </Flex> )
                }
            </Box>}
        </Flex>
        ))
    : null;
    
    const renderProofreadingDetails = workflowData !== null && workflowData?.length > 0
    ? workflowData?.map((item, index) => (
        <Flex key={index} direction={'column'} w={'100%'} mt={2}>
            {jobFailed
            ?<Flex key={index} w={'100%'} p={2}>
                <Text>Job failed so there is no proofreading work required.</Text>
            </Flex>
            :<Box w={'100%'} ml={1} pl={2}>
                {item?.proofreading 
                ?<Flex w={'200px'}>
                    <Icon mt={1} as={BiCheckCircle} color={'green.300'} mr={1}/> Proofreading Added
                </Flex>
                :<Flex w={'200px'}>
                    <Icon mt={1} as={BiCheckCircle} color={'gray.300'} mr={1}/> Proofreading Not Added
                </Flex>}
            </Box>}
        </Flex>
        ))
    : null;

    const renderReportingDetails = workflowData !== null && workflowData?.length > 0
    ? workflowData?.map((item, index) => (
        <Flex key={index} direction={'column'} w={'100%'} mt={2}>
            {jobFailed
            ?<Flex key={index} w={'100%'} p={2}>
                <Text>Job failed and an email with job failure report is sent to you.</Text>
            </Flex>
            :<Box w={'100%'} ml={1} pl={2}>
                {item?.email_response 
                ?<Flex w={'100%'}>
                    {item?.email_sent
                    ?<Flex w={'100%'}>
                        <Icon mt={1} as={BiCheckCircle} color={'green.300'} mr={1}/>The results are sent in the email, please check your email. 
                    </Flex>
                    :<Flex w={'100%'}>
                        <Icon mt={1} as={BiCheckCircle} color={'gray.300'} mr={1}/> We could not sent an email with result, only status update.
                    </Flex>}
                </Flex>
                :<Flex w={'100%'}>
                    <Icon mt={1} as={BiCheckCircle} color={'red.200'} mr={1}/> You did not select to get response in your email update.
                </Flex>}
            </Box>}
        </Flex>
        ))
    : null;

    const renderTranscriptionReport = (item) => {
        return(
            <Flex w={'100%'}>
                <Card w={'100%'}>
                    <Text fontSize={'lg'} fontWeight={500}>Transcription Results in {item?.language_code} language</Text>
                    <Text mt={2}>
                        {item?.resultText}
                    </Text>
                </Card>
                <Card ml={1} minW={'250px'} maxW={'250px'} align={'start'}>
                    <Flex w={'100%'} direction={'column'} align={'center'}>
                        <Text fontSize={'lg'} fontWeight={500}>AI Transcription Report</Text>
                        <Divider />
                        <CircularProgress 
                            size='100px' thickness='10px' mt={5} mb={5}
                            value={item?.accuracy?.value} color={'green.500'}>
                            <CircularProgressLabel>{Math.round(item?.accuracy?.value)}{'%'}</CircularProgressLabel>
                        </CircularProgress>
                        <Divider />
                    </Flex>
                    {item?.accuracy?.rank?.length > 0
                    ?<Flex w={'100%'} direction={'column'}>
                        <Flex w={'100%'}>
                            <Text>Above 90%:</Text>
                            <Spacer />
                            <Text>{Math.round(item?.accuracy?.rank[0])}{'%'}</Text>
                        </Flex>
                        <Flex w={'100%'}>
                            <Text>Between 70%-90%:</Text>
                            <Spacer />
                            <Text>{Math.round(item?.accuracy?.rank[1])}{'%'}</Text>
                        </Flex>
                        <Flex w={'100%'}>
                            <Text>Between 50%-70%:</Text>
                            <Spacer />
                            <Text>{Math.round(item?.accuracy?.rank[2])}{'%'}</Text>
                        </Flex>
                        <Flex w={'100%'}>
                            <Text>Below 50%:</Text>
                            <Spacer />
                            <Text>{Math.round(item?.accuracy?.rank[3])}{'%'}</Text>
                        </Flex>
                        <Flex w={'100%'} align={'start'} mt={5}>
                            <Text fontSize={'xs'}>The above information is accuracy for each spoken word detection. Higher % value in higher % groups means better accuracy.</Text>
                        </Flex>
                    </Flex>
                    :null}
                </Card>
            </Flex>
        )        
    }

    const renderWorkflowResultTabs = resultData !== null && resultData?.length > 0
    ? resultData?.map((item, index) => (   
        <Tab key={index}>Result Document ({item.language})</Tab>
    ))
    : null;

    const renderWorkflowResultContent = resultData !== null && resultData?.length > 0
        ? resultData?.map((item, index) => (
            <TabPanel w={'100%'}  bg={'gray.200'} key={index}>
                <Flex w={'100%'}>
                    {item?.type === 'failure'
                    ?<Flex w={'100%'} h={'800px'} p={2}>
                        <Text>{item?.reason}</Text>
                    </Flex>:null}
                    {item?.type === 'treport'
                    ?<Flex w={'100%'} h={'800px'}>
                        {renderTranscriptionReport(item)}
                    </Flex>:null}
                    {/* <Text>{JSON.stringify(item)}</Text> */}
                    {item?.type === 'pdf'
                        ?<Flex w={'100%'} h={'800px'}>
                            <DocViewer 
                            pluginRenderers={DocViewerRenderers} 
                            documents={[{uri: item?.url, fileName:'documentTitle', fileType: 'pdf'}]}
                            config={{
                                theme:{
                                'pdf-download':true
                                },
                                header: {
                                disableHeader: true,
                                disableFileName: false,
                                retainURLParams: true,
                                },
                                csvDelimiter: ",", // "," as default,
                                pdfZoom: {
                                defaultZoom: 1.1, // 1 as default,
                                zoomJump: 0.2, // 0.1 as default,
                                },
                                pdfDownload: true,
                                pdfVerticalScrollByDefault: false, // false as default
                            }}
                        /></Flex>:null}
                    {item?.type === 'doc'
                        ?<Flex w={'100%'} h={'800px'}><DocViewer 
                            style={{width: 1200, height: 800}}
                            pluginRenderers={DocViewerRenderers} 
                            documents={[{uri: item?.url, fileName:'documentTitle', fileType: 'doc'}]}
                            config={{
                                theme:{
                                height:"100%"
                                },
                                header: {
                                    disableHeader: true,
                                    disableFileName: false,
                                    retainURLParams: true,
                                },
                            }}
                        /></Flex>:null}
                    {item?.type === 'txt'
                        ?<Flex w={'100%'} h={'800px'}><DocViewer 
                            pluginRenderers={DocViewerRenderers} 
                            documents={[{uri: item?.url, fileName:'', fileType: 'txt'}]}
                            config={{
                                theme:{
                                'pdf-download':true
                                },
                                header: {
                                    disableHeader: true,
                                    disableFileName: false,
                                    retainURLParams: true,
                                },
                                csvDelimiter: ",", // "," as default,
                                pdfZoom: {
                                defaultZoom: 1.1, // 1 as default,
                                zoomJump: 0.2, // 0.1 as default,
                                },
                            }}
                        /></Flex>:null}
                    {item?.type === 'text'
                        ?<Flex w={'100%'}>
                            <Card w={'100%'} 
                                h="calc(100vh - 400px)"
                                overflow="auto"
                                borderTopWidth="0px"
                                css={{
                                '&::-webkit-scrollbar': {
                                    width: '4px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    width: '6px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    borderRadius: '24px',
                                },
                                }}     
                            mt={1}>
                                <pre style={{whiteSpace: 'pre-wrap', fontFamily: 'inherit'}}>{item?.result_text}</pre>
                                {/* <Text>{item?.result_text}</Text> */}
                            </Card>
                        </Flex>:null}
                </Flex>
            </TabPanel>
        ))
        : null;


    const getTranslationLanguagesFromCode = (langArr) => {
        let languageList = [];
        let langCodes = langArr.split(",");
        if (langCodes.length > 0){
            langCodes.map( (item, index) => {
                let obj = translationLanguages.find(o => o.code === item);
                if (obj != null || obj != undefined){
                    languageList.push(obj?.name);
                }
            })
        }
        return languageList;
    }

    const getTranscriptionLanguagesFromCode = (langArr) => {
        let languageList = [];
        let langCodes = langArr.split(",");
        if (langCodes.length > 0){
            langCodes.map( (item, index) => {
                let obj = transcriptionLanguages.find(o => o.code === item);
                if (obj != null || obj != undefined){
                    languageList.push(obj?.name);
                }
            })
        }
        return languageList;
    }

    const renderLanguageListDetails = (langData) => {
        if (langData.length > 0){
            return(
                langData?.map((item, index) => {
                    return(
                        <Text m={1} p={1} fontSize={'xs'} bg={'blue.100'} rounded={'md'}>{item}</Text>
                    )
                })
            )
        }
    }

    const getCompletionTime = (complete_time, start_time) => {
        let seconds = moment(complete_time).diff(moment(start_time), 'seconds');
        if (seconds == 0){
            return 'Under a minute';
        } else if (seconds <= 60){
            return seconds + ' Seconds';
        } else {
            seconds = seconds / 60;
            return  Math.round(seconds,2) + ' Minutes'
        }
    }
    
    const renderWorkflowDetails = workflowData !== null && workflowData?.length > 0
    ? workflowData?.map((item, index) => (
        <Flex key={index} direction={'column'} w={'100%'} mt={2}>
            <Flex w={'100%'} ml={1} p={2}>
                <Text align={'center'} ml={1} fontSize={'lg'}><b>{item?.code}</b></Text>
                {jobFailed
                ?<Text align={'center'} ml={1} color={'red.500'} fontSize={'lg'}><b>Failed</b></Text>:null}
                <Spacer />
                <Button onClick={() => isShowHeader(!showHeader)} size={'sm'} bg={'gray.200'}>
                    <Icon as={showHeader ? BiCaretLeft : BiCaretDown} />
                </Button>
                {/* {item?.started
                ?<Button 
                    size={'sm'} bg={'blue.200'}>
                    Refresh Results
                </Button>
                :<Button size={'sm'} disabled={true} ml={1} bg={'red.200'}>Refresh Results</Button>} */}
            </Flex>
            <Flex w={'100%'} direction={'column'} ml={1} p={2} hidden={showHeader ? true: false}>
                <Flex w={'100%'}>
                    <Box minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
                        Created On: {moment(item?.created).format("MMM DD,YYYY,HH:mm:ss a")}
                    </Box>
                    <Box minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
                        (Total Time Taken to complete: {getCompletionTime(item?.complete_time, item?.start_time)})
                    </Box>
                </Flex>
                <Box w={'100%'} ml={1} pl={2}>
                    <Text color={'blue.400'} fontSize={'xs'}>Submitted By: {item?.email}</Text>
                </Box>
                <Box w={'100%'} ml={1} pl={2}>
                    {item?.code === 'Translation'
                    ?<Flex w={'100%'}>
                        <Text mr={1}>Source Language</Text>
                        {renderLanguageListDetails(getTranslationLanguagesFromCode(item?.source))}
                        <Text ml={5}  mr={1}>Target Language(s)</Text>
                        {renderLanguageListDetails(getTranslationLanguagesFromCode(item?.languages))}
                    </Flex>:null}
                    {item?.code === 'Transcription'
                    ?<Flex w={'100%'}>
                        <Text mr={1}>Source Language</Text>
                        {renderLanguageListDetails(getTranscriptionLanguagesFromCode(item?.source))}
                        <Text ml={5}  mr={1}>Target Language(s)</Text>
                        {renderLanguageListDetails(getTranscriptionLanguagesFromCode(item?.languages))}
                    </Flex>:null}
                </Box>
                {/* <Box minW={'100px'} ml={1} p={2}>
                    {item?.completed ? (
                        <Flex w={'100%'}>
                            <Text color={'green.500'}>Job Completed</Text>
                            <Text ml={1}>{moment(item?.complete_time).format("MMM DD,YYYY,HH:mm a")}</Text>
                        </Flex>
                    ) : item?.started && item?.completed === false ? (
                        <Text color={'green.300'}>Job Started</Text>
                    ) : ( 
                        <Text color={'red.300'}>Not Started</Text>
                    )}
                </Box> */}
                <Box minW={'250px'} ml={1} pl={2}>
                    {item?.priority 
                    ?<Flex w={'200px'}>
                        <Icon mt={1} as={BiCheckCircle} color={'green.300'} mr={1}/> Top Priority
                    </Flex>:null}
                </Box>
                <Flex w={'100%'} borderWidth={'0.5px'} borderColor={'gray.200'} rounded={'md'} mt={4} p={2}>
                    <Box w={'250px'}>
                        <Text color={'green.500'}>Job Started</Text>
                        <Text ml={1}>{moment(item?.start_time).format("MMM DD,YYYY,HH:mm:ss a")}</Text>
                    </Box>
                    <Box  w={'100%'} ml={1} pl={2} mt={2} mb={5}>
                        <Progress w={'100%'} colorScheme={jobFailed ? 'red' : 'green'} size='sm' hasStripe value={100} />
                    </Box>
                    <Box w={'300px'} pl={5}>
                        <Text color={'green.500'}>Job Completed</Text>
                        <Text ml={1}>{moment(item?.complete_time).format("MMM DD,YYYY,HH:mm:ss a")}</Text>
                    </Box>
                </Flex>
            </Flex>
        </Flex>
        ))
    : null;
    
    return (
        <>
        <Box direction="column" pt={{ sm: "125px", lg: "75px" }}>
            <Flex
                p="3"
                alignItems="center"
                borderBottomWidth="1px"
                justifyContent={"space-between"}
            >
            <Text fontWeight={"500"} fontSize={"18px"}>
                <b>{contentTitle}</b> {' '}Translation and Transcription Workflows Results
            </Text>
            <Spacer />
            <Button 
                onClick={() => {
                    navigate("/business/projects", {
                    state: {
                        projectUuid: projectUuid,
                        contentUuid: contentUuid,
                    },
                    })
                }}
                colorScheme="blue" size="sm" >
                Back to Projects
            </Button>
            {projectUuid != null && contentUuid != null
            ?<Button 
                onClick={() => {
                    navigate("/business/project/content", {
                    state: {
                        projectUuid: projectUuid,
                        contentUuid: contentUuid,
                        contentTitle: contentTitle
                    },
                    })
                }}
                colorScheme="blue" size="sm" ml={1}>
                Back to Contents Page
            </Button>:null}
            {projectUuid != null && contentUuid != null
            ?<Button 
                onClick={() => {
                    navigate("/business/project/content/workflow", {
                    state: {
                        projectUuid: projectUuid,
                        contentUuid: contentUuid,
                        contentTitle: contentTitle
                    },
                    })
                }}
                colorScheme="blue" size="sm" ml={1}>
                Back to Content Workflows Page
            </Button>:null}
            </Flex>
            {loading ? (
            <Flex alignItems="center" justifyContent="center" h="50vh">
                <Spinner size="xl" />
            </Flex>
            ) : (
                <>
                {workflowData !== null && workflowData?.length ? (
                    <Flex w={'100%'} direction={'column'}>
                        <Card w={'100%'}>
                            {renderWorkflowDetails}
                        </Card>
                        <Card w={'100%'} mt={1}>
                            <Tabs>
                            <TabList>
                                <Tab>Original Source Content</Tab>
                                <Tab>Results Content</Tab>
                                <Tab>Proofreading Results</Tab>
                                <Tab>Progress Reporting</Tab>
                                <Tab>Billing Details</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    <Card w={'100%'} mt={1}>
                                        <Flex w={'100%'}>
                                            <Tabs>
                                                <TabList>
                                                    {renderWorkflowSourceTabs}
                                                </TabList>
                                                <TabPanels>
                                                    {renderWorkflowSourceContent}
                                                </TabPanels>
                                            </Tabs>
                                        </Flex>
                                    </Card>
                                </TabPanel>
                                <TabPanel>
                                    <Flex w={'100%'} direction={'column'}>
                                        <Flex w={'100%'}>
                                            <Spacer />
                                            <Button size={'sm'} 
                                                onClick={() => completeResultsWithEmail()}
                                                bg={'blue.100'}>Send Result as Email</Button>
                                        </Flex>
                                        <Flex w={'100%'}>
                                            <Card w={'100%'} mt={1}>
                                                <Flex w={'100%'}>
                                                    <Tabs w={'100%'}>
                                                        <TabList>
                                                            {renderWorkflowResultTabs}
                                                        </TabList>
                                                        <TabPanels>
                                                            {renderWorkflowResultContent}
                                                        </TabPanels>
                                                    </Tabs>
                                                </Flex>
                                            </Card>
                                        </Flex>
                                    </Flex>
                                </TabPanel>
                                <TabPanel>
                                    <Card w={'100%'} mt={1}>
                                        {renderProofreadingDetails}
                                    </Card>
                                </TabPanel>
                                <TabPanel>
                                    <Card w={'100%'} mt={1}>
                                        {renderReportingDetails}
                                    </Card>
                                </TabPanel>
                                <TabPanel>
                                    <Card w={'100%'} mt={1}>
                                        {renderBillingDetails}
                                    </Card>
                                </TabPanel>
                            </TabPanels>
                            </Tabs>
                        </Card>
                    </Flex>
                ) : (
                    <Text p="2" fontWeight={"500"}>
                    No Record Found
                    </Text>
                )}
                </>
            )}
        </Box>
        </>
    )
}

export default ContentWorkflowResults;