import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { AiOutlineSearch, AiOutlineRobot, AiOutlineUserSwitch, AiFillEdit } from "react-icons/ai";
import {
    Stack,
    Icon,
    Box,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    FormLabel,
    Heading,
    Flex,
    Text,
    Button,
    Drawer,
    Spinner,
    InputGroup,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    InputLeftElement,
    useDisclosure,
    Input,
    useColorModeValue,
    Switch,
    Avatar,
    useToast
} from "@chakra-ui/react";

import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import { defaultThemeColor } from "../../../constants";

import Card from "../../../components/card/Card";
import { getAssocTeamMembers } from "../../../services/businessServices";
import { toastFunctionToaster } from "../../../utils/toastFunction";
import { fetchLocationInfo } from "../../../services/accountServices";
import BasicAIBg from "../../../assets/communicator/SecureAI.png";
import PremiumAIBg from "../../../assets/communicator/Premium_AIIcon.png";
import HumanAIBg from "../../../assets/communicator/HumanIcon.png";
import MultichannelBg from "../../../assets/communicator/multichannelai.png";
import DocumentAiLightBg from "../../../assets/communicator/DocumentAILight.png";
import InviteAccountAdmin from "./InviteAccountAdmin";


const AccountTeam = (props) => {
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, isLoading] = useState(false);
    const [teamMembers, setTeamMembers] = useState([])
    const [quota, setQuota] = useState([])
    const toast = useToast()

    const brandBg = useColorModeValue("blue.50", "navy.900");
    const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(true);

    const handleGetTeamMembers = () => {
        const paramObj = {
            key: ''
        }
        getAssocTeamMembers(paramObj).then(resp => {
            if (resp?.data[0]) {
                setTeamMembers(resp?.data[0])
            } else {
                toast(toastFunctionToaster(resp?.message, "error"))
                setTeamMembers([])
            }
        }).catch(err => {
            toast(toastFunctionToaster("Failed to get Team Menbers", "error"))
            setTeamMembers([])

        })
    }

    const handleGetQuota = () => {
        const paramObj = {
            key:'location'
        }
        fetchLocationInfo(paramObj).then(resp=>{
            setQuota(resp?.data[0]?.quota)
        }).catch(err=>{

        })
    }

    useEffect(() => {
        handleGetTeamMembers()
        handleGetQuota()
    }, [])

    const renderFeatureName = (name) => {
        if (name === 'hi') {
            return 'Human Interpretation';
        } else if (name === 'bi') {
            return 'Secure AI';
        } else if (name === 'pi') {
            return 'Premium AI';
        } else if (name === 'di'){
            return 'Document AI';
        } else if (name === 'mi'){
            return 'Multichannel';
        }
    }

    // Todo:Render Feature Image By Code
	const renderFeatureImage = name => {
		if (name === "hi") {
			return HumanAIBg;
		} else if (name === "bi") {
			return BasicAIBg;
		} else if (name === "pi") {
			return PremiumAIBg;
		} else if (name === "di") {
			return DocumentAiLightBg;
        } else if (name === "mi") {
			return MultichannelBg;
		} else return;
	};

    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }} pl={3}>
            <Card w={'100%'} mt={2}>
                <Flex h="80vh">
                    <Box
                        w={!configurationCollapseFlag ? "95%" : "70%"}
                        bg={brandBg}
                        p="5"
                        borderRadius={"10px"}
                        overflowY="scroll"
                    >
                        {
                            teamMembers?.length ? (
                                teamMembers?.map((item, index) => (
                                    <Flex
                                        w={"100%"}
                                        key={index}
                                        rounded={"md"}
                                        align={"center"}
                                        p={1}
                                        mt={1}
                                        direction={'column'}
                                        borderWidth={"0.05px"}
                                        borderLeftWidth={'10px'}
                                        borderLeftColor={item?.is_disabled ? "red.300" : "green.300"}
                                        borderColor={item?.is_disabled ? "red.200" : "green.300"}>
                                        <Flex w={'100%'}>
                                            <Flex
                                                rounded={"md"}
                                                px={1}
                                                w={"100px"}
                                                align={"center"}>
                                                <Box minW={"60px"}>
                                                    <Avatar src={item?.user_photo_url}/>
                                                </Box>
                                            </Flex>
                                            <Flex w={'100%'} direction={'column'}>
                                                <Flex minW={"100%"} p={1} direction={"column"} flexWrap={'wrap'}>
                                                    <Text fontWeight={'bold'}>
                                                        {item?.first_name} <Text as="span" fontWeight={'bold'}>{item?.last_name}</Text>
                                                    </Text>
                                                    <Text>
                                                        {item?.email}
                                                    </Text>
                                                    <Text>
                                                        {item?.account_subscriber_email}
                                                    </Text>
                                                    <Flex maxW={'100%'}>
                                                        {item?.is_disabled
                                                            ? <Text ml={1} w={'100px'} color={'gray.700'} bg={'red.100'} p={1} rounded={'md'}>
                                                                Disabled
                                                            </Text>
                                                            : <Text ml={1} w={'100px'} color={'gray.700'} bg={'green.200'} p={1} rounded={'md'}>
                                                                Enabled
                                                            </Text>}
                                                    </Flex>
                                                </Flex>
                                            </Flex>
                                            
                                        </Flex>
                                        <Flex w={'100%'} mt={3} p={1} direction={'column'} borderWidth={'1px'} borderColor={'gray.200'} rounded={'lg'}>
                                            {quota.length ?(<> <Flex
                                                rounded={"md"}
                                                px={1}
                                                align={"center"} mb={4}>
                                                <Text>Enabled lingoLink Apps</Text>
                                            </Flex>
                                            <Flex w={'100%'} alignItems={'center'} gap={2} >
                                                {quota.map(item=>(
                                                    <Flex borderWidth={'1px'} borderColor={'green.200'} rounded={'lg'} p={1} alignItems="center" gap="1">
                                                        <Avatar size="sm" src={renderFeatureImage(item.quota_id)}/>
                                                        {renderFeatureName(item.quota_id)}
                                                    </Flex>
                                                ))}
                                            </Flex> </>) : <Flex
                                                rounded={"md"}
                                                px={1}
                                                align={"center"}>
                                                <Text>No Enabled lingoLink Apps</Text>
                                            </Flex>}
                                        </Flex>
                                    </Flex>
                                ))
                            ) : (
                                <Flex w={"100%"} p={5} bg="red.100" borderLeftWidth={"5px"} borderLeftColor={"red.400"}>
                                    <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"}>
                                        You do not have any Team members.
                                    </Heading>
                                </Flex>
                            )
                        }
                    </Box>
                    {configurationCollapseFlag ? (
                        <Box
                            w="30%"
                            position="relative"
                            p="3"
                            borderRadius="15px"
                            bg={brandBg}
                            ml="2"
                        >
                            <Box
                                position="absolute"
                                top="0"
                                right="0"
                                mr="2"
                                mt="3"
                                zIndex="999"
                            >
                                <AiFillRightCircle
                                    cursor="pointer"
                                    size="20px"
                                    onClick={() => {
                                        setConfiguationCollapseFlag(
                                            !configurationCollapseFlag
                                        );
                                    }}
                                />
                            </Box>
                            <Box w="100%" h="95%"  overflowY="auto">
                                <Flex w={'100%'} direction={'column'} p={5}>
                                    <InviteAccountAdmin />
                                </Flex>
                                
                            </Box>
                        </Box>
                    ) : (
                        <Box
                            w="5%"
                            p="3"
                            borderRadius="10px"
                            bg={brandBg}
                            h="100%"
                            ml="2"
                            pl="5"
                        >
                            <Box w={"100%"} h="100%">
                                <AiFillLeftCircle
                                    cursor="pointer"
                                    onClick={() => {
                                        setConfiguationCollapseFlag(
                                            !configurationCollapseFlag
                                        );
                                    }}
                                    size="20px"
                                />
                                <Text
                                    fontSize="16px"
                                    mt="2"
                                    css={{
                                        writingMode: "vertical-lr",
                                        textOrientation: "mixed",
                                    }}
                                >
                                    Request to Add new Account Admin
                                </Text>
                            </Box>
                        </Box>
                    )}
                </Flex>
            </Card>
        </Flex>
    );
}

export default AccountTeam;
