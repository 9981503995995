import { Box, Button, Card, Flex, Icon, Spacer, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react'
import { useBlackWhiteBg, useBrandBg, useGradientColor, usePillButtonBg, usePillButtonHighLight, usePillButtonTextColorHover, useTextColor } from '../../../theme/globalColorTheme';
import { Link } from 'react-router-dom';
import { AiOutlineCopy, AiOutlineLink } from 'react-icons/ai';
import QRCode from 'react-qr-code';
import moment from 'moment'

const SharingCardDetails = ({ data, setModalQrCode, onOpenQr }) => {
    const [setShowTooltip] = useState(false);
    const pillButtonTextColorHover = usePillButtonTextColorHover()
    const pillButtonText = useTextColor()
    const brandBg = useBrandBg()
    const gradientColor = useGradientColor()
    const blackWhiteBg = useBlackWhiteBg()

    const pillButtonBg = usePillButtonBg()
    const pillButtonBgHighlight = usePillButtonHighLight()



    const port_value = window.location.port ? ":" + window.location.port : '';
    const currentHost = window.location.hostname.includes("localhost")
        ? "https://lingolet.ai/"
        : window.location.protocol + "//" + window.location.hostname + port_value + "/";
    return (
        <Card
            bg={brandBg}
            w={"100%"}
            mb={2}
            p="0px"
            borderRadius='10px'
            boxShadow="none"
        >
            <Flex w={"100%"} borderWidth={'0.5px'} borderColor={gradientColor} borderRadius="10px" className="abc">
                <Flex w={"70%"} direction={"column"} >
                    <Flex flexDir="column">
                        <Flex w={'100%'}
                            // bg={item?.dead ? localCardHeaderNonActiveBg : localCardHeaderActiveBg}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            roundedTop={'md'} p={'10px'} borderBottomWidth={'0.5px'} borderColor={gradientColor}
                        >
                            <Flex alignItems="center">
                                <Text textTransform="capitalize" fontWeight="bold" pl='10px' pt="2px">{data?.dynamic_content_type} -</Text>
                                <Text as="span" ml={1}>{data?.content_type_details?.title || "No Title"}</Text>
                            </Flex>
                        </Flex>
                        {data?.dynamic_content_type === 'lingocard' && <Flex flexDir="column" pl={'10px'}>
                            <Text>
                                Start Time (UTC):{" "}
                                <Text as="span" fontWeight={"600"}>
                                    {moment(data?.content_type_details?.start_time).format("MMM DD,YYYY,HH:mm a")}
                                </Text>
                            </Text>
                            <Text>
                                End Time (UTC):{" "}
                                <Text as="span" fontWeight={"600"}>
                                    {moment(data?.content_type_details?.end_time).format("MMM DD,YYYY,HH:mm a")}
                                </Text>
                            </Text>
                            <Text>
                                Card Active
                                <Text as="span" fontWeight={"600"}>
                                   {data?.content_type_details?.card_active ? "Active" : "Disabled" }
                                </Text>
                            </Text>
                        </Flex>}

                    </Flex>
                    {data?.dynamic_content_type === 'lingolink' && < Flex w={'100%'} alignItems="center">
                        <Flex minW={'150px'} maxW={'150px'} >
                            <Flex maxW={"150px"}>
                                <Flex w={"100%"} direction={"column"} p={4} alignItems={'center'} justifyContent={'end'}>
                                    <Box
                                        w={"100%"}
                                        onClick={() => {
                                            setModalQrCode(data?.content_type_details?.link_url + `&code=${data?.link_code}`);
                                            onOpenQr();
                                        }}
                                        _hover={{ cursor: "pointer", borderColor: "red.600", borderWidth: "1px" }}
                                        p={1}
                                        borderColor={"gray.400"}
                                        borderWidth={"1px"}
                                        rounded={"md"}>
                                        <QRCode
                                            size={90}
                                            style={{ height: "auto", maxWidth: "100", width: "100" }}
                                            value={data?.content_type_details?.link_url + `&code=${data?.content_type_details?.link_code}`}
                                            viewBox={`0 0 90 90`}
                                        />
                                    </Box>
                                </Flex>
                            </Flex>
                        </Flex>

                        <Flex w={"100%"} direction={"column"} p={'10px'}>
                            <Flex
                                w={"100%"}
                                p={1}
                                direction={"column"}
                                bg={blackWhiteBg}
                                rounded={"md"}>
                                <Flex w={"100%"} direction={"column"}>
                                    {data?.dynamic_content_type === "lingolink" && <Flex
                                        w={"100%"} alignItems="center">
                                        <Flex w={"100%"} mt={1} >
                                            <Text mt={2} mr={2} fontSize={"lg"} color={"blue.600"}>
                                                {data?.content_type_details?.link_url ? currentHost + data?.content_type_details?.link_url + `&code=${data?.content_type_details?.link_code}` : "No Url"}
                                            </Text>
                                        </Flex>
                                        <Tooltip
                                            hasArrow
                                            placement={"top"}
                                            bg="teal.500"
                                            onMouseEnter={() => setShowTooltip(true)}
                                            onMouseLeave={() => setShowTooltip(false)}
                                            color="white"
                                            label="Copy Url">
                                            <Box
                                                mt={1}
                                                w={"35px"}
                                                ml={1}
                                                pr={2}
                                                rounded={"md"}
                                                mr={2}
                                                _hover={{
                                                    bg: pillButtonBgHighlight,
                                                    cursor: "pointer",
                                                    color: pillButtonTextColorHover
                                                }}
                                                color={pillButtonText}
                                                bg={pillButtonBg}>
                                                <Icon
                                                    onClick={() =>
                                                        navigator.clipboard.writeText(currentHost + data?.content_type_details?.link_url + `&code=${data?.link_code}`)
                                                    }
                                                    ml={2}
                                                    mt={1}
                                                    h={5}
                                                    _hover={{ cursor: "pointer" }}
                                                    w={5}
                                                    as={AiOutlineCopy}
                                                />
                                            </Box>
                                        </Tooltip>
                                        <Tooltip
                                            hasArrow
                                            placement={"top"}
                                            bg="teal.500"
                                            onMouseEnter={() => setShowTooltip(true)}
                                            onMouseLeave={() => setShowTooltip(false)}
                                            color="white"
                                            label="Open Url in new Tab">
                                            <Link
                                                mt={1}
                                                p={1}
                                                w={"35px"}
                                                rounded={"md"}
                                                mr={2}
                                                align={"center"}
                                                href={currentHost + data?.content_type_details?.link_url + `&code=${data?.link_code}`}
                                                target="_blank"
                                                _hover={{
                                                    bg: pillButtonBgHighlight,
                                                    cursor: "pointer",
                                                    color: pillButtonTextColorHover
                                                }}
                                                color={pillButtonText}
                                                bg={pillButtonBg}>
                                                <Icon
                                                    ml={2}
                                                    mt={1}
                                                    h={5}
                                                    _hover={{ cursor: "pointer" }}
                                                    w={5}
                                                    as={AiOutlineLink}
                                                />
                                            </Link>
                                        </Tooltip>
                                    </Flex>}
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>}
                </Flex>
                <Flex w={"30%"} overflow="hidden" ml='2' maxH="12rem">
                    <Flex flexDir="column" overflowY="auto" w="100%" alignItems="center" gap={2} className='custom-scrollbar' pb="2">
                        <Text fontWeight="bold" mt="1">Shared with Emails:</Text>
                        {
                            data?.emails.map(email => (
                                <Tooltip label={email}>
                                    <Text p={2} w={'90%'} bg="cyan" rounded="md" isTruncated flexShrink={0} whiteSpace="nowrap" size="smaller">{email}</Text>
                                </Tooltip>
                            ))

                        }


                    </Flex>
                </Flex>
            </Flex>
        </Card>
    )
}

export default SharingCardDetails