import axios from "./axios";
import { serviceAPIs } from "./serviceApis";

// --------------------------------------------------------------
// Account and Location API
// --------------------------------------------------------------

export const fetchLocationInfo = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getLocationInfo, data, config);
};

export const fetchLocationDetails = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getLocationList, data, config);
};

export const fetchLocationsAndSubs = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getLocListWithSubs, data, config);
};

export const manageLocationStatus = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().manageLocationStatus, data, config);
};

export const addNewLocation = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().createNewLocation, data, config);
};

export const updateExistingLocation = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateExisingLocation, data, config);
};

export const uploadLocationAvtar = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().uploadLocationAvtar, data, config);
};

export const fetchSubscriberList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getSubscriberList, data, config);
};

export const manageSubscriberStatus = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().manageSubscriberStatus, data, config);
};

export const addSubscriber = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().addSubscriber, data, config);
};

export const updateSubscriber = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateSubscriber, data, config);
};

export const getAllLanguages = (data, config=null, axiosInstance=axios)=>{
	return axiosInstance.post(serviceAPIs().getLanguageList, data, config)
}

export const getAllLocLanguages = (data, config=null, axiosInstance=axios)=>{
	return axiosInstance.post(serviceAPIs().getLocLanguageList, data, config)
}

export const fetchValidLanguages = (data, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().getLanguageListForLocation, data, config)
}

export const updateLocationLanguageList = (data, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().updateLanguageList, data, config)
}

export const updateDesignForAccountAdmin = (data, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().updateDesignForAccountAdmin, data, config)
}

export const addSubDomainForAccountAdmin = (data, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().addSubDomainAccountAdmin, data, config)
}

export const delSubDomainForAccountAdmin = (data, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().delSubDomainAccountAdmin, data, config)
}



export const createAccLangPref = (data, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().createAccLangPref, data, config)
}

export const updateAccLangPref = (data, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().updateAccLangPref, data, config)
}

export const manageAccLangPref = (data, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().manageAccLangPref, data, config)
}

export const applyAccLangPref = (data, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().applyAccLangPref, data, config)
}

export const accLangPrefPaginated = (data, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().accLangPaginated, data, config)
}

export const getBusinessDetailsForAccountAdmin = (data, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().getBusinessDetailsForAccountAdmin, data, config)
}

export const createAccAdminSchedule = (data, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().createAccAdminSchedule, data, config)
}

export const updateAccAdminSchedule = (data, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().updateAccAdminSchedule, data, config)
}

export const getAccAdminScheduleList = (data, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().getAccAdminScheduleList, data, config)
}

export const cancelAccAdminSchedule = (data, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().cancelAccAdminSchedule, data, config)
}

export const shareAccAdminSchedule = (data, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().shareAccAdminSchedule, data, config)
}

export const getAccLinkHistory = (data, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().getAccLinkCallHistory, data, config)
}

export const getAccAssignedDevices = (data=null, config=null, axiosInstance=axios) =>{
	return axiosInstance.get(serviceAPIs().accAssignedDeviceList+`?page_id=${data?.page_id}&records_per_page=${data?.records_per_page}`, config)
}

export const getAccSharingData = (data, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().accSharingDetails,data, config)
}

export const getAccSharingEmailsData = (data, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().accSharingEmailsList,data, config)
}

// --------------------------------------------------------------
// Subscriber API
// --------------------------------------------------------------
export const fetchAccLocInfo = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getAccLocInfo, data, config);
};
export const fetchSubPersonalInfo = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getSubPersonalInfo, data, config);
};

export const createSubLingoLink = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().createSubLingoLink, data, config);
};

export const fetchSubLingoList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getSubLingoList, data, config);
};
export const fetchSubLingoMiniList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getSubLingoMiniList, data, config);
};

export const disableSubLingoLink = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().disableSubLingoLink, data, config);
};

export const resetSubLingoLinkCode = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().resetSubLingoLinkCode, data, config);
};

export const shareSubLingoLink = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().shareSubLingoLink, data, config);
};

export const getLinkSubscriberDesign = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().fetchSubLinkDesignData, data, config);
};

export const setupSubscriberLinkDesign = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().manageSubscriberLinkDesign, data, config);
};

export const uploadSubscriberLinkDesignImages = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().uploadSubscriberLinkDesign, data, config);
};

export const getSubscriberCallHistory = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getSubCallHistory, data, config);
};

export const calculateSubscriberCalls = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().calSubCalls, data, config);
};

export const subLingoKeyword = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().subLingoKey, data, config);
};

export const updateLinkFeatures = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().addUpdateFeatures, data, config);
};

export const fetchSubscriberTranscript = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getSubsriberTranscript, data, config);
};

export const fetchSubscriberTranscriptData = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getSubsriberTranscriptData, data, config);
};

export const mailSubscriberTranscriptData = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().sendSubscriberTranscriptMail, data, config);
};

export const resetSubscriberPassword = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().resetSubscriberPassword, data, config);
};

export const fetchResetedLeaderCode = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getresetedLeaderCode, data, config);
};

export const shareLeaderCode = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().mailLeaderCode, data, config);
};

export const createSubLingoCard = (data, config=null, axiosInstance=axios) => {
	return axiosInstance.post(serviceAPIs().createSubLingoCard, data, config)
}

export const updateSubLingoCard = (data, config=null, axiosInstance=axios) => {
	return axiosInstance.post(serviceAPIs().updateSubLingoCard, data, config)
}

export const listSubLingoCard = (data, config=null, axiosInstance=axios) => {
	return axiosInstance.post(serviceAPIs().listSubLingoCards, data, config)
}
export const manageSubLingoCard = (data, config=null, axiosInstance=axios) => {
	return axiosInstance.post(serviceAPIs().manageSubLingoCard, data, config)
}

export const shareSubLingoCard = (data, config=null, axiosInstance=axios) => {
	return axiosInstance.post(serviceAPIs().shareSubLingoCard, data, config)
}

export const enableDiableSubLingoCard = (data, config=null, axiosInstance=axios) => {
	return axiosInstance.post(serviceAPIs().enableDiableSubLingoCard, data, config)
}

export const checkLingoCardAccess = (data, config=null, axiosInstance=axios) => {
	return axiosInstance.post(serviceAPIs().checkLingoCardEnabled, data, config)
}

export const refreshLingoCardCode = (data, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().refreshSubLingoCard,data,config)
}

export const subLingoCardUsage = (data, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().cardUsageDetails,data,config)
}

export const commonDetailsForLingoCards = (data=null, config=null, axiosInstance=axios) =>{
	return axiosInstance.get(serviceAPIs().commonDetailsLingoCard,data,config)
}

export const shareLingoCardVaiSms = (data=null, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().shareLingoCardSms,data,config)
}

export const createSubSchedule = (data=null, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().createSchedule,data,config)
}

export const updateSubSchedule = (data=null, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().updateSchedule,data,config)
}

export const getSubScheduleList = (data=null, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().getScheduleList,data,config)
}

export const cancelSubSchedule = (data=null, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().cancelSchedule,data,config)
}

export const shareSubSchedule = (data=null, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().shareSchedule,data,config)
}

export const createLangPreference = (data=null, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().createLanguagePreference,data,config)
}
export const updateLangPreference = (data=null, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().updateLanguagePreference,data,config)
}
export const listLangPreference = (data=null, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().listLangPreferences,data,config)
}
export const getLangPreference = (data=null, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().getLanPreference,data,config)
}
export const applyLangPreference = (data=null, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().applyLangPrefrence,data,config)
}
export const manageLangPreference = (data=null, config=null, axiosInstance=axios) =>{
	return axiosInstance.post(serviceAPIs().manageLanguagePreference,data,config)
}


// --------------------------------------------------------------
// Events API
// --------------------------------------------------------------

export const fetchAccountEvents = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getAllAdminEvents, data, config);
};

export const fetchEventData = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getEventList, data, config);
};

export const fetchAccAdminEventsForSub = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getAcountAdminEventsForSubscriber, data, config);
};

// --------------------
// Subscriber Dashboard Services
// --------------------
export const getSubDbClientLinkCount = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().dbSubLingoLinksCount, data, config);
};
export const getSubDbClientEventsCount = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().dbSubClientEventsCnt, data, config);
};

export const lingoEmailTranscript = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().lingoEmailTranscript, data, config);
};

export const saveEmailTranscript = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().saveLingoTranscript, data, config);
};

export const storeAccountSubApp = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().saveAccountSubApp, data, config);
};

export const diableAllAccountSubApp = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().disableAllSubApp, data, config);
};



export const fetchSubscriberLinks = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getsubscriberLinks, data, config);
};

export const fetchSubscriberLinksCallHistory = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getsubscriberLinksCallHistory, data, config);
};

export const getSubscriberQuotaInfo = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().fetchSubQuotaInfo, data, config);
};

// --------------------
// Account Dashboard Services
// --------------------
export const getAccountDbLocationCount = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().dbAccountLocationsCount, data, config);
};
export const getAccountDbEventCount = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().dbAccountEventsCount, data, config);
};
// --------------------
// Account Quota Services
// --------------------
export const getAccountQuotaList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().fetchAccountQuotaList, data, config);
};

export const getQuotaData = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().fetchQuotaDetails, data, config);
};

export const updateQuotaDetails = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateAccountQuota, data, config);
};



