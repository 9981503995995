import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import {
    Box,
    Link,
    Spacer,
    Icon,
    Button,
    FormControl,
    FormLabel,
    PinInput,
    PinInputField,
    Input,
    VStack, Heading, Flex,
    Image,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useToast,
    useDisclosure,
    useColorModeValue,
    Spinner,
    Badge
} from "@chakra-ui/react";
import {
    AiOutlineCheckCircle,
    AiOutlineInfoCircle,
    AiOutlineCopy,
    AiOutlineLink,
    AiOutlineMail,
    AiOutlineStop,
    AiOutlineSync, AiTwotoneAudio,
    AiOutlineVideoCamera
} from "react-icons/ai";
import moment from "moment";
import { formatTime, formatSeconds } from '../../../../utils/TimeFunctions';
import Card from "../../../../components/card/Card";
// Api Call Imports
import {
    getAllUserLinkCalls, calculateUserLinkCalls
} from "../../../../services/businessServices";

// Component Imports
import { toastFunctionToaster } from "../../../../utils/toastFunction";
import { useBlueButton, useGrayTextColor, useNoResultFoundText } from "../../../../theme/globalColorTheme";

const LinkCalls = () => {
    const toast = useToast();
    const { state } = useLocation();
    const navigate = useNavigate();
    const btnRef = React.useRef();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [loading, isLoading] = useState(false);

    const notFoundText = useNoResultFoundText()
    const blueButton = useBlueButton()
    const grayText = useGrayTextColor()

    const linkUuid = state?.linkUuid ? state?.linkUuid : null;
    const linkCode = state?.linkCode ? state?.linkCode : null;
    const linkKey = state?.linkKey ? state?.linkKey : null;

    const [linkSessions, setLinkSessions] = useState([]);
    const [linkreport, setLinkReport] = useState([]);
    const [billAmount, setBillAmount] = useState(0.0);
    const [linkUrl, setLinkUrl] = useState(null);

    //   Todo:Fetch all projects
    useEffect(() => {
        getLinkCalls();
        calculateBilling();
    }, []);

    const getLinkCalls = () => {
        if (linkUuid != null && linkCode != null && linkKey != null) {
            isLoading(true);
            let paramObj = {
                linkUuid: linkUuid,
                linkKey: linkKey,
                records_per_page: 0,
                page_id: 0,
            };
            getAllUserLinkCalls(paramObj)
                .then((res) => {
                    isLoading(false);
                    if (res?.data[0]?.status) {
                        isLoading(false);
                        setLinkSessions(res?.data[0]?.sessions);
                        setLinkReport(res?.data[0]?.report);
                        setLinkUrl(res?.data[0]?.url);
                    } else {
                        isLoading(false);
                        setLinkSessions([]);
                        setLinkUrl(null);
                    }
                })
                .catch((err) => {
                    isLoading(false);
                    setLinkSessions([]);
                    setLinkUrl(null);
                });
        }
    };

    const mergeBillingData = (billingData) => {
        const localData = Object.assign({}, billingData, linkSessions);
        setLinkSessions(localData);
    }

    const calculateBilling = () => {
        if (linkUuid != null && linkCode != null && linkKey != null) {
            isLoading(true);
            let paramObj = {
                linkUuid: linkUuid,
                linkKey: linkKey
            };
            calculateUserLinkCalls(paramObj)
                .then((res) => {
                    isLoading(false);
                    if (res?.data[0]?.status) {
                        isLoading(false);
                        setBillAmount(res?.data[0]?.amount);
                        // mergeBillingData(res?.data[0]?.sessions);
                    } else {
                        isLoading(false);
                    }
                })
                .catch((err) => {
                    isLoading(false);
                });
        }
    };

    const renderSessionCalls = (linkCalls) => {
        if (linkCalls.length > 0) {
            return (
                linkCalls?.map((item, index) => {
                    return (
                        <Flex w={'100%'} key={index} bg={item?.type === 'audio' ? 'green.100' : 'blue.100'} p={2} rounded={'md'} borderWidth={'0.05px'} borderColor={'gray.200'}>
                            <Flex minW={'50px'}>
                                <Text >{index + 1}</Text>
                            </Flex>
                            <Flex minW={'50px'}>
                                <Box w={'30px'} rounded={'md'}
                                    bg={item?.type === 'audio' ? 'green.300' : 'blue.300'} align={'center'} pt={1}>
                                    {item?.type === 'audio' ? <Icon h={5} w={5} as={AiTwotoneAudio} /> : <Icon as={AiOutlineVideoCamera} />}
                                </Box>
                            </Flex>
                            <Flex minW={'200px'}>
                                <Text fontSize={'sm'}>{moment(item?.started).format("MMM DD,YYYY,HH:mm:ss a")}</Text>
                            </Flex>
                            <Flex minW={'200px'}>
                                <Text fontSize={'sm'}>{moment(item?.ended).format("MMM DD,YYYY,HH:mm:ss a")}</Text>
                            </Flex>
                            <Flex minW={'100px'}>
                                <Text>{formatSeconds(Math.round(item?.duration_seconds, 0))}</Text>
                            </Flex>
                            <Flex minW={'300px'}>
                                <Text>From <b>{item?.source_language}</b> to <b>{item?.target_language}</b></Text>
                            </Flex>
                            <Flex minW={'150px'}>
                                <Text textTransform="uppercase">{item?.provider}</Text>
                            </Flex>
                            <Flex w={'100%'}>
                                <Text>{item?.agent_info}</Text>
                            </Flex>
                        </Flex>
                    )
                })
            )
        }
    }

    const renderSessions = linkSessions !== null && linkSessions?.length > 0
        ? linkSessions?.map((item, index) => (
            <Flex w={'100%'} key={index}
                direction={'column'}
                p={2} mt={2} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
                <Flex w={'100%'}>
                    <Flex w={'50px'}>
                        <Text >{index + 1}</Text>
                    </Flex>
                    <Flex w={'250px'}>
                        <Text fontSize={'sm'}>{moment(item?.created).format("MMM DD,YYYY,HH:mm:ss a")}</Text>
                    </Flex>
                    <Flex w={'100%'}>
                        <Text fontWeight={500} color={'gray.400'}>{item?.session}</Text>
                    </Flex>
                    <Flex w={'150px'} direction={'column'}>
                        <Text>Total Calls: {item?.count}</Text>
                    </Flex>
                    <Flex w={'250px'} direction={'column'}>
                        <Text>Call Duration: {formatSeconds(Math.round(item?.total, 2))}</Text>
                    </Flex>

                </Flex>
                {item?.count > 0
                    ? <Flex w={'100%'} direction={'column'} >
                        {renderSessionCalls(item?.calls)}
                    </Flex> : null}
            </Flex>
        ))
        : <Flex w={'100%'} p={2}>
            <Text w={'100%'} bg={'red.100'} py={3} color={notFoundText}>There are <b>no sessions</b> and <b>calls within those sessions</b> in this dynamic link</Text>
        </Flex>;

    const launchLinkPrefix = () => {
        if (window.location.pathname.includes("/account")) {
            return "/account";
        } else if (window.location.pathname.includes("/business")) {
            return "/business";
        }
        return null;
    };

    return (
        <>
            <Box direction="column" pt={{ sm: "125px", lg: "75px" }}>
                <Card w={'100%'}>
                    <Flex w={'100%'} align={'center'}>
                        <Text>
                            LingoLink and LingoCard Customer
                        </Text>
                        <Spacer />
                    </Flex>
                </Card>
                {linkUuid != null && linkCode != null
                    ? <Flex w={'100%'} direction={'column'} align={'center'}>
                        <Flex w={'100%'} direction={'column'}>
                            <Flex
                                w={'100%'}
                                p="3"
                                alignItems="center"
                                borderBottomWidth="1px"
                                justifyContent={"space-between"}
                            >
                                <Text fontWeight={"500"} fontSize={"18px"}>
                                    Dynamic Link Calls ({linkSessions?.length > 0 ? linkSessions?.length : null} Sessions)
                                </Text>
                                <Spacer />
                                <Button bg="yellow.200" rounded={'md'} size="sm"
                                    onClick={() => {
                                        getLinkCalls();
                                    }}>
                                    <Icon as={AiOutlineSync} color={grayText} />
                                </Button>
                                <Button ml={1} bg={blueButton} _hover={{ bg: 'blue.300' }} rounded={'md'} size="sm"
                                    onClick={() => {
                                        navigate(launchLinkPrefix() + "/linkmanager", {
                                            state: {
                                                linkUuid: linkUuid,
                                            },
                                        });
                                    }}>
                                    Back to Link Manager
                                </Button>
                                <Button ml={1} colorScheme="blue" size="sm"
                                    onClick={() => {
                                        navigate(launchLinkPrefix() + "/linklisting", {
                                        });
                                    }}>
                                    Back to LingoLinks
                                </Button>
                            </Flex>
                            {loading
                                ? <Flex w={'100%'} p={10} direction={'column'} align={'center'}>
                                    <Spinner size={'xl'} />
                                </Flex>
                                : <Flex w={'100%'} direction={'column'}>
                                    <Flex
                                        direction={'column'}
                                        w={'100%'}
                                        h="calc(100vh - 200px)"
                                        overflow="auto"
                                        borderTopWidth="0px"
                                        css={{
                                            '&::-webkit-scrollbar': {
                                                width: '4px',
                                            },
                                            '&::-webkit-scrollbar-track': {
                                                width: '6px',
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                borderRadius: '24px',
                                            },
                                        }}>
                                        {renderSessions}
                                    </Flex>
                                    {linkreport?.length > 0
                                        ? <Flex w={'100%'} py={2} h={'40px'} bg={'gray.300'} align={'center'}>
                                            <Box align={'start'}>
                                                <Text ml={1}>Total Sessions: <Badge>{linkreport[0]}</Badge></Text>
                                            </Box>
                                            <Box align={'start'} ml={2}>
                                                <Text ml={1}>Total Calls (in all sessions): <Badge>{linkreport[1]}</Badge></Text>
                                            </Box>
                                            <Box align={'start'} ml={2}>
                                                <Text ml={1}>Total Time used (in all sessions): <Badge>{formatSeconds(Math.round(linkreport[2], 2))}</Badge></Text>
                                            </Box>
                                            <Spacer />
                                            {billAmount >= 0.0
                                                ? <Box align={'end'} ml={2}>
                                                    <Text ml={1}>Total Charged $ Amount (in all sessions): <Badge bg={'green.200'}>${billAmount.toFixed(2)}</Badge></Text>
                                                </Box> : null}
                                            <Box
                                                onClick={() => calculateBilling()}
                                                align={'end'} ml={2} mr={'1'}>
                                                <Button size={'xs'}>Calculate Amount</Button>
                                            </Box>
                                        </Flex>
                                        : <Flex py={2} w={'100%'} h={'30px'} bg={'gray.300'} align={'center'}>
                                            <Text ml={1}>No Report...</Text>
                                        </Flex>}
                                </Flex>}
                        </Flex>
                    </Flex>
                    : <Flex w={'100%'} direction={'column'} align={'center'}>
                        <Text w={'100%'} bg={'red.100'} p={2} color="#gray.800" fontSize={"16px"} fontWeight={"normal"}>
                            There is no session data for this dynamic link!!
                        </Text>
                    </Flex>}
            </Box>
        </>
    );
};

export default LinkCalls;
