import {
    Box, Button, Card, Flex, Icon, Spacer, Text, useDisclosure, Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Alert,
    AlertIcon,
    FormControl,
    FormLabel,
    Input,
    useToast,
    FormHelperText,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Spinner,
} from '@chakra-ui/react';
import React, { memo, useEffect, useState } from 'react'
import { LANG_PREF_TYPES, SIDEBAR_TYPES } from './constants';
import { AiOutlineCheckCircle } from 'react-icons/ai';



import Multiselect from 'multiselect-react-dropdown';
import { useBrandBg, useGreenBg, useRedButton } from '../../../theme/globalColorTheme';
import { accLangPrefPaginated, createAccLangPref, fetchLocationInfo, getAllLocLanguages, manageAccLangPref, updateAccLangPref } from '../../../services/accountServices';
import { toastFunctionToaster } from '../../../utils/toastFunction';
import Pagination from '../../../components/pagination/Pagination';



const LanguagePreference = () => {
    const [cardFilter, setCardFilter] = useState(LANG_PREF_TYPES.ACTIVE)
    const [langPrefList, setLangPrefList] = useState()
    const redBg = useRedButton();
    const greenBg = useGreenBg();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure()
    const [sideBarType, setSideBarType] = useState('')
    const brandBg = useBrandBg();
    const [btnLoading, setBtnLoading] = useState({
        addUpdate: '',
        manageBtnLoading: ''
    })
    const [accountUuid, setAccountUuid] = useState('')
    const [locationUuid, setLocationUuid] = useState(null); // (TODO: Paras) Dont use '' instead use null
    const [availableLangs, setAvailableLangs] = useState([]); // (TODO: Paras) Dont use () instead use [] for list type vars
    const toast = useToast()
    const [name, setName] = useState('')
    const [selectedLangs, setSelectedLanguages] = useState([])
    const [touched, setTouched] = useState({})
    const [errors, setErrors] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [recordsPerPage, setRecordsPerPage] = useState(5)
    const [activePrefence, setActivePreference] = useState(null)
    const [initialDetails, setInitialDetails] = useState({})
    const [updateDisabled, setUpdateDisabled] = useState(true)
    const [loading, setLoading] = useState(true)

    const RenderLanguageListBox = memo(({ availableLangs, selectedLangs}) => {
        const selectedValues = availableLangs.filter(item => selectedLangs.indexOf(item.code) > -1)
        return (availableLangs.length ?
            (
                <Flex direction="column" gap={5} w="full" h="full" onBlur={() => handleOnBlur('selected_langs')}>
                    <Multiselect
                        style={{
                            width: "100%",
                        }}
                        options={availableLangs}
                        selectedValues={selectedValues}
                        onSelect={selectedList => handleLangSelect(selectedList)}
                        onRemove={selectedList => handleLangSelect(selectedList)}
                        displayValue="name"
                        placeholder="Select languages"
                    />
                </Flex>
    
            ) : <>No options found</>)
    })

    function handleOpenDrawer(type, item) {
        setSideBarType(type)
        if (type === SIDEBAR_TYPES.UPDATE) {
            setName(item?.language_preference_name)
            setSelectedLanguages(item.supported_language_list?.split(','))
            setActivePreference(item)
            setInitialDetails(item)
        }
        onOpen()
    }

    function handleOnDrawerClose() {
        onClose()
        setErrors({})
        setName('')
        setSelectedLanguages([])
        setTouched({})
    }

    function getAvailableLangs(){
        const paramObj = {
            type:"translation",
            org:"aws"
        }
        getAllLocLanguages(paramObj).then(resp=>{
            if(resp?.result){
                setAvailableLangs(resp?.data)
            }else{
                setAvailableLangs([])
            }
        }).catch(err=>{
            setAvailableLangs([])
        })
    }

    function fetchAccAndLocDetails() {
        const paramObj = { key: "location" };
        fetchLocationInfo(paramObj)
            .then(resp => {
                if (resp?.data[0]?.status) {
                    setAccountUuid(resp?.data[0]?.uuid);
                } else {
                    setAccountUuid("");
                    setLocationUuid("");
                }
            })
            .catch(err => {
                setAccountUuid("");
                setLocationUuid("");
            });
    };

    function handleLangSelect(selectedList) {
        if (selectedList.length > 0) {
            let languagesArr = [];
            selectedList?.map(data => {
                languagesArr?.push(data?.code);
            });
            setSelectedLanguages(languagesArr);
        } else {
            setSelectedLanguages([])
        }
    };

    function handleCreateLanguagePreference() {
        if (validate()) {
            const paramObj = {
                "link_account_uuid": accountUuid,
                "link_location_uuid": locationUuid,
                "language_preference_name": name,
                "language_list": selectedLangs.join(','),
                "preference_status": true
            }
            setBtnLoading(btnLoading => ({ ...btnLoading, addUpdate: true }))
            createAccLangPref(paramObj).then(resp => {
                if (resp.data[0]?.status) {
                    toast(toastFunctionToaster("Language preference added successfully!!", "success"))
                    getLanguagePreferencesList()
                    handleOnDrawerClose()
                } else {
                    toast(toastFunctionToaster("Failed to add Language preference!!", "error"),)
                }
                setBtnLoading(btnLoading => ({ ...btnLoading, addUpdate: false }))
            }).catch(err => {
                setBtnLoading(btnLoading => ({ ...btnLoading, addUpdate: false }))
                toast(toastFunctionToaster("Failed to add Language preference!!", "error"))
            })
        }
    }

    function getLanguagePreferencesList() {
        const paramObj = {
            pageId: currentPage,
            records_per_page: recordsPerPage,
            status: cardFilter
        }
        setLoading(true)
            accLangPrefPaginated(paramObj).then(resp => {
                if (resp.data[0]?.status) {
                    setLangPrefList(resp.data[0]?.status)
                    setCurrentPage(resp?.data[0]?.page_id)
                    setTotalPages(resp?.data[0]?.total_pages)
                } else {
                    setLangPrefList(null)
                    toast(toastFunctionToaster("Failed to fetch Preference list", "error"))
                }
                setLoading(false)
            }).catch(err => {
                setLangPrefList(null)
                toast(toastFunctionToaster("Failed to fetch Preference list", "error"))
                setLoading(false)
            })
    }

    function handleOnBlur(name) {
        setTouched(touched => ({ ...touched, [name]: true }))
    }

    function validate() {
        let res = false
        if (touched['selected_langs'] && !selectedLangs.length) {
            setErrors(errors => ({ ...errors, 'selected_langs': "Selected languages cannot be empty!" }))
        } else {
            setErrors(errors => ({ ...errors, 'selected_langs': '' }));
            res = true
        }

        if (touched['pref_name'] && !name) {
            setErrors(errors => ({ ...errors, 'pref_name': "Preference name cannot be empty!" }))
        } else {
            setErrors(errors => ({ ...errors, 'pref_name': "" }))
            res = true
        }
        return res
    }

    function handleUpdateLanguagePreference() {
        if (validate()) {

            const paramObj = {
                "link_account_uuid": accountUuid,
                "link_location_uuid": locationUuid,
                "language_preference_uuid": activePrefence?.language_preference_uuid,
                "language_preference_name": activePrefence?.language_preference_name,
                "language_list": selectedLangs.join(",")
            }
            setBtnLoading(btnLoading => ({ ...btnLoading, addUpdate: true }))
            updateAccLangPref(paramObj).then(resp => {
                if (resp?.data[0]?.status) {
                    toast(toastFunctionToaster("Updated Language preference successfully", "success"))
                    handleOnDrawerClose()
                    getLanguagePreferencesList()
                } else {
                    toast(toastFunctionToaster("Failed to Updated Language preference", "error"))
                }
                setBtnLoading(btnLoading => ({ ...btnLoading, addUpdate: false }))
            }).catch(err => {
                setBtnLoading(btnLoading => ({ ...btnLoading, addUpdate: false }))
                toast(toastFunctionToaster("Failed to Updated Language preference", "error"))
            })
        }
    }

    useEffect(() => {
        validate()
    }, [touched, selectedLangs, name])

    useEffect(() => {
        fetchAccAndLocDetails()
        getAvailableLangs()
    }, [])

    useEffect(() => {
        const oldData = {
            name: initialDetails?.language_preference_name,
            langs: initialDetails?.supported_language_list
        }

        const newData = {
            name,
            langs: selectedLangs.join(',')
        }

        JSON.stringify(newData) === JSON.stringify(oldData) ? setUpdateDisabled(true) : setUpdateDisabled(false)
    }, [initialDetails, name, selectedLangs])

    useEffect(() => { setCurrentPage(1) }, [recordsPerPage])

    useEffect(() => {
        getLanguagePreferencesList()
    }, [cardFilter, recordsPerPage, currentPage])

   

    const RenderLanguages = memo(({ langs }) => {
        let langsArr = langs?.split(',')
        const filterData = availableLangs?.filter(item => langsArr?.indexOf(item.code) > -1)
        return (
            filterData.length ? (
                <Flex flexWrap="wrap" gap={2} >
                    {filterData.map((item) => (<Text bg={'blue.200'} p="1" fontSize="sm" borderRadius="md">{item.name}</Text>))}
                </Flex>
            ) :
                <Text bg={'blue.200'} p="1" fontSize="sm" borderRadius="md">All</Text>
        )
    })

    function handleModalClose() {
        onModalClose()
        setActivePreference(null)
    }

    function handleModalOpen(item) {
        setActivePreference(item)
        onModalOpen()
    }

    function handleManagePreference() {
        const paramObj = {
            "link_account_uuid": accountUuid,
            "link_location_uuid": locationUuid,
            "language_preference_uuid": activePrefence?.language_preference_uuid
        }

        setBtnLoading(btnLoading => ({ ...btnLoading, manageBtnLoading: activePrefence?.language_preference_uuid }))
        manageAccLangPref(paramObj).then(resp => {
            if (resp?.data[0]?.status) {
                toast(toastFunctionToaster("Preference update successfully", "success"))
                getLanguagePreferencesList()
            
            } else {
                toast(toastFunctionToaster("Failed to Update Preference", "error"))
            }
            handleModalClose()
            setBtnLoading(btnLoading => ({ ...btnLoading, manageBtnLoading: '' }))
        }).catch(err => {
            handleModalClose()
            setBtnLoading(btnLoading => ({ ...btnLoading, manageBtnLoading: '' }))
            toast(toastFunctionToaster("Failed to Update Preference", "error"))

        })
    }

    return (
        <Flex direction="column" pt={{ sm: "125px", lg: "75px" }} mt="4" alignItems="center" position="relative">
            {loading ? <Spinner thickness='2px'
                speed='0.65s'
                emptyColor='gray.200'
                color='black'
                size='xl'
                position="fixed"
                top="50%"
                
                 /> : <>
                <Card w={"100%"} rounded={"md"} p={1} mb={2}>
                    <Flex p="2" alignItems="center" justifyContent={"space-between"}>
                        <Text fontWeight={"500"} fontSize={"18px"} _firstLetter={{ textTransform: "capitalize" }}>
                            {cardFilter} Language Preferences: {langPrefList ?  langPrefList?.length : 0}
                        </Text>
                        <Spacer />
                        <Flex align={"center"}>
                            <Box
                                p={1}
                                align={"center"}
                                w={"100px"}
                                _hover={{ cursor: "pointer" }}
                                roundedLeft={"lg"}
                                bg={cardFilter === LANG_PREF_TYPES.ACTIVE ? "green.600" : greenBg}
                                color={cardFilter === LANG_PREF_TYPES.ACTIVE ? "white" : "inherit"}
                                size="sm"
                                onClick={() => {
                                    setCardFilter(LANG_PREF_TYPES.ACTIVE);
                                }}>
                                {cardFilter === LANG_PREF_TYPES.ACTIVE ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                Active
                            </Box>
                            <Box
                                p={1}
                                bg={cardFilter === LANG_PREF_TYPES.DISABLE ? "red.600" : redBg}
                                color={cardFilter === LANG_PREF_TYPES.DISABLE ? "white" : "inherit"}
                                align={"center"}
                                w={"100px"}
                                roundedRight={"lg"}
                                size="sm"
                                _hover={{ cursor: "pointer" }}
                                onClick={() => {
                                    setCardFilter(LANG_PREF_TYPES.DISABLE);
                                }}>
                                {cardFilter === LANG_PREF_TYPES.DISABLE ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                Disabled
                            </Box>
                            <Button
                                colorScheme="blue"
                                size="sm"
                                borderRadius="lg"
                                p={1}
                                ml={4}
                                onClick={() => handleOpenDrawer(SIDEBAR_TYPES.CREATE)}
                            >
                                Create New Language Preference
                            </Button>
                        </Flex>
                    </Flex>
                </Card>

                <Card w={"100%"} rounded={"md"} p="1px">
                    <Flex h="65vh" w="100%">
                        <Box
                            w="full"
                            bg={brandBg}
                            p="1"
                            borderRadius={"10px"}
                            overflow="hidden">
                            <Card w={"100%"} p={2} rounded={"md"} h="full">
                                {(langPrefList && langPrefList?.length) ? (
                                    langPrefList.map(item => (
                                        <Card w="100%" borderRadius="md" mb={2} p={4}>
                                            <Flex flexDir="column" gap={2}>
                                                <Flex>
                                                    <Box
                                                        p={2}
                                                        bg={cardFilter === LANG_PREF_TYPES.ACTIVE ? greenBg : redBg}
                                                        borderRadius="md">
                                                        <Text
                                                            fontWeight={"500"}
                                                            fontSize={"18px"}
                                                            _firstLetter={{ textTransform: "capitalize" }}>
                                                            {item?.language_preference_name}
                                                        </Text>
                                                    </Box>
                                                    <Spacer />
                                                    <Flex>
                                                        <Button
                                                            ml={1}
                                                            bg={"blue.100"}
                                                            size="sm"
                                                            onClick={() =>
                                                                handleOpenDrawer(SIDEBAR_TYPES.UPDATE, item)
                                                            }>
                                                            Update
                                                        </Button>
                                                        <Button
                                                            ml={1}
                                                            bg={"blue.100"}
                                                            size="sm"
                                                            onClick={() => handleModalOpen(item)}
                                                            isLoading={btnLoading?.manageBtnLoading === item?.language_preference_uuid}>
                                                            {item?.preference_status ? 'Disable' : 'Enable'}
                                                        </Button>
                                                    </Flex>
                                                </Flex>

                                                <Flex alignContent="center" gap={2}>Languages:<RenderLanguages langs={item?.supported_language_list} /></Flex>
                                            </Flex>
                                        </Card>
                                    ))
                                ) : (
                                    <Alert status="warning" mt="4">
                                        <AlertIcon />
                                        <Text>You dont have any {cardFilter} Language Preferences</Text>
                                        <Text
                                            ml={5}
                                            _hover={{ cursor: "pointer", textDecoration: "underline" }}
                                            onClick={() => handleOpenDrawer(SIDEBAR_TYPES.CREATE)}>
                                            <b>CREATE NEW Language Preference!!</b>
                                        </Text>
                                    </Alert>
                                )}
                            </Card>
                        </Box>
                        <Modal isOpen={isModalOpen} onClose={handleModalClose}>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>
                                <Text fontWeight={"500"} fontSize={"18px"} _firstLetter={{ textTransform: "capitalize" }}>
                                    {activePrefence?.preference_status ? 'Disable': "Enable"} Language Preference
                                </Text>
                                </ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <Text>Are you sure you want to {activePrefence?.preference_status ? 'Disbale' : 'Enable'} this preference? </Text>
                                </ModalBody>

                                <ModalFooter gap={4}>
                                    <Button colorScheme="red" onClick={handleManagePreference} isLoading={btnLoading?.manageBtnLoading === activePrefence?.language_preference_uuid}>
                                        Yes
                                    </Button>
                                    <Button colorScheme="blue" mr={3} onClick={handleModalClose}>
                                        Close
                                    </Button>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                        <Drawer
                            isOpen={isOpen}
                            placement='right'
                            onClose={handleOnDrawerClose}
                            size="md"
                        >
                            <DrawerOverlay />
                            <DrawerContent>
                                <DrawerCloseButton />
                                <DrawerHeader>Create your account</DrawerHeader>

                                <DrawerBody>
                                    {
                                        sideBarType === SIDEBAR_TYPES.CREATE && (
                                            <form>
                                                <FormControl>
                                                    <FormLabel>Name: <Text as="span" color="red.400">*</Text></FormLabel>
                                                    <Input value={name} placeholder='Enter preference name' onBlur={() => handleOnBlur('pref_name')} onChange={e => setName(e.target.value)} />
                                                    {errors['pref_name'] && <FormHelperText fontSize="sm" color="red.500">{errors['pref_name']}</FormHelperText>}
                                                </FormControl>
                                                <FormControl mt={4}>
                                                    <FormLabel>Select Languages:  <Text as="span" color="red.400">*</Text></FormLabel>
                                                    <RenderLanguageListBox availableLangs={availableLangs} selectedLangs={selectedLangs}/>
                                                    {errors['selected_langs'] && <FormHelperText fontSize="sm" color="red.500">{errors['selected_langs']}</FormHelperText>}
                                                </FormControl>
                                            </form>
                                        )
                                    }
                                    {
                                        sideBarType === SIDEBAR_TYPES.UPDATE && (
                                            <form>
                                                <FormControl>
                                                    <FormLabel>Name: <Text as="span" color="red.400">*</Text></FormLabel>
                                                    <Input value={name} placeholder='Enter preference name' onBlur={() => handleOnBlur('pref_name')} onChange={e => setName(e.target.value)} />
                                                    {errors['pref_name'] && <FormHelperText fontSize="sm" color="red.500">{errors['pref_name']}</FormHelperText>}
                                                </FormControl>
                                                <FormControl mt={4}>
                                                    <FormLabel>Select Languages:  <Text as="span" color="red.400">*</Text></FormLabel>
                                                    <RenderLanguageListBox availableLangs={availableLangs} selectedLangs={selectedLangs} />
                                                    {errors['selected_langs'] && <FormHelperText fontSize="sm" color="red.500">{errors['selected_langs']}</FormHelperText>}
                                                </FormControl>
                                            </form>
                                        )
                                    }
                                </DrawerBody>

                                <DrawerFooter>
                                    <Button variant='outline' mr={3} onClick={handleOnDrawerClose}>
                                        Cancel
                                    </Button>
                                    {sideBarType === SIDEBAR_TYPES.CREATE ? <Button colorScheme='blue' isLoading={btnLoading?.addUpdate} onClick={handleCreateLanguagePreference}>CREATE</Button> : <Button colorScheme='blue' isDisabled={updateDisabled} onClick={handleUpdateLanguagePreference}>UPDATE</Button>}
                                </DrawerFooter>
                            </DrawerContent>
                        </Drawer>

                    </Flex>
                </Card>
                {langPrefList?.length ? <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    setRecordsPerPage={setRecordsPerPage}
                    totalPages={totalPages}
                    recordsPerPage={recordsPerPage}
                /> : null}
            </>}

        </Flex>
    )
}

export default LanguagePreference